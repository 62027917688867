import { RequestState } from "../../types"

export default abstract class RequestException {
    protected requestState!: RequestState;
    protected checkCondition!: (
        responseData: any
    ) => boolean;
    public check(
        responseData: any,
        setRequestState: (requestState: RequestState) => void
    ): void {
        try {
            if (this.checkCondition(responseData))
                setRequestState(this.requestState);
        }
        catch (error: any) {
            console.log(error);
            setRequestState(RequestState.NotSuccessful);
        }
    }
}

export class OldPasswordWrong extends RequestException {
    requestState: RequestState = RequestState.OldPasswordWrong;
    checkCondition = (responseData: any) => (
        ("old_password" in responseData)
    )
}

export class EmailAlreadyInUse extends RequestException {
    requestState: RequestState = RequestState.EmailAlreadyInUse;
    checkCondition = (responseData: any) => (
        ("email" in responseData && responseData.email.includes("email_already_in_use"))
    )
}

export class CPFAlreadyInUse extends RequestException {
    requestState: RequestState = RequestState.CPFAlreadyInUse;
    checkCondition = (responseData: any) => (
        ("cpf" in responseData && responseData.cpf.includes("cpf_already_in_use"))
    )
}

export class WeakPassword extends RequestException {
    requestState: RequestState = RequestState.WeakPassword;
    checkCondition = (responseData: any) => ((
        ("password1" in responseData && responseData.password1.includes("password_too_common")) ||
        ("password1" in responseData && responseData.password1.includes("password_too_short")) ||
        ("password1" in responseData && responseData.password1.includes("password_only_numbers")) ||
        ("new_password2" in responseData && responseData.new_password2.includes("password_too_common")) ||
        ("new_password2" in responseData && responseData.new_password2.includes("password_too_short")) ||
        ("new_password2" in responseData && responseData.new_password2.includes("password_only_numbers"))
    ))
}

export class UnableToChangeProperty extends RequestException {
    requestState: RequestState = RequestState.UnableToChangeProperty;
    checkCondition = (responseData: any) => (
        "detail" in responseData && responseData.detail.includes("JSON parse error")
    )
}

export class EmailInvalid extends RequestException {
    requestState: RequestState = RequestState.EmailInvalid;
    checkCondition = (responseData: any) => (
        "email" in responseData && responseData.email.includes("invalid_email")
    )
}

export class CPFInvalid extends RequestException {
    requestState: RequestState = RequestState.CPFInvalid;
    checkCondition = (responseData: any) => (
        "cpf" in responseData && responseData.cpf.includes("invalid_cpf")
    )
}

export class NoBookingsFound extends RequestException {
    requestState: RequestState = RequestState.NoBookingsFound;
    checkCondition = (responseData: any) => (
        "error" in responseData && responseData.error.includes("no_booking_slots")
    )
}

export class BookingNotAvailable extends RequestException {
    requestState: RequestState = RequestState.BookingNotAvailable;
    checkCondition = (responseData: any) => (
        "error" in responseData && responseData.error.includes("booking_not_available")
    )
}

export class BookingAlreadyBooked extends RequestException {
    requestState: RequestState = RequestState.BookingAlreadyBooked;
    checkCondition = (responseData: any) => (
        "error" in responseData && responseData.error.includes("already_booked")
    )
}

export class NotAuthorized extends RequestException {
    requestState: RequestState = RequestState.NotAuthorized;
    checkCondition = (responseData:any) => (
        "error" in responseData && (responseData.error.includes("operation_not_authorized"))
    )
}

export class NotFound extends RequestException {
    requestState: RequestState = RequestState.NotFound;
    checkCondition = (responseData: any) => true;
}

export class DefaultException extends RequestException {
    requestState: RequestState = RequestState.NotSuccessful;
    checkCondition = (responseData: any) => true;
}

export class NotConnected extends RequestException {
    requestState: RequestState = RequestState.NotConnected;
    checkCondition = (responseData: any) => true;
}

export class MissingData extends RequestException {
    requestState: RequestState = RequestState.MissingData
    checkCondition = (responseData: any) => (
        "error" in responseData && responseData.error.includes("missing_data")
    )
}

export class InvalidToken extends RequestException {
    requestState: RequestState = RequestState.InvalidToken
    checkCondition = (responseData: any) => (
        "error" in responseData && (responseData.error.includes("invalid_token") || responseData.error.includes("free_book_slot"))
    )
}

export class BirthdayIncorrect extends RequestException {
    requestState: RequestState = RequestState.BirthdayIncorrect
    checkCondition = (responseData: any) => (
        "error" in responseData && responseData.error.includes("birthday_incorrect")
    )
}

export class EmailNotUser extends RequestException {
    requestState: RequestState = RequestState.EmailNotUser
    checkCondition = (responseData: any) => (
        "error" in responseData && (responseData.error.includes("email_not_exist") || responseData.error.includes("user_email_not_identified"))
    )
}

export class UserAlready extends RequestException {
    requestState: RequestState = RequestState.UserAlready
    checkCondition = (responseData: any) => {
        console.log("yoooo: ", responseData)
        return ("error" in responseData && responseData.error.includes("user_already") )
        || ("error" in responseData && "username" in responseData.error && responseData.error.username[0].includes("Um usuário com este nome de usuário já existe."))
    }
}

export class UserAlreadyFuncionario extends RequestException {
    requestState: RequestState = RequestState.UserAlreadyFuncionario
    checkCondition = (responseData: any) => (
        "error" in responseData && responseData.error.includes("user_already_funcionario")
    )
}

export class UserAlreadyGerente extends RequestException {
    requestState: RequestState = RequestState.UserAlreadyGerente
    checkCondition = (responseData: any) => {
        console.log("yoooo useralready gerente: ", responseData)
        return "error" in responseData && responseData.error.includes("user_already_gerente")
    }
}

export class GestorInvalid extends RequestException {
    requestState: RequestState = RequestState.GestorInvalid
    checkCondition = (responseData: any) => (
        "error" in responseData && (responseData.error.includes("gestor_invalid") || responseData.error.includes("gestor_not_valid"))
    )
}

export class GestorNotIdentified extends RequestException {
    requestState: RequestState = RequestState.GestorNotIdentified
    checkCondition = (responseData: any) => (
        "error" in responseData && (responseData.error.includes("gestor_not_identified") || responseData.error.includes("gestor_not_found"))
    )
}
