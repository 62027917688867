import { useState } from 'react';
import { RequestState, RequestType } from '../types';
import {AxiosError, AxiosResponse} from 'axios';
import axios from '../commons/axios';

import requestExceptions, {RequestException} from '../constants/requestExceptions';

type useRequestReturn = {
    requestState: RequestState,
    call: (payload: object) => any
}

export default function useRequest (
    endpoint: string,
    setData: (data: any) => void,
    requestType: RequestType = RequestType.Get,
    toRoot: boolean = true,
): useRequestReturn {

    const [requestState, setRequestState] = useState<RequestState>(RequestState.NotStarted);
    const url = `/${endpoint}`

    const call = (payload: object | null = {}): void => {
        const reqSetup: any = {
            [RequestType.Get]: [axios.get, {params: payload}],
            [RequestType.Post]: [axios.post, payload],
            [RequestType.Patch]: [axios.patch, payload],
            [RequestType.Put]: [axios.put, payload],
            [RequestType.Delete]: [axios.delete, payload],
        }
        const [axiosApi, sendingData] = reqSetup[requestType];
        if(axiosApi === null) return;
        
        // define the api call
        setRequestState(RequestState.Waiting);
        axiosApi(url, sendingData )
        .then( (response: AxiosResponse) => response.data)
        .then( (data: any) => {
            setData(data);
            setRequestState(RequestState.Successful);
        })
        .catch( (error: AxiosError) => {
            try{
                const status = error.response!.status;
                const responseData = error.response!.data;
                requestExceptions[status].forEach(
                    (exception: RequestException) => {
                        exception.check(responseData, setRequestState);
                    }
                );
            }
            catch {
                setRequestState(RequestState.NotSuccessful);
            }
        })
    }

    return {
        requestState: requestState,
        call: call
    }
}
