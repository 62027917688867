import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { Exam, RequestState, RequestType } from '../../types';
import { Loader } from '../../components';
import useAuthorization from "../../hooks/useAuthorization"

import { emptyListExam } from 'src/constants/emptyTypes';

export default function AddLabExam(): React.ReactElement {
    const navigate = useNavigate()
    let { labId } = useParams()

    const [idExam, setIdExam] = useState("")

    const [exams, setExams] = useState<Exam[]>(emptyListExam)

    const request = useRequest('api/lab-exams/create_lab_exam/', () => { }, RequestType.Post);
    const requestState = request.requestState;

    const list_exams = useRequest("api/exams/get_exams_not_registered/", setExams, RequestType.Get)
    const list_exams_request_state = request.requestState;

    useEffect(() => {
        if(labId !== undefined){
            list_exams.call({
                lab_id: labId
            })
        } else{
            const labIdLocalStorage = localStorage.getItem("labId");
            if (!labIdLocalStorage)
                navigate("/");
            else{
                labId = labIdLocalStorage
                list_exams.call({
                    lab_id: labIdLocalStorage
                })
            }
        }
        
    }, [])

    useEffect(() => {
        if(exams.length !== 0)
            setIdExam(exams[0].id.toString())
    }, [exams]) 

    useEffect(() => {
        switch(list_exams_request_state){
            case RequestState.NotSuccessful:
                alert("Não foi possível carregar os dados no momento. Tente novamente mais tarde.")
                navigate(-1)
                break;
            default:
                break;
        }
    }, [list_exams_request_state])

    const handleSubmit = (event: any) => {
        event.preventDefault();
        request.call({
            lab_id: labId,
            exam_id: idExam
        })
    };

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            case RequestState.Successful:
                window.alert("Exame adicionado")
                localStorage.setItem("labId", labId?.toString() || "");
                navigate("/myLabs/labId/labExams")
                break;
            case RequestState.NotAuthorized:
                window.alert("Operação não autorizada")
                navigate("/")
                break;
            case RequestState.MissingData:
                window.alert("Os campos obrigatórios devem ser preenchidos")
                break;
            case RequestState.GestorNotIdentified:
                window.alert("Usuário não possui permissão de gestor")
                break;
            default: break;
        }
    }, [requestState])

    if (!useAuthorization()) return <Navigate to="/" replace />

    return (
        <div className="page" style={{ marginBottom: "40px" }}>
            <h1>Adicione um novo exame</h1>
            {list_exams_request_state == RequestState.Waiting ? <Loader /> :
                exams.length === 0 ?
                <h2>Não há exames para ser cadastrado</h2>
                :
                <form className="flex flex-col" onSubmit={handleSubmit}>
                    <select style={{height: "40px", borderRadius: "10px", outline: "none"}} onChange={e => setIdExam(e.target.value)}>
                        {exams.map((exam:Exam) => (
                            <option value={exam.id}>
                                {exam.name}
                            </option>
                        ))}
                    </select>
                    {
                        requestState === RequestState.Waiting ?
                            <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> :
                            <input className='button-primary' type="submit" value="Adicionar" />
                    }
                </form>
            }
        </div>
    )
}

function setRequestState(UnableToLogIn: RequestState) {
    throw new Error('Function not implemented.');
}
