import { useState } from 'react'
import { MdKeyboardArrowRight } from "react-icons/md"
import { Navigate } from 'react-router-dom';

type CardFuncionarioProps = {
    name: string,
    photo: string,
    lab: string,
    email: string,
    redirect: string,
    action?: () => void,
}

export default function CardFuncionario({ name, photo = "", lab, email, redirect, action = () => { } }: CardFuncionarioProps): React.ReactElement {
    const [active, setActive] = useState<Boolean>(false);
    const handleClick = (): void => {
        action();
        setActive(true);
    }

    if (redirect && active) return <Navigate to={redirect} />
    return (
        <div onClick={handleClick} className="card" style={{ margin: 'auto', marginBottom: '30px', width: '90%' }}>
            {
                window.innerWidth > 400 ?
                    <div className="my-auto p-2" >
                        { }
                    </div> : <></>
            }
            <div className="flex flex-col justify-center w-full ml-8">
                <p className="font-semibold">{name}</p>
                <p className="text-gray-dark text-sm">{lab}</p>
                <p className="text-gray-dark text-sm">{email}</p>
            </div>
            {
                <div className="my-auto py-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <MdKeyboardArrowRight size={24} style={{ marginBottom: '10px' }} />
                </div>
            }
        </div>
    )
}
