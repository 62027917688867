import React from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { LabExam, RequestState, RequestType } from '../../types';
import { Loader } from '../../components';
import useAuthorization from "../../hooks/useAuthorization"
import CardLabExam from 'src/components/CardLabExam';

export default function MyLabExams(): React.ReactElement {
    const navigate = useNavigate()
    let { labId } = useParams()
    if(labId === undefined) {
        const labIdLocalStorage = localStorage.getItem("labId");
        if (!labIdLocalStorage)
            navigate("/");
        else{
            labId = labIdLocalStorage
        }
    }
    const [labExams, SetLabExams] = useState<LabExam[]>()
    const request = useRequest(`api/lab-exams/get_lab_exams_by_lab/`, SetLabExams, RequestType.Get);
    const requestState = request.requestState;

    useEffect(() => request.call({
        lab_id: labId
    }), [])

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            default: break;
        }
    }, [requestState])

    if (!useAuthorization()) return <Navigate to="/login" replace />

    return (
        <div>
            <h1 style={{ marginTop: '15px', textAlign: 'center', marginBottom: '20px' }}>Meus Exames - Visão do Laboratório</h1>
            {
                requestState === RequestState.Waiting ?
                    <Loader />
                    : <div>
                        {labExams?.map((labExam: LabExam) => (
                            <CardLabExam
                                lab_exam={labExam}
                                action={() => {
                                    localStorage.setItem("labId", labId?.toString() || "");
                                    localStorage.setItem("labExamId", labExam.id?.toString() || "");
                                }}
                                redirect={"/myLabs/labId/labExamId"}
                            />
                        ))}
                        <Link className="button-primary" onClick={() => {
                            localStorage.setItem("labId", labId?.toString() || "");
                        }} to={"/myLabs/labId/labExams/addLabExam"}>Adicionar Exames</Link>
                    </div>
            }
        </div>
    )
}