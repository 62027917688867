import DeleteButton from "./DeleteButtons";
import EditableLineInput from "./EditableLineInput";

type EditDataProps = {
    title: string,
    listParams: any,
    entity: string,
    entityId: string,
    nameDelete: string
}

export default function EditData({ title, listParams, entity, entityId, nameDelete }: EditDataProps): React.ReactElement {

    return (
        <div className="page">
            <h1>{title}</h1>

            <div className="flex flex-col">
                {
                    Object.keys(listParams).length == 0 ? <></> :
                        <>
                            {
                                Object.keys(listParams).map((item) => {
                                    return(
                                        <EditableLineInput
                                            title={item}
                                            defaultValue={listParams[item]}
                                            propertyName={item}
                                            entity={entity}
                                            entityId={entityId}
                                        />
                                    )
                                })
                            }
                        </>
                }
            </div>
            <DeleteButton entity={entity} entityId={entityId} name={nameDelete}/>
        </div>
    )
}
