import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from "styled-components";
import { Lab, RequestState, RequestType } from "src/types";
import { emptyLab, emptyListLab } from "src/constants/emptyTypes";
import useRequest from "src/hooks/useRequest";
import { useGerente } from "src/hooks/useAuthorization";
import { BiWindowAlt } from "react-icons/bi";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const StyledStatsHomeScreen = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

type LabPair = {
    labId: number;
    labName: string;
}

export default function StatsHomeScreen(): React.ReactElement {
    const navigator = useNavigate();
    const theme = useTheme();

    const [chosenLab, setChosenLab] = useState<string>('');
    const [chosenLabList, setChosenLabList] = useState<string[]>([]);
    const [allLabsNames, setAllLabsNames] = useState<LabPair[]>([]);

    const isGerente = useGerente();

    const [listAllLabs, setListAllLabs] = useState<Lab[]>(emptyListLab)

    useEffect(() => {
        if (!isGerente) navigator("../accessDenied");
    }, []);

    const getListLab = useRequest(`api/labs/get_labs_by_gestor/`, setListAllLabs, RequestType.Get);

    useEffect(() => {
        getListLab.call({});
    }, [])

    useEffect(() => {
        switch (getListLab.requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possível carregar os labs no momento. Tente novamente mais tarde");
                navigator("/")
                break;
            default:
                setAllLabsNames(listAllLabs.map((lab) => ({ "labId": lab.id, "labName": lab.razao_social })))
                break;
        }
    }, [getListLab.requestState])

    const handleChangeLab = (event: SelectChangeEvent) => {
        setChosenLab(event.target.value as string);
    };

    const handleChangeLabList = (event: SelectChangeEvent<typeof chosenLabList>) => {
        const { target: { value }, } = event;
        setChosenLabList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const multiSelectHandler = (options: string[]) => {
        const details = options;
        const stringData = details.map((detail) => `${detail}`).join(',');
        console.log(stringData);
        return stringData
    };

    const handleInstitutionStats = () => {
        const labExamIdlist = multiSelectHandler(chosenLabList);
        if (chosenLabList.length <= 1) {
            window.alert("Selecione pelo menos 2 laboratórios para analisar");
        }
        else {
            localStorage.setItem("labIdList", labExamIdlist)
            navigator("/institutionStats/labIdList");
        }
    }
    const handleLabStats = () => {
        if (chosenLab === "") {
            window.alert("Selecione um laboratório");
        }
        else {
            localStorage.setItem("labId", chosenLab);
            navigator("/labStats/labId");
        }
    }
    return (
        <StyledStatsHomeScreen>

            <br></br>
            <h1>Aqui você pode ver estatísticas de vários laboratórios juntos</h1>
            <FormControl sx={{ m: 1, width: 360 }}>
                <InputLabel>Escolha um Conjunto de Laboratórios</InputLabel>
                <Select
                    multiple
                    value={chosenLabList}
                    onChange={handleChangeLabList}
                    input={<OutlinedInput />}
                    MenuProps={MenuProps}
                >
                    {allLabsNames.map((lab) => (
                        <MenuItem
                            key={lab.labId}
                            value={lab.labId}
                            style={getStyles(lab.labName, chosenLabList, theme)}
                        >
                            {lab.labName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <button className="button-primary button-animation" onClick={handleInstitutionStats}>Ver Estatísticas desse Conjunto de Laboratórios</button>
            <br></br>
            <h1>Aqui você pode ver estatísticas de um laboratório específico</h1>
            <Box sx={{ minWidth: 360 }}>
                <FormControl fullWidth>
                    <InputLabel>Escolha um Laboratório Específico</InputLabel>
                    <Select
                        value={chosenLab}
                        onChange={handleChangeLab}
                    >
                        {allLabsNames.map((lab) => (
                            <MenuItem
                                key={lab.labId}
                                value={lab.labId}
                            >
                                {lab.labName}
                            </MenuItem>

                        ))}
                    </Select>
                </FormControl>
            </Box>
            <button className="button-primary button-animation" onClick={handleLabStats}>Ver Estatísticas desse Laboratório Específico</button>


        </StyledStatsHomeScreen>
    )
}