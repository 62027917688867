import { Route, Routes } from "react-router-dom";
import LoginScreenGestor from "src/screens/LoginScreenGestor";

import {
    TestScreen,
    Error404Screen,
    HomeScreen,
    AccessDenied,
    LoginScreen,
    UserScreen,
    SignUp,
    SignUpGestor,
    ChooseExamsScreen,
    ApiTest,
    TermsAndConditions,
    ForgotPassword,
    ConfirmExam,
    ChangePassword,
    MyExams,
    MyExam,
    ExamTokenScreen,
    MyLabs,
    MyFuncionarios,
    AddFuncionario,
    AddLab,
    FuncionarioPage,
    ListExamConfirm,
    ChooseLabConfirmExam,
    MakeAppointmentAndClient,
    ChooseLabAppointment,
    ListLabExam,
    MyLabExams,
    AddLabExam,
    LabExamPage,
    AddBookingSlot,
    InstitutionStats,
    LabStats,
    LabInteractiveCalendar,
    StatsHomeScreen,
    EditExam,
    EditLab,
    MyLabPage,
    EditBookingSlot,
    MyLabExamsFuncionario,
} from "../screens";

export default function Navigation(): React.ReactElement {
    return (
        <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/test" element={<TestScreen />} />
            <Route path="/test-api" element={<ApiTest />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/logingestor" element={<LoginScreenGestor />} />

            <Route path="/signup" element={<SignUp />} />
            <Route path="/signupgestor" element={<SignUpGestor />} />
            <Route path="/user" element={<UserScreen />} />
            <Route path="/chooseExam/*" element={<ChooseExamsScreen />} />
            <Route path="/termsAndConditions" element={<TermsAndConditions />} />
            <Route path="/chooseLabConfirmExam" element={<ChooseLabConfirmExam />} />
            <Route path="/confirmExam" element={<ListExamConfirm />} />
            <Route path="/confirmExam/labId=:labId" element={<ListExamConfirm />} />
            <Route path="/confirmExam/labId" element={<ListExamConfirm />} />
            <Route path="/confirmExam/bookSlotId=:bookSlotId" element={<ConfirmExam />} />
            <Route path="/confirmExam/bookSlotId" element={<ConfirmExam />} />
            <Route path="/password/*" element={<ForgotPassword />}></Route>
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/accessDenied" element={<AccessDenied />} />
            <Route path="/myExams" element={<MyExams />} />
            <Route path="/myExams/id=:id_exam" element={<MyExam />} />
            <Route path="/myExams/id" element={<MyExam />} />
            <Route path="/exam_token" element={<ExamTokenScreen />} />
            
            <Route path="/myLabs" element={<MyLabs />} />
            <Route path="/myLabs/addLab" element={<AddLab />} />
            <Route path="/myLabs/labId=:labId" element={<MyLabPage />} />
            <Route path="/myLabs/labId" element={<MyLabPage />} />
            <Route path="/myLabs/labId=:labId/edit" element={<EditLab />} />
            <Route path="/myLabs/labId/edit" element={<EditLab />} />

            <Route path="/myLabs/labId=:labId/funcionarios" element={<MyFuncionarios />} />
            <Route path="/myLabs/labId/funcionarios" element={<MyFuncionarios />} />
            <Route path="/myLabs/labId=:labId/funcionarios/addFuncionario" element={<AddFuncionario />} />
            <Route path="/myLabs/labId/funcionarios/addFuncionario" element={<AddFuncionario />} />
            <Route path="/myLabs/labId=:labId/funcionarioId=:funcionarioId" element={<FuncionarioPage />} />
            <Route path="/myLabs/labId/funcionarioId" element={<FuncionarioPage />} />
            
            <Route path="/myLabExamsFuncionario" element={<MyLabExamsFuncionario />} />
            <Route path="/myLabs/labId=:labId/labExams" element={<MyLabExams />} />
            <Route path="/myLabs/labId/labExams" element={<MyLabExams />} />
            <Route path="/myLabs/labId=:labId/labExams/addLabExam" element={<AddLabExam />} />
            <Route path="/myLabs/labId/labExams/addLabExam" element={<AddLabExam />} />
            <Route path="/myLabs/labId=:labId/labExamId=:labExamId" element={<LabExamPage />} />
            <Route path="/myLabs/labId/labExamId" element={<LabExamPage />} />
            <Route path="/myLabs/labId=:labId/labExamId=:labExamId/edit" element={<EditExam />} />
            <Route path="/myLabs/labId/labExamId/edit" element={<EditExam />} />
            <Route path="/myLabs/labId=:labId/labExamId=:labExamId/add_booking_slot" element={<AddBookingSlot />} />
            <Route path="/myLabs/labId/labExamId/add_booking_slot" element={<AddBookingSlot />} />
            <Route path="/myLabs/labId=:labId/labExamId=:labExamId/bookingSlotId=:bookingSlotId" element={<EditBookingSlot />} />
            <Route path="/myLabs/labId/labExamId/bookingSlotId" element={<EditBookingSlot />} />

            <Route path="/ChooseLabAppointment" element={<ChooseLabAppointment />} />
            <Route path="/makeAppointment" element={<ListLabExam />} />
            <Route path="/makeAppointment/labId=:labId" element={<ListLabExam />} />
            <Route path="/makeAppointment/labId" element={<ListLabExam />} />
            <Route path="/makeAppointment/labExamId=:labExamId" element={<MakeAppointmentAndClient />} />
            <Route path="/makeAppointment/labExamId" element={<MakeAppointmentAndClient />} />

            <Route path="/statsHomeScreen" element={<StatsHomeScreen />} />
            <Route path="/institutionStats/labIdList=:labIdList" element={<InstitutionStats />} />
            <Route path="/institutionStats/labIdList" element={<InstitutionStats />} />
            <Route path="/labStats/labId=:labId" element={<LabStats />} />
            <Route path="/labStats/labId" element={<LabStats />} />
            <Route path="/interactiveCalendar/labId=:labId" element={<LabInteractiveCalendar />} />
            <Route path="/interactiveCalendar/labId" element={<LabInteractiveCalendar />} />

            <Route path="*" element={<Error404Screen />} />
        </Routes>
    )
}
