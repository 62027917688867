import { useEffect, useState } from 'react'
import { MdCancel, MdKeyboardArrowRight } from "react-icons/md"
import { Navigate, useNavigate } from 'react-router-dom';
import useRequest from 'src/hooks/useRequest';
import { BookingSlotLabExamUser, BookingSlotStatus, RequestState, RequestType } from '../types'

type CardExamProps = {
    booking_slot: BookingSlotLabExamUser,
    redirect: string,
    action?: () => void,
}

export default function CardBookSlot({ booking_slot, redirect, action = () => { } }: CardExamProps): React.ReactElement {
    const [active, setActive] = useState<Boolean>(false);

    function getTimestamp(date: string): string {
        const pad = (n: any, s = 2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
        const d = new Date(date);
        return `${pad(d.getDate())}/${pad(d.getMonth() + 1)}/${pad(d.getFullYear(), 4)} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
    }

    const handleClick = (): void => {
        action();
        setActive(true);
    }

    if (redirect && active) return <Navigate to={redirect} />
    return (
        <div onClick={handleClick} className="card" style={{ margin: 'auto', marginBottom: '30px', width: '90%' }}>
            <div className="flex flex-col justify-center w-full ml-8">
                <p className="font-semibold">{booking_slot.lab_exam.exam.name}</p>
                <p className="text-gray-dark text-sm">{booking_slot.lab_exam.lab.razao_social}</p>
                <p className="text-gray-dark text-sm">{getTimestamp(booking_slot.date_time_from)}</p>
                <p className="text-gray-dark text-sm">R$ {booking_slot.price}</p>
            </div>
            {
                <div className="my-auto py-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <MdKeyboardArrowRight size={24} style={{ marginBottom: '10px' }} />
                    {
                        booking_slot.status === BookingSlotStatus.free ?
                            <p className='text-red-700'>Cancelado</p> :
                            booking_slot.status === BookingSlotStatus.inProgress ?
                                <p className='text-yellow-500'>Em progresso</p> :
                                <p className='text-green-800'>Confirmado</p>
                    }
                </div>
            }
        </div>
    )
}