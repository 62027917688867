import MyDate from "./helpers/date"
import React from 'react';

// enums
export enum RequestState {
    NotStarted,
    Waiting,
    NotSuccessful,
    NotFound,
    Successful,
    UserNotFound,
    PasswordIncorrect,
    UnableToLogIn,
    EmailAlreadyInUse,
    CPFAlreadyInUse,
    WeakPassword,
    UnableToChangeProperty,
    CPFInvalid,
    EmailInvalid,
    BookingIdInvalid,
    BookingNotAvailable,
    BookingAlreadyBooked,
    NoBookingsFound,
    NotAuthorized,
    NotConnected,
    OldPasswordWrong,
    MissingData,
    InvalidToken,
    BirthdayIncorrect,
    EmailNotUser,
    UserAlready,
    GestorInvalid,
    GestorNotIdentified,
    UserAlreadyFuncionario,
    UserAlreadyGerente,
}

export enum RequestType {
    Get,
    Post,
    Patch,
    Put,
    Delete
}

export enum TypeAlert {
    success = "success",
    info = "info",
    warning = "warning",
    error = "error"
}

export enum BookingSlotStatus {
    free = "free",
    booked = "booked",
    inProgress = "inprogress"
}

export enum PermissionTypes {
    Staff = "is_staff",
    LabAdmin = "is_lab_admin"
}

export enum UserTypeList {
    GERENTE = "G",
    FUNCIONARIO = "F",
    CLIENTE = "C"
}

// types
export type RequestResponse = {
    status: number,
    data: any
}

export type LatLong = {
    lat: number,
    lng: number
}

export type Time = {
    [hour: string]: {
        available: boolean,
        id: number,
        price: string
    }
}

export type Day = {
    date: MyDate,
    times: Time
}

export type Permissions = {
    is_staff: boolean,
    is_lab_admin: boolean,
    moderation_lab?: number
}

export type UserData = {
    id: number,
    name: string,
    photo?: string,
    email: string,
    username: string
    birthday: string,
    cpf: string,
    user_type: string,
    phone_number: string,
    permissions: Permissions
}

export type Gestor = {
    id: number,
    user: UserData,
    isValid: boolean
}

export type Funcionario = {
    id: number,
    user: UserData,
    lab: Lab
}

export type Exam = {
    id: number,
    name: string,
    description: string,
}

export type BestExam = {
    id: number,
    name: string,
    description: string,
    ocorrencias: number
}

export type Lab = {
    id: number,
    gestor: Gestor,
    razao_social: string,
    cnpj: string,
    email: string,
    description: string,
    address: string,
    latitude: number,
    longitude: number,
    cep: string,
    telefone: string
}

export type BookingSlot = {
    id: number,
    status: BookingSlotStatus,
    duration: number,
    price: string,
    date_time_from: string,
    date_time_to: string,
    lab_exam: number,
    token: string,
}

export type BookingSlotLabExam = {
    id: number,
    status: BookingSlotStatus,
    duration: number,
    price: string,
    date_time_from: string,
    date_time_to: string,
    lab_exam: LabExam,
    token: string,
}

export type BookingSlotLabExamUser = {
    id: number,
    user: UserData,
    status: BookingSlotStatus,
    duration: number,
    price: string,
    date_time_from: string,
    date_time_to: string,
    lab_exam: LabExam,
    token: string,
}

export type LabExam = {
    id: number,
    lab: Lab,
    exam: Exam,
    booking_slots: BookingSlot[],
    min_price: number,
    max_price: number
}

// interfaces
export interface SearchFilter {
    tag: string,
    title: string,
    description: string,
    icon: React.ReactElement,
    compareFunctionGenerator: (props: any) => (obj1: any, obj2: any) => number
}

export enum TypeMessage {
    success = "success",
    info = "info",
    error = "error",
    warning = "warning"
}