import { useEffect, useState } from "react";
import { BookingSlotLabExamUser, BookingSlotStatus, RequestState, RequestType } from "../../types";
import useRequest from '../../hooks/useRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { useFuncionario, useGerente } from './../../hooks/useAuthorization';
import { Loader } from "src/components";
import { emptyBookingSlotLabExamUser } from "src/constants/emptyTypes";
import { getTimestamp } from "src/helpers/getTimestamp";

export default function ConfirmExam(): React.ReactElement {

    const navigate = useNavigate();
    let { bookSlotId } = useParams()
    if(bookSlotId === undefined) {
        const bookSlotIdLocalStorage = localStorage.getItem("bookSlotId");
        if (!bookSlotIdLocalStorage)
            navigate("/");
        else{
            bookSlotId = bookSlotIdLocalStorage
        }
    }
    const isGerente = useGerente();
    const isFuncionario = useFuncionario();
    useEffect(() => {
        if (!isGerente)
            if (!isFuncionario)
                navigate("../accessDenied");
    }, []);

    const [bookSlot, setBookSlot] = useState<BookingSlotLabExamUser>(emptyBookingSlotLabExamUser);
    const [confirm, setConfirm] = useState<boolean>(true);

    const getBookSlot = useRequest(`api/booking-slots/${bookSlotId}/`, setBookSlot, RequestType.Get)
    const confirmRequest = useRequest(`api/booking-slots/${bookSlotId}/book/confirm/`, () => { }, RequestType.Post);
    const cancelRequest = useRequest(`api/booking-slots/${bookSlotId}/book/cancel/`, () => { }, RequestType.Post);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (confirm) confirmRequest.call({});
        else cancelRequest.call({});
    }

    useEffect(() => {
        getBookSlot.call({})
        console.log("\nyooo", bookSlot)
    }, [])
    useEffect(() => {
        switch (getBookSlot.requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possível acessar a reserva no momento. Tente novamente mais tarde");
                navigate("/")
                break;
            default: break;
        }
    }, [getBookSlot.requestState])

    useEffect(() => {
        switch (confirmRequest.requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possível confirmar esse exame no momento. Tente novamente mais tarde.");
                break;
            case RequestState.NotFound:
                window.alert("O exame desejado não existe.");
                break;
            case RequestState.Successful:
                window.alert("Exame confirmado com sucesso!");
                // clearBookingId();
                navigate("/")
                break;
            case RequestState.BookingNotAvailable:
                window.alert("Este exame não está aguardando confirmação.");
                break;
            case RequestState.BookingAlreadyBooked:
                window.alert("Este exame já foi confirmado.");
                break;
            case RequestState.NotAuthorized:
                window.alert("Somente administradores de laboratórios podem alterar estados de exames.");
                navigate("/accessDenied");
                break;
            default: break;
        }
    }, [confirmRequest.requestState]);
    useEffect(() => {
        switch (cancelRequest.requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi cancelar esse exame no momento. Tente novamente mais tarde.");
                break;
            case RequestState.NotFound:
                window.alert("O exame desejado não existe.");
                break;
            case RequestState.Successful:
                window.alert("Exame cancelado com sucesso!");
                // clearBookingId();
                navigate("/")
                break;
            case RequestState.BookingNotAvailable:
                window.alert("Este exame não está aguardando confirmação ou cancelamento.");
                break;
            case RequestState.NotAuthorized:
                window.alert("Somente administradores de laboratórios podem alterar estados de exames.");
                navigate("/accessDenied");
                break;
            default: break;
        }
    }, [cancelRequest.requestState])

    return (
        <div className="page">
            <h1>Confirmar um exame</h1>
            <form action="#" className="w-full" onSubmit={handleSubmit}>
                <div className="flex flex-row align-middle">
                    {bookSlot !== undefined ? 
                        <section>
                            <section className="mt-3 flex flex-row align-text-top">
                                <p className="ml-0 font-semibold">{"Cliente"}:</p>
                                <p className="ml-4">{bookSlot.user.name}</p>
                            </section>
                            <section className="mt-3 flex flex-row align-text-top">
                                <p className="ml-0 font-semibold">{"Email Cliente"}:</p>
                                <p className="ml-4">{bookSlot.user.email}</p>
                            </section>
                            <section className="mt-3 flex flex-row align-text-top">
                                <p className="ml-0 font-semibold">{"Token"}:</p>
                                <p className="ml-4">{bookSlot.token}</p>
                            </section>
                            <section className="mt-3 flex flex-row align-text-top">
                                <p className="ml-0 font-semibold">{"Laboratório"}:</p>
                                <p className="ml-4">{bookSlot.lab_exam.lab.razao_social}</p>
                            </section>
                            <section className="mt-3 flex flex-row align-text-top">
                                <p className="ml-0 font-semibold">{"Exame"}:</p>
                                <p className="ml-4">{bookSlot.lab_exam.exam.name}</p>
                            </section>
                            <section className="mt-3 flex flex-row align-text-top">
                                <p className="ml-0 font-semibold">{"Data"}:</p>
                                <p className="ml-4">{getTimestamp(bookSlot.date_time_from)}</p>
                            </section>
                            <section className="mt-3 flex flex-row align-text-top">
                                <p className="ml-0 font-semibold">{"Preço"}:</p>
                                <p className="ml-4">R$ {bookSlot.price}</p>
                            </section>
                        </section> : <Loader />
                    }
                </div>
                {
                    (
                        confirmRequest.requestState === RequestState.BookingNotAvailable ||
                        confirmRequest.requestState === RequestState.NotFound ||
                        cancelRequest.requestState === RequestState.BookingNotAvailable ||
                        cancelRequest.requestState === RequestState.NotFound
                    ) ?
                        <p className="input-warning">Identificador inválido.</p> : <></>
                }
                {
                    !(bookSlot.status === BookingSlotStatus.booked) && confirmRequest.requestState === RequestState.Waiting ?
                        <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> : 
                            !(bookSlot.status === BookingSlotStatus.booked) ?
                            <input onClick={() => setConfirm(true)} type="submit" value="Confirmar reserva" className="button-primary button-animation w-full" /> :
                            <></>
                }
                {
                    cancelRequest.requestState === RequestState.Waiting ?
                        <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> :
                        <input onClick={() => setConfirm(false)} type="submit" value="Cancelar reserva" className="button-secondary button-animation w-full" />
                }
            </form>
        </div>
    )
}