import React from 'react';
import Reset from './Reset';
import GetEmail from './GetEmail';
import { Route, Routes } from 'react-router-dom';
export default function ForgotPassword(): React.ReactElement {

    return (
        <div style={{marginTop: "-40px", minHeight: "100%", height: "100vh", backgroundColor: "#37E0B7"}}>
            <Routes >
                <Route index element={<GetEmail />} />
                <Route path="reset/:uidb64/:token/" element={<Reset />} />
            </Routes>
        </div>
    )
}
