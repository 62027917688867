import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import store from './redux/store';
import { Provider } from 'react-redux';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// removing loader on mount
const loader: HTMLElement | null = document.getElementById("loader");
loader?.remove();

// configuring sentry

console.log(process.env.NODE_ENV)

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev mode
} else {
    Sentry.init({
      dsn: "https://e7a42e31defa41ea909d89a121f9871a@o1242788.ingest.sentry.io/6433893",
        integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });

}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
