import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DateTimeTable, Loader } from 'src/components';
import { emptyBookingSlotList } from 'src/constants/emptyTypes';
import MyDate from 'src/helpers/date';
import { createTimeSchedule } from '../ChooseExamsScreen/ChooseTime'
import textFromInputEvent from 'src/helpers/textFromInputEvent';
import { getAge, validateCPF, isInvalidEmail, isInvalidName, validatePhoneNumber } from 'src/helpers/validator';
import useRequest from 'src/hooks/useRequest';
import { BookingSlot, Day, RequestState, RequestType } from 'src/types';
import axios from '../../commons/axios'
import AlertDialog from 'src/components/Popup';
import { AxiosError } from 'axios';

type userData = {
    name: string,
    email: string,
    phone_number: string,
    birthday: string,
    cpf: string,
}

const emptyUserDataSignUp = {
    name: "",
    email: "",
    phone_number: "",
    birthday: "",
    cpf: "",
}

const emptyDay: Day = {
    date: new MyDate(),
    times: {
        "": {
            available: false,
            id: 0,
            price: ""
        }
    }
}

const emptyDayTable: Day[] = [emptyDay]

export default function MakeAppointmentAndClient(): React.ReactElement {

    const [signUpData, setSignUpData] = useState<userData>(emptyUserDataSignUp)
    let { labExamId } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if(labExamId === undefined) {
            const bookSlotIdLocalStorage = localStorage.getItem("labExamId");
            if (!bookSlotIdLocalStorage)
                navigate("/");
            else{
                labExamId = bookSlotIdLocalStorage
            }
        }
    }, [])

    const [typeGetUser, setTypeGetUser] = useState("email")
    const [typeRegister, setTypeRegister] = useState("user_client")
    const [inputGetUser, setInputGetUser] = useState("")
    const [userFound, setUserFound] = useState("")
    const [inicioDate, setInicioDate] = useState("")
    const [fimDate, setFimDate] = useState("")
    const [openPopup, setOpenPopup] = useState(false)
    const [bookSlotDate, setBookSlotDate] = useState<String>()
    const [bookSlotHour, setBookSlotHour] = useState<String>()
    const [bookSlotId, setBookSlotId] = useState(0)
    const [search, setSearch] = useState(false)
    const [dayTable, setDayTable] = useState<Day[]>(emptyDayTable);
    const [schedule, setSchedule] = useState<BookingSlot[]>(emptyBookingSlotList);

    var today = new Date().toISOString().split('T')[0];

    const getBookSlotRequest = useRequest('api/lab-exams/get_available_book_slots_by_lab_exam_id/', setSchedule, RequestType.Get);
    const getBookSlotRequestState = getBookSlotRequest.requestState;

    const signUpRequest = useRequest('api/users/simple_create/', setSignUpData, RequestType.Post);
    const requestState = signUpRequest.requestState;

    const signUpDataSetter = (data: any) => {
        const { name, email, phone_number, birthday, cpf } = data
        const newData = {
            name: name || signUpData.name,
            email: email || signUpData.email,
            phone_number: phone_number || signUpData.phone_number,
            birthday: birthday || signUpData.birthday,
            cpf: cpf || signUpData.cpf,
        }
        setSignUpData(newData);
    }

    const getUser = () => {
        let payload = {}
        if (typeGetUser === "email") payload = { email: inputGetUser }
        else if (typeGetUser === "phone_number") payload = { phone_number: inputGetUser }
        else if (typeGetUser === "cpf") payload = { cpf: inputGetUser }
        axios.get("/api/users/get_user/", {
            params: payload
        }).then(response => {
            if (response.status === 200) {
                setSignUpData(response.data)
                setUserFound("found")
            }
        })
            .catch((e: AxiosError) => {
                if (e.response?.data.error == "more_users")
                    setUserFound("more_users")
                else
                    setUserFound("not_found")
                setSignUpData(emptyUserDataSignUp)
                // console.log(e)
            })
    }

    const handleInputRegister = (key: string | number) => (event: any) => signUpDataSetter({ [key]: (textFromInputEvent(event)) })

    const handleFilter = () => {
        if (inicioDate !== "" && fimDate !== "") {
            setSearch(true)
            setDayTable(createTimeSchedule(
                schedule.filter((bookingSlot: BookingSlot) => {
                    return bookingSlot.id > 0 &&
                        bookingSlot.date_time_from !== "" &&
                        new Date(bookingSlot.date_time_from).getTime() > new Date(inicioDate + "T00:00:00-03:00").getTime() &&
                        new Date(bookingSlot.date_time_from).getTime() < new Date(fimDate + "T23:59:59-03:00").getTime()
                })
            ))
        } else {
            window.alert("As datas não podem ser vazias")
        }
    }

    const checkErrors = () => {
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        if (typeRegister === "user_client") {
            if (userFound !== "found") {
                window.alert("Usuário não encontrado"); return true
            }
        } else {
            if (getAge(signUpData.birthday) < 18) {
                window.alert("Você deve ser maior de 18 anos para utilizar o serviço."); return true
            }
            if (isInvalidName(signUpData.name)) {
                window.alert("Insira um nome válido!"); return true
            }
            if (isInvalidEmail(signUpData.email)) {
                window.alert("Insira um e-mail válido!"); return true
            }
            if (validateCPF(signUpData.cpf)) {
                window.alert("CPF deve ser numérico e conter 11 dígitos"); return true
            }
            if (validatePhoneNumber(signUpData.phone_number)) {
                window.alert("Número deve ser numérico e conter 11 dígitos (DDD + 9 + Número)"); return true
            }
        }
        return false
    }

    const handleSubmit = async () => {
        if (typeRegister === "user_not_client") {
            axios.post("/api/users/simple_create/", {
                ...signUpData,
                password: "senhasenha123",
                created_at: ""
            }).then(async response => await handleBook())
                .catch(async (error: AxiosError) => {
                    let responseData = error.response?.data
                    if ("email" in responseData && responseData.email.includes("email_already_in_use")) {
                        window.alert("E-mail já está em uso e reserva será marcado para este e-mail");
                        await handleBook();
                    } else if (
                        ("password1" in responseData && responseData.password1.includes("password_too_common")) ||
                        ("password1" in responseData && responseData.password1.includes("password_too_short")) ||
                        ("password1" in responseData && responseData.password1.includes("password_only_numbers")) ||
                        ("new_password2" in responseData && responseData.new_password2.includes("password_too_common")) ||
                        ("new_password2" in responseData && responseData.new_password2.includes("password_too_short")) ||
                        ("new_password2" in responseData && responseData.new_password2.includes("password_only_numbers"))
                    ) {
                        window.alert("Esta senha é muito fraca ou comum. As senhas devem ter no mínimo 8 caracteres, que não sejam todos numéricos."); return
                    } else {
                        window.alert("Parece que temos um problema momentaneo. Tente novamente mais tarde!"); return
                    }
                })
        } else {
            await handleBook()
        }
    }

    const handleBook = async () => {
        if (bookSlotDate !== undefined && bookSlotHour !== undefined && bookSlotId !== undefined) {
            let response = await axios.post("/api/booking-slots/book_confirm_user/", {
                email: signUpData.email,
                book_slot_id: bookSlotId
            })
            if (response.status === 200 || response.status === 201) {
                window.alert("Reservado com sucesso.")
                navigate("/")
            }
        } else {
            window.alert("Erro no sistema, tente novamente mais tarde")
        }
    }

    useEffect(() => {
        if (labExamId !== undefined) {
            getBookSlotRequest.call({ lab_exam_id: parseInt(labExamId) })
        }
    }, [labExamId])

    useEffect(() => {
        switch (getBookSlotRequestState) {
            case RequestState.NotSuccessful:
                window.alert("Erro no sistema, tente novamente mais tarde")
                break;
            default:
                break;
        }
    }, [getBookSlotRequestState])

    useEffect(() => {
        switch (requestState) {
            case RequestState.Successful:
                break;
            case RequestState.EmailAlreadyInUse:
                window.alert("Este e-mail já está cadastrado. Por favor, tente outro.");
                break;
            case RequestState.NotSuccessful:
                window.alert("Erro no cadastro. Tente novamente mais tarde.")
                break;
            default:
                break;
        }
    }, [requestState])

    return (
        <div className="page">
            {
                openPopup ?
                    <AlertDialog
                        open={openPopup}
                        setOpen={setOpenPopup}
                        handleAgree={async () => handleSubmit()}
                        handleDisagree={() => { }}
                        title={"Deseja reservar esse horário?"}
                        message={"A reserva para o dia " + bookSlotDate + " no horário " + bookSlotHour}
                    /> : <></>
            }
            <h1>Marcar um horário</h1>
            <div className="flex flex-col">
                <RadioGroup
                    row
                    style={{ marginTop: "10px", marginBottom: "20px", alignContent: "center", justifyContent: "center" }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue="user_client"
                    onChange={e => setTypeRegister(e.target.value)}
                >
                    <FormControlLabel value="user_client" control={<Radio />} label="Cliente Cadastrado" />
                    <FormControlLabel value="user_not_client" control={<Radio />} label="Cliente não Cadastrado" />
                </RadioGroup>
                {
                    typeRegister === "user_client" ?
                        <div style={{ display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center" }}>
                            <label className="text-center">
                                Escolha a opção de busca do usuário:
                                <select
                                    onChange={(e) => setTypeGetUser(e.target.value)}
                                    value={typeGetUser}
                                >
                                    <option value="email">Email</option>
                                    <option value="phone_number">Telefone</option>
                                    <option value="cpf">CPF</option>
                                </select>
                            </label>
                            <input className='line-input' onChange={e => setInputGetUser(e.target.value)} type="text" placeholder="Email/Telefone/CPF" />
                            {
                                userFound === "found" ?
                                    <div>
                                        <p className="text-center mt-2 mb-2">Nome: {signUpData.name}</p>
                                        <p className="text-center mt-2 mb-2">Data de nascimento: {signUpData.birthday}</p>
                                    </div> : userFound === "not_found" ?
                                        <p className="input-warning">Usuário Não encontrado</p> :
                                        userFound === "more_users" ?
                                            <p className="input-warning">Mais de usuário com estas informações, tente outra opção de busca</p> : <></>
                            }
                            <button className="button-primary" style={window.innerWidth > 400 ? { width: "70%", alignSelf: "center" } : { alignSelf: "center" }} onClick={getUser}>Pesquisar usuário</button>
                        </div> :
                        <div>
                            <input className='line-input' value={signUpData.name} onChange={handleInputRegister("name")} type="text" placeholder="*Nome Completo" required />
                            <input className='line-input' value={signUpData.email} onChange={handleInputRegister("email")} type="text" placeholder="Email" />
                            {
                                requestState === RequestState.EmailAlreadyInUse ?
                                    <p className='input-warning'>Este e-mail já está cadastrado.</p> : <></>
                            }
                            <input className='line-input' value={signUpData.cpf} onChange={handleInputRegister("cpf")} type="text" placeholder="*CPF (somente números)" required />
                            {
                                (requestState === RequestState.CPFAlreadyInUse || requestState === RequestState.CPFInvalid) ?
                                    <p className='input-warning'>Este CPF já está cadastrado ou é inválido'.</p> : <></>
                            }
                            <input className='line-input' value={signUpData.phone_number} onChange={handleInputRegister("phone_number")} type="text" placeholder="*Celular com DDD" required />{

                            }
                            <input className='line-input' value={signUpData.birthday} onChange={handleInputRegister("birthday")} type="date" placeholder="Data de Nascimento (você deve ter pelo menos 18 anos)" />
                            {
                                getAge(signUpData.birthday) < 18 ?
                                    <p className='input-warning'>Você deve ser maior de 18 anos para utilizar o serviço.</p> : <></>
                            }
                        </div>
                }
                <p className="text-center mt-6 mb-2"><b>Escolha do horário</b></p>
                <div className={window.innerWidth > 400 ? "flex flex-row" : "flex flex-col"} style={{ alignSelf: "center" }}>
                    <section className={window.innerWidth > 400 ? "mr-5" : ""} >
                        <p>Início</p>
                        <input className='line-input' min={today} onChange={e => setInicioDate(e.target.value)} type="date" placeholder="Início" />
                        {
                            inicioDate === "" ?
                                <p className="input-warning">Selecione a data de início de busca</p> : <></>
                        }
                    </section>
                    <section className={window.innerWidth > 400 ? "ml-5" : ""}>
                        <p>Fim</p>
                        <input className='line-input' min={inicioDate !== "" ? inicioDate : today} onChange={e => setFimDate(e.target.value)} type="date" placeholder="Fim" />
                        {
                            fimDate === "" ?
                                <p className="input-warning">Selecione a data de fim de busca</p> : <></>
                        }
                    </section>
                </div>
                <button className="button-primary" style={window.innerWidth > 400 ? { width: "70%", alignSelf: "center" } : { alignSelf: "center" }} onClick={handleFilter}>Pesquisar horário</button>
                <div>
                    {
                        !search ? <></> :
                            requestState === RequestState.Waiting ?
                                <Loader /> :

                                dayTable.length === 0 ?
                                    <p className="input-warning text-center mt-2 mb-6">Sem horários no período selecionado</p> :
                                    dayTable.map((day: Day, index: number) => {
                                        return <DateTimeTable
                                            day={day}
                                            index={index}
                                            collapsible={index > 1}
                                            settingBookingIdAction={(bookingId: number, date: String, hour: String) => async () => {
                                                let errorFound = await checkErrors()
                                                if (!errorFound) {
                                                    setBookSlotId(bookingId)
                                                    setBookSlotDate(date)
                                                    setBookSlotHour(hour)
                                                    setOpenPopup(true)
                                                }
                                            }}
                                            key={index}
                                        />
                                    })
                    }
                </div>
            </div>
        </div>
    )
}