import { useState } from 'react'
import { MdKeyboardArrowRight } from "react-icons/md"
import { Navigate, useNavigate } from 'react-router-dom';
import { BookingSlot, BookingSlotStatus, LabExam } from '../types'

type CardExamProps = {
    lab_exam: LabExam,
    redirect: string,
    action?: () => void,
}

export default function CardLabExam({ lab_exam, redirect, action = () => { } }: CardExamProps): React.ReactElement {
    const navigate = useNavigate()

    function getTimestamp(date: string): string {
        const pad = (n: any, s = 2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
        const d = new Date(date);
        return `${pad(d.getDate())}/${pad(d.getMonth() + 1)}/${pad(d.getFullYear(), 4)} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
    }

    var today = new Date().toISOString().split('T')[0];

    const handleClick = (e:any): void => {
        e.preventDefault();
        console.log("clicado aqui")
        action();
        if(redirect){
            navigate(redirect)
            return
        }
    }

    return (
        <div onClick={handleClick} className="card" style={{ margin: 'auto', marginBottom: '30px', width: '90%' }}>
            <div className="flex flex-col justify-center w-full ml-8">
                <p className="font-semibold">{lab_exam.exam.name}</p>
                <p className="text-gray-dark text-sm">{lab_exam.lab.razao_social}</p>
                <p className="text-gray-dark text-sm">Horários Livres: {
                    lab_exam.booking_slots.filter((bookSlot: BookingSlot) => (
                        bookSlot.status === BookingSlotStatus.free &&
                        new Date(bookSlot.date_time_from).getTime() > new Date(today).getTime()
                    )).length
                }</p>
            </div>
            {
                <div className="my-auto py-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={handleClick}>
                    <MdKeyboardArrowRight size={24} onClick={handleClick} style={{ marginBottom: '10px' }} />
                </div>
            }
        </div>
    )
}