import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux"
import { useState } from 'react';
import setFromEvent from '../../helpers/setFromEvent';
import useRequest from '../../hooks/useRequest';
import { RequestState, RequestType } from '../../types';
import { useEffect } from 'react';
import { Loader } from '../../components';
import { resetLocation } from "src/redux/features/user";
import useAuthorization from "../../hooks/useAuthorization"


export default function ChangePassword(): React.ReactElement {
    const dispatcher = useDispatch();
    const navigator = useNavigate();

    const [old_password, setOldPassword] = useState<string>("");
    const [password1, setPassword1] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");

    const request = useRequest('auth/password/change/', () => { }, RequestType.Post);
    const requestState = request.requestState;

    const handleSubmit = (event: any): void => {
        event.preventDefault();
        if (password1 !== password2) {
            window.alert("Suas duas senhas devem ser iguais!"); return
        }
        else if ((password1.length < 8) || (password1.match(/^[0-9]+$/) != null)) {
            window.alert("Sua senha deve ter no mínimo 8 caracteres e não pode ser completamente numérica"); return
        }
        request.call({
            old_password: old_password,
            new_password1: password1,
            new_password2: password2
        })
    }

    useEffect(() => {
        switch (requestState) {
            case RequestState.Successful:
                window.alert("Senha alterada com sucesso!");
                localStorage.clear();
                dispatcher(resetLocation());
                // navigator("/login");
                window.location.reload();
                break;
            case RequestState.OldPasswordWrong:
                window.alert("Senha atual errada. Por favor digite a senha correta.");
                break;
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            default: break;
        }
    }, [requestState])

    if (!useAuthorization()) return <Navigate to="/login" replace />
    if (requestState === RequestState.Successful) return <Navigate to='/login' replace />
    return (
        <div>
            <h1 style={{ marginTop: '15px', textAlign: 'center', marginBottom: '20px' }}>Altere sua senha</h1>
            <form onSubmit={handleSubmit} action="#">
                <input className='line-input' onChange={setFromEvent(setOldPassword)} type="password" placeholder='Senha atual' />
                <input className='line-input' onChange={setFromEvent(setPassword1)} type="password" placeholder='Nova senha' />
                {
                    requestState === RequestState.WeakPassword ?
                        <p className='input-warning'>Esta senha é muito fraca ou comum. As senhas devem ter no mínimo 8 caracteres.</p> : <></>
                }
                <input style={{ marginBottom: '40px' }} className='line-input' onChange={setFromEvent(setPassword2)} type="password" placeholder='Repita sua nova senha' />
                {
                    password1 !== password2 ?
                        <p style={{ marginBottom: '10px' }} className='input-warning'>As duas senhas devem ser iguais!</p> : <></>
                }
                {
                    requestState === RequestState.Waiting ?
                        <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> :
                        <input type="submit" value="Alterar senha" className='button-primary button-animation w-full' />
                }
            </form>

        </div>
    )
}