import { Card } from '../../components'
import { SearchFilter } from '../../types';
import { useDispatch } from 'react-redux';
import { setFilterId } from '../../redux/features/exam';
import searchFilters from './../../constants/searchFilters';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type ChooseFilterProps = {
    setPage: () => void,
}

export default function ChooseFilter ({ setPage}: ChooseFilterProps): React.ReactElement {
    // initial data
    const navigator = useNavigate();
    const dispatcher = useDispatch();
    const examId = useSelector((state: any) => state.exam.examId);
    const createHandleChoose = (filterId: number) => () => {
        dispatcher(setFilterId(filterId));
        navigator("../clinic");
    }

    // checking if needed data
    useEffect(() => {
        if(examId === null) navigator("../");
        setPage()
    }, []);

    // getting location 
    const userLocationAuth = useSelector((state: any) => state.user.locationAuth);

    return (
        <div className="flex flex-col items-center">
            <h1>Escolha a melhor opção</h1>
            <div style={window.innerWidth > 1000? {width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"} : {width: "100%", display: "flex", flexDirection: "column", justifyContent: "center",alignItems: "center"} }>
                {
                    searchFilters.map((filter: SearchFilter, index: number) => (
                        filter.tag === "closest" && !userLocationAuth ? <div key={index}></div> :
                        <div>
                        <Card 
                            big 
                            icon={filter.icon} 
                            title={filter.title} 
                            subtitle={filter.description} 
                            action={createHandleChoose(index)} 
                            key={index}
                        />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}