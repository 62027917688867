import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Loader } from 'src/components';
import CardClinic from 'src/components/CardClinics';
import { emptyListLab } from 'src/constants/emptyTypes';
import { useGerente } from 'src/hooks/useAuthorization';
import useRequest from 'src/hooks/useRequest';
import { Lab, RequestState, RequestType } from 'src/types';

export default function ChooseLabAppointment(): React.ReactElement {

    const navigate = useNavigate();
    const isGerente = useGerente();

    const [listLab, setListLab] = useState<Lab[]>(emptyListLab)

    useEffect(() => {
        if (!isGerente) navigate("../accessDenied");
    }, []);

    const getListLab = useRequest(`api/labs/get_labs_by_gestor/`, setListLab, RequestType.Get);

    useEffect(() => getListLab.call({}), [])

    useEffect(() => {
        switch (getListLab.requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possível carregar os labs no momento. Tente novamente mais tarde");
                navigate("/")
                break;
            default: break;
        }
    }, [getListLab.requestState])

    return (
        <div className="page">
            {
                listLab.length === 0 ?
                    <p className='mx-4 my-2'>Nenhumo laboratório disponível</p> :
                    <div>
                        <h1 className="text-center mb-5 mt-3">Escolha umo laboratório:</h1>
                        {
                            getListLab.requestState === RequestState.Waiting ?
                                <Loader /> :
                                <div>
                                    {listLab.map((lab: Lab) => (
                                        <CardClinic
                                            razao_social={lab.razao_social}
                                            email={lab.email}
                                            address={lab.address}
                                            action={() => localStorage.setItem("labId", lab.id.toString())}
                                            redirect={`/makeAppointment/labId`}
                                        />
                                    ))}
                                </div>
                        }
                    </div>
            }
        </div>
    )
}