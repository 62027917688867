import React from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { BookingSlot, BookingSlotStatus, Day, LabExam, RequestState, RequestType } from '../../types';
import { DateTimeTable, Loader } from '../../components';
import useAuthorization from "../../hooks/useAuthorization"
import MyDate from 'src/helpers/date';
import DeleteButton from 'src/components/EditData/DeleteButtons';

const emptyDayTable: Day[] = [{
    date: new MyDate(),
    times: {
        "": {
            available: false,
            id: 0,
            price: ""
        }
    }
}]

export default function LabExamPage(): React.ReactElement {
    const navigate = useNavigate()
    let { labExamId, labId } = useParams()
    if(labExamId === undefined) {
        const labExamIdLocalStorage = localStorage.getItem("labExamId");
        if (!labExamIdLocalStorage)
            navigate("/");
        else{
            labExamId = labExamIdLocalStorage
        }
    }
    if(labId === undefined) {
        const labIdLocalStorage = localStorage.getItem("labId");
        if (!labIdLocalStorage)
            navigate("/");
        else{
            labId = labIdLocalStorage
        }
    }
    const [lab_exam, SetLabExam] = useState<LabExam>()
    const [listBookSlot, setListBookSlot] = useState<BookingSlot[]>()
    const [dayTable, setDayTable] = useState<Day[]>(emptyDayTable);
    const request = useRequest('api/lab-exams/get_lab_exam_by_id/', SetLabExam, RequestType.Get);

    const requestState = request.requestState;
    const createHandleChoose = (bookingId: number, date: String, hour: String) => () => {
        localStorage.setItem("labId", labId?.toString() || "");
        localStorage.setItem("labExamId", labExamId?.toString() || "");
        localStorage.setItem("bookingSlotId", bookingId.toString());
        navigate("/myLabs/labId/labExamId/bookingSlotId")
    }
    useEffect(() => {
        request.call({
            lab_exam_id: labExamId
        });
    }, [])
    useEffect(() => {
        setListBookSlot(lab_exam?.booking_slots)
    }, [lab_exam])

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            default:
                if (listBookSlot !== undefined) {
                    setDayTable(createTimeSchedule(listBookSlot))
                }
                break;
        }
    }, [requestState, listBookSlot])

    if (!useAuthorization()) return <Navigate to="/login" replace />
    // translate timetable into usable format


    return (
        <div>
            {
                requestState === RequestState.Waiting || lab_exam === undefined || labExamId === undefined ?
                    <Loader />
                    : <div style={{ margin: "auto", marginBottom: "30px" }}>
                        <h1 style={{ marginTop: '15px', textAlign: 'center', marginBottom: '10px' }}>{lab_exam?.exam?.name}</h1>
                        <h1 className="font-bold text-center pt-3">Detalhes do Exame:</h1>
                        <div className="flex flex-row align-middle">
                            <section>
                                <section className="mt-3 flex flex-row align-text-top">
                                    <p className="ml-0 font-semibold">{"Descrição"}:</p>
                                    <p className="ml-4">{lab_exam?.exam?.description}</p>
                                </section>
                            </section>
                        </div>
                        <h1 className="font-bold text-center pt-3">Horários Disponíveis:</h1>
                        <section className="mb-6" style={{ margin: 'auto', paddingBottom: "10px", borderRadius: '20px' }}>
                            {
                                dayTable.length === 0 ?
                                    <div className='w-full flex items-center flex-col'>
                                        <p className='my-4 mx-2 '> Não temos horários disponíveis para esse exame.</p>
                                    </div > :
                                    dayTable.map((day: Day, index: number) => (
                                        <DateTimeTable
                                            day={day}
                                            index={index}
                                            collapsible={index > 1}
                                            settingBookingIdAction={createHandleChoose}
                                            key={index}
                                        />
                                    ))
                            }
                        </section>
                        <Link className="button-primary" onClick={() => {
                            localStorage.setItem("labId", labId?.toString() || "");
                            localStorage.setItem("labExamId", labExamId?.toString() || "");
                        }} to={"/myLabs/labId/labExamId/add_booking_slot"}>Disponibilizar Novos Horários</Link>
                        <DeleteButton entity={"lab-exams"} entityId={labExamId} name={"exame"}/>
                    </div>
            }
        </div>
    )
}


export const createTimeSchedule = (bookingSlots: BookingSlot[]): Day[] => {
    let dayList: Day[] = [];
    bookingSlots.forEach((bookingSlot: BookingSlot) => {
        const date: MyDate = new MyDate(bookingSlot.date_time_from);
        const today: MyDate = new MyDate();
        if (MyDate.compareDates(date, today) < 0) return;

        const registeredDates = dayList.map(day => day.date.getBR());

        // case where the date is already in the list
        if (registeredDates.includes(date.getBR())) {
            const desiredDay = dayList.find(day => day.date.getBR() === date.getBR())
            if (!desiredDay) return;
            desiredDay.times[date.getHour()] = {
                available: bookingSlot.status === BookingSlotStatus.free,
                id: bookingSlot.id,
                price: bookingSlot.price
            }
        }
        else {
            dayList.push({
                date: date,
                times: {
                    [date.getHour()]: {
                        available: bookingSlot.status === BookingSlotStatus.free,
                        id: bookingSlot.id,
                        price: bookingSlot.price
                    }
                }
            })
        }
    })
    return dayList.sort((a: Day, b: Day) => MyDate.compareDates(a.date, b.date));;
}