import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        lat: localStorage.getItem("lat") !== null ? localStorage.getItem("lat"): 0,
        long: localStorage.getItem("long") !== null ? localStorage.getItem("long"): 0,
        cep: localStorage.getItem("cep") !== null ? localStorage.getItem("cep"): "",
        locationAuth: localStorage.getItem("locationAuth") !== null ? localStorage.getItem("locationAuth") === "true" : null,
        userData: null
    },
    reducers: {
        setLocation: (state, action) => {
            state.lat = action.payload.location[0]
            state.long = action.payload.location[1]
            state.cep = action.payload.location[2]
            state.locationAuth = action.payload.locationAuth
            localStorage.setItem("locationAuth", action.payload.locationAuth)
            localStorage.setItem("lat", action.payload.location[0])
            localStorage.setItem("long", action.payload.location[1])
            localStorage.setItem("cep", action.payload.location[2])
        },
        setUserData: (state, action) => {
            state.userData = action.payload
        },
        resetLocation: (state) => {
            state.lat = null
            state.long = null
            state.cep = null
            state.locationAuth = false
            localStorage.removeItem("lat")
            localStorage.removeItem("long")
            localStorage.removeItem("cep")
            localStorage.removeItem("locationAuth")
        }
    }
})

export const {setUserData, setLocation, resetLocation} = userSlice.actions;

export default userSlice.reducer;