import { useState } from 'react'
import {MdKeyboardArrowRight} from "react-icons/md"
import { Navigate } from 'react-router-dom';

type CardProps = {
    icon?: React.ReactElement,
    title: string,
    subtitle: string,
    additionalText?: string|null,
    subtitleHighlight?: string,
    redirect?: string,
    action?: () => void,
    big?: boolean,
}

export default function Card({title, subtitle, additionalText = null, icon, action = () => {}, big = false, redirect = undefined, subtitleHighlight = ""}: CardProps): React.ReactElement{
    const [active, setActive] = useState<Boolean>(false);
    const handleClick = (): void => {
        action();
        setActive(true);
    }

    if(redirect && active) return <Navigate to={redirect} />
    return (
        <div onClick={handleClick}
        style = {{width: "285px",
                margin: "24px 24px",
                height: "165px",
                background: "#FFFFFF",        
                border: "1px solid #F5F5F5",
                borderRadius: "14px"}}
        className={
            big ? "card flex-col items-center" : "card"
        }>
            <div style={{background: "#B6EDE0", width: "60px", height: "60px", borderRadius: "30px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                {icon}
            </div>
            <div className={
                big?"flex flex-col justify-center items-center w-full pb-4":"flex flex-col justify-center w-full "
            }>
                <p className=" font-semibold">{title}</p>
                <p className='text-pink'>{subtitleHighlight}</p>
                <p className=" text-gray-dark text-sm">{subtitle}</p>
                {
                    additionalText === null?<></>:
                    <p className=" text-gray-dark text-sm">{additionalText}</p>
                }
            </div>
            {
                big?<></>:
                <div className="my-auto py-2">
                    <MdKeyboardArrowRight size={24}/>
                </div>
            }

        </div>
    )
}