import { useState, useEffect } from 'react';
import { Card, Loader, SearchBar } from '../../components'
import { BestExam, Exam, LatLong, RequestState, RequestType } from '../../types';

import examsIcons from './../../constants/examsIcons';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { setExamId, setExamName } from '../../redux/features/exam';
import useRequest from '../../hooks/useRequest';
import { useNavigate } from 'react-router-dom';
import { useGerente, useGerenteInvalid } from 'src/hooks/useAuthorization';
import ReactPaginate from 'react-paginate';
import BannerExams from '../../assets/images/bannerExames.svg'
import CardExam from "../../components/CardExam"
import setFromEvent from 'src/helpers/setFromEvent';
import cleanCep from 'src/helpers/cleanCep';
import checkCep from 'src/helpers/checkCep';
import { setLocation } from 'src/redux/features/user';
import axios from '../../commons/axios'

type ChooseExamProps = {
    setPage: () => void,
}

const emptyExams: Exam[] = [
    {
        id: 0,
        name: "",
        description: ""
    },
]

const emptyBestExams: BestExam[] = [
    {
        id: 0,
        name: "",
        description: "",
        ocorrencias: 0,
    },
]

const dummyLatLong: LatLong = {
    lat: 1,
    lng: 1
}

export default function ChooseExam({ setPage }: ChooseExamProps): React.ReactElement {

    // initial data
    const navigator = useNavigate();
    const dispatcher = useDispatch();
    const isInvalidGerente = useGerenteInvalid();
    const isValidGerente = useGerente();
    const [exams, setExams] = useState<Exam[]>(emptyExams);
    const [bestExams, setBestExams] = useState<BestExam[]>(emptyBestExams);

    const createHandleChoose = (examId: number, examName: string) => () => {
        let storeState:any = store.getState()
        if(storeState.user.locationAuth !== true){
            if(newcep === ""){
                alert("Nenhum CEP salvo ainda, insira um CEP para continuar!")
                return;
            }
            else if(!checkCep(newcep.toString())){
                alert("Cep inválido!")
                return;
            }
            return;
        }
        dispatcher(setExamId(examId));
        dispatcher(setExamName(examName))
        navigator("/chooseExam/filter")
    }
    useEffect(() => setPage(), []);

    const store = useStore()

    // cep
    const { lat, long, cep, locationAuth } = useSelector((state: any) => state.user);
    const [latitude, setLatitude] = useState<number>(lat);
    const [longitude, setLongitude] = useState<number>(long);
    const [cepState, setCepState] = useState<string>(cep);
    const [locationAuthorization, setLocationAuthorization] = useState<null | boolean>(locationAuth);

    useEffect(() => {
        dispatcher(setLocation({ location: [latitude, longitude, cepState], locationAuth: locationAuthorization }));
    }, [locationAuthorization, longitude, latitude, cepState]);

    // get data request
    const request = useRequest('api/exams/', setExams, RequestType.Get);
    const requestState = request.requestState

    const requestBest = useRequest('api/exams/get_best_exams/', setBestExams, RequestType.Get);
    const requestStateBest = requestBest.requestState
    useEffect(() => request.call({}), []);
    useEffect(() => requestBest.call({}), []);
    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel recuperar os exames no momento. Tente novamente mais tarde.")
                break;
            default: break;
        }
    }, [requestState]);

    useEffect(() => {
        switch (requestStateBest) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel recuperar os exames mais procurados no momento. Tente novamente mais tarde.")
                break;
            default: break;
        }
    }, [requestStateBest])

    const [newcep, setCep] = useState(cep);
    const [nameSearch, setNameSearch] = useState("")

    async function handleInputSearch(){
        for (let i = 0; i<exams.length;i++){
            if(exams[i].name === nameSearch){
                dispatcher(setExamId(exams[i].id));
                dispatcher(setExamName(exams[i].name))
                navigator("/chooseExam/filter")
                return;
            }
        }
        alert("Nome do exame não encontrado")
    }

    async function handleSubmit(e:any){
        e.preventDefault()
        if(newcep === 0){
            let storeState:any = store.getState()
            if(storeState.user.locationAuth !== true){
                alert("Nenhum CEP foi salvo ainda, insira um CEP para continuar!")
                return;
            }
        }else{
            if(!checkCep(newcep.toString())){
                alert("Cep inválido!")
                return;
            }
            let cepCleaned = cleanCep(newcep)
            let response = await axios.get("/api/get-geolocation", { params: { cep: cepCleaned } })
            if (response.status === 200 || response.status === 201) {
                setLatitude(response.data.lat);
                setLongitude(response.data.lng);
                setCepState(cepCleaned);
                setLocationAuthorization(true);
            } else{
                window.alert("Não foi possível processar o cep")
                return
            }
        }
        handleInputSearch()
    }

    if (isInvalidGerente || isValidGerente) return (
        <div className="flex flex-col items-center">
            <h1>Seja Bem Vindo Gestor</h1>
        </div>
    )

    return (
        <div className="w-full mb-20" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center"}}>
            <div className={window.innerWidth > 1000 ? "flex flex-row justify-around items-center pt-12 pb-12" : "flex flex-row justify-center items-center pt-10 pb-10 px-5"} style={{backgroundColor: "#37e0b7"}}>
                <section className="flex flex-col justify-center items-start gap-6">
                    <h1 style={{fontSize: "24px", fontWeight: "700", lineHeight: "24px", width: "100%"}}>Bem-vindo(a)</h1>
                    <p style={{fontSize: "16px", fontWeight: "400", lineHeight: "19.2px"}}>Agendamento dos seus exames online. Muito mais rápido e fácil</p>
                    <section className={window.innerWidth > 1000 ? "flex flex-row content-center justify-center items-center gap-6" : "flex flex-col content-center justify-center items-center gap-6"}>
                        <input placeholder='Pesquisa' onChange={e => setNameSearch(e.target.value)} type="text" className='line-input' style={{maxWidth: "335px", width: "100%"}} list="exams"/>
                        <datalist id="exams">
                            {exams.map((exam:Exam) => {
                                return <option data-value={exam.id}>{exam.name}</option>
                            })}
                        </datalist>
                        <input onChange={setFromEvent(setCep)} className='line-input' type="text" defaultValue={cep} placeholder='Digite o CEP' style={{maxWidth: "335px", width: "100%"}} />
                        <button onClick={handleSubmit} className="bg-white" style={{borderRadius: "5px", height: "46px", width: "250px", fontSize: "16px"}}>Procurar</button>
                    </section>
                </section>
                {window.innerWidth > 1000 ? 
                    <img src={BannerExams}/>
                : <></>}
            </div>
            <div className="flex flex-col px-24 justify-center items-start" style={{marginTop: "34px"}}>
                <h1 style={{fontSize: "16px", fontWeight: "400", lineHeight: "24px", marginBottom: "24px", width: "100%"}}>Exames mais buscados</h1>
                <div className="flex flex-col justify-center items-center" style={{alignSelf: "center"}}>
                    {
                    requestStateBest === RequestState.Waiting || bestExams.length === 0 ?
                        <Loader /> :
                        <div className="mb-10 items-center justify-center gap-5" style={window.innerWidth <= 1000 ? {display: "flex", flexDirection: "column"} 
                        : window.innerWidth <= 1200 ? {display: "grid", gridTemplateColumns: "auto auto auto"} 
                        : {display: "grid", gridTemplateColumns: "auto auto auto auto"}}>
                            {
                                bestExams.map((exam: BestExam, index: number) => {
                                    const icon = Object.keys(examsIcons).includes(exam.name) ?
                                        examsIcons[exam.name] :
                                        examsIcons["*"]

                                    return (
                                        <CardExam 
                                            icon={icon}
                                            title={exam.name}
                                            subtitle={""}
                                            redirect={"/"}
                                            action={createHandleChoose(exam.id, exam.name)}
                                            key={index}
                                        />
                                    )
                                })
                            }
                        </div>
                    }
                </div>
                
            </div>
        </div>
    )
}
