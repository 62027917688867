const degToRad = (deg: number) => deg * (Math.PI / 180);

const hav = (theta: number) => Math.sin(theta / 2) * Math.sin(theta / 2) ;

export default function distanceBetweenPoints(point1: [lat: number, long: number], point2: [lat: number, long: number]): number {
    const R = 6371;
    const lat1 = degToRad(point1[0]), lat2 = degToRad(point2[0])
    const long1 = degToRad(point1[1]), long2 = degToRad(point2[1])
    const dLat = lat2 - lat1;
    const dLong = long2 - long1;

    const a = hav(dLat) + hav(dLong) * Math.cos(lat1) * Math.cos(lat2);
    const d = 2 * R * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return d;
}