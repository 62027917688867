import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { RequestState } from "../types";
import useLogin from "../hooks/useLogin";
import useAuthorization from "../hooks/useAuthorization";
import { Loader } from "../components";
import { Background, Page, Header, Title, CenterDiv, Line, OtherWithLine, LeftDiv } from "../components/NewDesignCommons";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import Logo from '../components/Logo';

export default function LoginScreenGestor(): React.ReactElement {
    const login = useLogin();
    login.type_login = "email";
    const requestState = login.requestState;
    const navigator = useNavigate();
    const location = useLocation();
    const [passVisible, setPassVisible] = useState<boolean>(false);
    const togglePassVisible = () => setPassVisible(!passVisible);

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel efetuar seu login no momento.");
                break;
            case RequestState.UnableToLogIn:
                window.alert("Seu nome de usuário ou senha estão incorretos. Verifique e tente novamente.")
                break;
            case RequestState.GestorInvalid:
                window.alert("Seu cadastro como gestor ainda não foi validado. Entre em contato com a Sãnus para mais informações.")
                break;
            default: break;
        }
    }, [requestState])


    // checks user logged in
    const authorized = useAuthorization();
    const state: any = location.state || { redirect: undefined };
    const redirect = state.redirect;
    useEffect(() => {
        if (!authorized) return
        if (!redirect) navigator("/", { replace: true });
        else navigator(redirect);
    }, [authorized])

    return (
        <Background>
            <Logo/>
            <Page>
                <Header>
                    <IoIosArrowBack onClick={() => navigator("/")} style={{ fontSize: '24px', color: '#212121', cursor: 'pointer' }} />
                    <Title>Entrar</Title>
                </Header>
                <form className="flex flex-col " onSubmit={login.connect}>
                    {
                        <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                        :{ maxWidth: "280px", width: "100%", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                        } className={requestState === RequestState.UserNotFound ? "line-input line-input-activate" : "line-input"} type="email" placeholder="Email" required onChange={login.setEmail} />
                    }
                    {
                        requestState === RequestState.UserNotFound ?
                            <p className="text-sm text-red-600">Usuário não existe.</p> : <></>
                    }
                    <span className="flex w-full justify-between flex-row items-center">
                        <input style={window.innerWidth > 500 ? { width: "330px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                        :{ maxWidth: "250px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                        } className={requestState === RequestState.PasswordIncorrect ? "line-input line-input-activate" : "line-input"} type={passVisible ? "text" : "password"} placeholder="Senha" required onChange={login.setPassword} />
                        {
                            passVisible ?
                                <FiEyeOff size={20} className="ml-4" onClick={togglePassVisible} /> :
                                <FiEye size={20} className="ml-4" onClick={togglePassVisible} />
                        }
                    </span>
                    {
                        requestState === RequestState.PasswordIncorrect ?
                            <p className="text-sm text-red-600">Senha incorreta.</p> : <></>
                    }
                    <LeftDiv style={window.innerWidth > 500 ? {width: "360px", marginLeft: "0px"} 
                            : {maxWidth: "280px", width:"100%", marginLeft: "0px"}} >
                        <Link to="/password" className="button-animation">Esqueceu sua senha?</Link>
                    </LeftDiv>
                    {
                        requestState === RequestState.Waiting ?
                            <Loader /> :
                            <input style={window.innerWidth > 500 ? {width: "360px", marginLeft: "0px"} 
                            : {maxWidth: "280px", width:"100%", marginLeft: "0px"}} 
                            className="button-primary" type="submit" value="Entrar" />
                    }
                </form>
                <OtherWithLine>
                    <Line />
                    <span>Ou</span>
                    <Line />

                </OtherWithLine>
                <CenterDiv>
                    <p>Ainda não é cadastrado como parceiro?&nbsp;&nbsp;</p><Link to="/signupgestor" className="button-animation" style={{ color: "#37E0B7" }}>Cadastre-se</Link>
                </CenterDiv>
            </Page>
        </Background>
    )
}