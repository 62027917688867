import { useEffect, useState } from "react";
import { BookingSlotLabExamUser, BookingSlotStatus, RequestState, RequestType } from "../../types";
import useRequest from '../../hooks/useRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { useFuncionario, useGerente } from '../../hooks/useAuthorization';
import { Loader } from "src/components";
import { emptyListBookingSlotLabExamUser } from "src/constants/emptyTypes";
import CardBookSlot from "src/components/CardBookSlot";
import { Radio } from "antd";

export default function ListExamConfirm(): React.ReactElement {
    const params = useParams()

    const navigate = useNavigate();
    const isGerente = useGerente();
    const isFuncionario = useFuncionario();

    const [optionFilter, setOptionFilter] = useState(BookingSlotStatus.inProgress)
    const [listBookSlot, setListBookSlot] = useState<BookingSlotLabExamUser[]>(emptyListBookingSlotLabExamUser)

    useEffect(() => {
        if (!isGerente)
            if (!isFuncionario)
                navigate("../accessDenied");
    }, []);

    const getListGestor = useRequest(`api/booking-slots/get_book_slot_by_gestor_to_confirm/`, setListBookSlot, RequestType.Get);
    const getListFuncionario = useRequest(`api/booking-slots/get_book_slot_by_funcionario_to_confirm/`, setListBookSlot, RequestType.Get);

    useEffect(() => {
        if (params.labId !== undefined) {
            let labId = params.labId
            getListGestor.call({
                lab_id: labId
            })
        } else {
            const labIdLocalStorage = localStorage.getItem("labId");
            if (!labIdLocalStorage)
                getListFuncionario.call({})
            else{
                getListGestor.call({
                    lab_id: labIdLocalStorage
                })
            }
        }
    }, [])

    useEffect(() => {
        switch (getListGestor.requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possível carregar as reservas. Tente novamente mais tarde");
                navigate("/")
                break;
            default: break;
        }
    }, [getListGestor.requestState])

    useEffect(() => {
        switch (getListFuncionario.requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possível carregar as reservas. Tente novamente mais tarde");
                navigate("/")
                break;
            default: break;
        }
    }, [getListFuncionario.requestState])

    return (
        <div className="page">
            <h1>Lista de reservas:</h1>
            {
                listBookSlot.length === 0 ?
                    <p className='mx-4 my-2'>Não há nenhuma reserva a ser confirmada no momento.</p> :
                    <div>
                        <Radio.Group onChange={e => setOptionFilter(e.target.value)} defaultValue={BookingSlotStatus.inProgress} style={{ textAlign: "center", marginTop: '10px' }}>
                            <Radio.Button value={BookingSlotStatus.inProgress} style={{ marginLeft: '5px', marginRight: '5px' }}>Em andamento</Radio.Button>
                            <Radio.Button value={BookingSlotStatus.booked} style={{ marginLeft: '5px', marginRight: '5px' }}>Confirmado</Radio.Button>
                        </Radio.Group>

                        {
                            getListGestor.requestState === RequestState.Waiting || getListFuncionario.requestState === RequestState.Waiting ?
                                <Loader /> :
                                <div className="mt-5">
                                    {
                                        optionFilter === BookingSlotStatus.inProgress ?
                                            <div>
                                                {
                                                    listBookSlot.filter((bookSlot: BookingSlotLabExamUser) => bookSlot.status === BookingSlotStatus.inProgress).length !== 0 ?
                                                        listBookSlot.filter((bookSlot: BookingSlotLabExamUser) => bookSlot.status === BookingSlotStatus.inProgress).map((bookSlotUser: BookingSlotLabExamUser) => (
                                                            <CardBookSlot
                                                                booking_slot={bookSlotUser}
                                                                redirect={`/confirmExam/bookSlotId`}
                                                                action={() => localStorage.setItem("bookSlotId", (bookSlotUser.id).toString())}
                                                            />
                                                        )) :
                                                        <p className='mx-4 my-2 text-center'>Não há nenhuma reserva a ser confirmada</p>
                                                }
                                            </div> :
                                            <div>
                                                {
                                                    listBookSlot.filter((bookSlot: BookingSlotLabExamUser) => bookSlot.status === BookingSlotStatus.booked).length !== 0 ?
                                                        listBookSlot.filter((bookSlot: BookingSlotLabExamUser) => bookSlot.status === BookingSlotStatus.booked).map((bookSlotUser: BookingSlotLabExamUser) => (
                                                            <CardBookSlot
                                                                booking_slot={bookSlotUser}
                                                                redirect={`/confirmExam/bookSlotId`}
                                                                action={() => localStorage.setItem("bookSlotId", (bookSlotUser.id).toString())}
                                                            />
                                                        )) :
                                                        <p className='mx-4 my-2 text-center'>Não há nenhuma reserva confirmada</p>
                                                }
                                            </div>
                                    }
                                </div>
                        }
                    </div>
            }
        </div>
    )
}