import { display } from '@mui/system';
import { useState } from 'react'
import { MdKeyboardArrowRight } from "react-icons/md"
import { Navigate } from 'react-router-dom';

type CardProps = {
    icon?: React.ReactElement,
    title: string,
    subtitle: string,
    additionalText?: string | null,
    subtitleHighlight?: string,
    redirect?: string,
    action?: () => void,
    big?: boolean,
}

export default function CardExamNew({ title, subtitle, additionalText = null, icon, action = () => { }, big = false, redirect = undefined, subtitleHighlight = "" }: CardProps): React.ReactElement {
    const [active, setActive] = useState<Boolean>(false);
    const handleClick = (): void => {
        action();
        setActive(true);
    }

    if (redirect && active) return <Navigate to={redirect} />
    return (
        <div onClick={handleClick}
            className="button-animation"
            style={window.innerWidth > 1000 ? {
                width: "400px",
                margin: "16px 33px",
                height: "200px",
                background: "#FFFFFF",
                cursor: "pointer",
                border: "1px solid #F5F5F5",
                borderRadius: "14px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingLeft: "20px",
                alignItems: "center",
                gap: "10px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }
            :{
                width: "250px",
                margin: "16px 33px",
                height: "180px",
                background: "#FFFFFF",
                cursor: "pointer",
                border: "1px solid #F5F5F5",
                borderRadius: "14px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                paddingLeft: "20px",
                alignItems: "center",
                gap: "10px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
        >
            {window.innerWidth > 1000 ?
                <div style={{ background: "#B6EDE0", width: "70px", height: "70px", borderRadius: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {icon}
                </div> : <></>
            }
            <div>
                <p className=" font-semibold">{title}</p>
                <p className='text-gray-dark text-sm'>{subtitleHighlight}</p>
                <p className=" text-gray-dark text-sm">{subtitle}</p>
                {
                    additionalText === null ? <></> :
                        <p className=" text-gray-dark text-sm">{additionalText}</p>
                }
            </div>
        </div>
    )
}