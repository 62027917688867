type HamburgerProps = {
    active: boolean
    toggleActive: () => void
}
export default function Hamburger ({active, toggleActive}: HamburgerProps): React.ReactElement {

    return (
        <button onClick={toggleActive} className='w-7 m-2 flex flex-col justify-between select-none'>
            <span className={active ? "hamburger-bar rotate-45 translate-y-3" : "hamburger-bar"}></span>
            <span className={active ? "hamburger-bar scale-x-0 -translate-x-3" : "hamburger-bar"}></span>
            <span className={active ? "hamburger-bar -rotate-45 -translate-y-2" : "hamburger-bar"}></span>
        </button>
    )
}