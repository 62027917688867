import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { BookingSlotLabExam, RequestState, RequestType } from '../../types';
import { Loader } from '../../components';
import useAuthorization from "../../hooks/useAuthorization"
import CardExam from 'src/components/CardExams';

export default function MyExams(): React.ReactElement {
    const navigate = useNavigate();
    const [bookings_slots, SetBookingSlots] = useState<BookingSlotLabExam[]>()
    const request = useRequest('api/booking-slots/get_book_slot_by_user/', SetBookingSlots, RequestType.Get);
    const requestState = request.requestState;
    useEffect(() => request.call({}), [])

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            case RequestState.NoBookingsFound:
                window.alert("Você não tem exames disponíveis.");
                break;
            default: break;
        }
    }, [requestState])

    if (!useAuthorization()) return <Navigate to="/login" replace />

    return (
        <div>
            <h1 style={{ marginTop: '15px', textAlign: 'center', marginBottom: '20px' }}>Meus Exames</h1>
            {
                requestState === RequestState.Waiting ?
                    <Loader />
                    : bookings_slots?.length === 0 ?
                        <p className='mx-4 my-2'>Não há nenhum exame para ser mostrado.</p>
                        :
                        <div className="px-3">
                            {
                                bookings_slots?.map((book: BookingSlotLabExam) => (
                                    <CardExam
                                        booking_slot={book}
                                        redirect={"/myExams/id"}
                                        action={() => localStorage.setItem("id_exam", (book.id).toString())}
                                    />
                                ))
                            }
                        </div>
            }
        </div>
    )
}