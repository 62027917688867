import React from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { Funcionario, RequestState, RequestType } from '../../types';
import { Loader } from '../../components';
import useAuthorization from "../../hooks/useAuthorization"
import DeleteButtonFuncionario from 'src/components/EditData/DeleteButtonFuncionario';

export default function FuncionarioPage(): React.ReactElement {
    const navigate = useNavigate()
    const { funcionarioId } = useParams()
    const [funcionario, SetFuncionario] = useState<Funcionario>()

    const request = useRequest('api/funcionario/get_funcionario_by_id/', SetFuncionario, RequestType.Get);
    const requestState = request.requestState;

    useEffect(() => {
        if(funcionarioId !== undefined){
            request.call({
                funcionario_id: funcionarioId
            })
        } else{
            const funcionarioIdLocalStorage = localStorage.getItem("funcionarioId");
            if (!funcionarioIdLocalStorage)
                navigate("/");
            else{
                request.call({
                    funcionario_id: funcionarioIdLocalStorage
                })
            }
        }
        
    }, [])

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            default: break;
        }
    }, [requestState])

    if (!useAuthorization()) return <Navigate to="/login" replace />

    return (
        <div>
            {
                requestState === RequestState.Waiting || funcionario === undefined ?
                    <Loader />
                    : <div style={{ margin: "auto", marginBottom: "30px" }}>
                        <h1 style={{ marginTop: '15px', textAlign: 'center', marginBottom: '10px' }}>Funcionario {funcionario.user.name}</h1>
                        <h1 className="font-bold text-center pt-3">Detalhes do funcionário:</h1>
                        <div className="flex flex-row align-middle">
                            <section>
                                <section className="mt-3 flex flex-row align-text-top">
                                    <p className="ml-0 font-semibold">{"Nome"}:</p>
                                    <p className="ml-4">{funcionario.user.name}</p>
                                </section>
                                <section className="mt-3 flex flex-row align-text-top">
                                    <p className="ml-0 font-semibold">{"Email"}:</p>
                                    <p className="ml-4">{funcionario.user.email}</p>
                                </section>
                                <section className="mt-3 flex flex-row align-text-top">
                                    <p className="ml-0 font-semibold">{"Usuário"}:</p>
                                    <p className="ml-4">{funcionario.user.username}</p>
                                </section>
                            </section>
                        </div>
                        <section className="mb-6" style={{ margin: 'auto', marginTop: "40px", backgroundColor: 'main', borderRadius: '20px' }}>
                            <h2 className="font-bold text-white" style={{ textAlign: "center", paddingTop: "40px" }}>Detalhes do Laboratório:</h2>
                            <p className="text-justify leading-7 p-8 indent-1 text-white" style={{ textIndent: "2em" }}>
                                Trabalha no(a) {funcionario.lab.razao_social}
                            </p>
                            <p className="text-justify leading-7 p-8 indent-1 text-white" style={{ textIndent: "2em" }}>
                                Endereço: {funcionario.lab.address}
                            </p>
                            <p className="text-justify leading-7 p-8 indent-1 text-white" style={{ textIndent: "2em" }}>
                                Descrição: {funcionario.lab.description}
                            </p>
                        </section>
                        <DeleteButtonFuncionario
                            entity={"funcionario"}
                            entityId={funcionario.id.toString()}
                            user_id={funcionario.user.id}
                        />
                    </div>
            }
        </div>
    )
}
