import React, { useState, useEffect } from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useRequest from '../hooks/useRequest';
import useAuthorization from '../hooks/useAuthorization';
import { RequestState, RequestType } from '../types';
import textFromInputEvent from './../helpers/textFromInputEvent';
import { logIn } from '../redux/features/auth';
import { getAge, validateCPF, isInvalidEmail, isInvalidName, validatePassword, validatePhoneNumber } from 'src/helpers/validator';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Background, Page, Header, Title, CenterDiv, Line, OtherWithLine } from "../components/NewDesignCommons";
import { IoIosArrowBack } from 'react-icons/io';
import styled from 'styled-components';
import Logo from '../components/Logo';

type userData = {
    name: string,
    email: string,
    password1: string
    password2: string,
    birthday: string,
    cpf: string,
    phone_number: string,
}

export default function SignUp() {
    const [signUpData, setSignUpData] = useState<userData>({
        name: "",
        email: "",
        password1: "",
        password2: "",
        birthday: "",
        cpf: "",
        phone_number: "",
    })
    const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);

    const dispatcher = useDispatch();
    const navigator = useNavigate();
    const signUpRequest = useRequest('auth/registration/', setSignUpData, RequestType.Post);
    const requestState = signUpRequest.requestState;

    const [passVisible1, setPassVisible1] = useState<boolean>(false);
    const togglePassVisible1 = () => setPassVisible1(!passVisible1);
    const [passVisible2, setPassVisible2] = useState<boolean>(false);
    const togglePassVisible2 = () => setPassVisible2(!passVisible2);
    const [typeInput, setTypeInput] = useState("text")

    const onFocusChangeTypeDate = (e: React.FocusEvent<HTMLInputElement>) => {
        setTypeInput("date")
    }

    const signUpDataSetter = (data: any) => {
        const { name, email, password1, password2, birthday, cpf, phone_number } = data
        const newData = {
            name: name || signUpData.name,
            email: email || signUpData.email,
            password1: password1 || signUpData.password1,
            password2: password2 || signUpData.password2,
            birthday: birthday || signUpData.birthday,
            cpf: cpf || signUpData.cpf,
            phone_number: phone_number || signUpData.phone_number
        }
        setSignUpData(newData);
    }

    const handleInput = (key: string | number) => (event: any) => signUpDataSetter({ [key]: (textFromInputEvent(event)) })
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (getAge(signUpData.birthday) < 18) {
            window.alert("Você deve ser maior de 18 anos para utilizar o serviço."); return
        }
        if (isInvalidName(signUpData.name)) {
            window.alert("Insira um nome válido!"); return
        }
        if (isInvalidEmail(signUpData.email)) {
            window.alert("Insira um e-mail válido!"); return
        }
        if (signUpData.password1 !== signUpData.password2) {
            window.alert("Suas duas senhas devem ser iguais!"); return
        }
        if (validatePassword(signUpData.password1)) {
            window.alert("Sua senha deve ter no mínimo 8 caracteres e não pode ser completamente numérica"); return
        }
        if (validateCPF(signUpData.cpf)) {
            window.alert("CPF deve ser numérico e conter 11 dígitos"); return
        }
        if (validatePhoneNumber(signUpData.phone_number)) {
            window.alert("Número deve ser numérico e conter 11 dígitos (DDD + 9 + Número)"); return
        }
        if (!termsAndConditions) {
            window.alert("Por favor aceite os termos e condições."); return
        }
        signUpRequest.call({
            ...signUpData,
            photo: `https://avatars.dicebear.com/api/initials/${signUpData.name}.svg`,
            created_at: ""
        });
    }

    useEffect(() => {
        switch (requestState) {
            case RequestState.Successful:
                dispatcher(logIn());
                window.alert("Cadastro realizado com sucesso! Agora você pode entrar na sua conta.");
                window.location.reload();
                break;
            case RequestState.CPFAlreadyInUse:
                window.alert("Este CPF já está cadastrado. Por favor, tente outro.");
                break;
            case RequestState.CPFInvalid:
                window.alert("Este CPF é inválido. Por favor, insira outro.");
                break;
            case RequestState.EmailAlreadyInUse:
                window.alert("Este e-mail já está cadastrado. Por favor, tente outro.");
                break;
            case RequestState.WeakPassword:
                window.alert("Esta senha é muito fraca ou comum. As senhas devem ter no mínimo 8 caracteres, que não sejam todos numéricos.");
                break;
            case RequestState.NotSuccessful:
                window.alert("Parece que temos um problema momentaneo. Tente novamente mais tarde!");
                break;
            default:
                console.log(requestState);
                console.log("default");
                break;
        }
    }, [requestState])

    if (useAuthorization()) {
        // let redirectTime = localStorage.getItem("redirectTime")
        // if(redirectTime === "true") {
        //     localStorage.removeItem("redirectTime")
        //     return <Navigate to="/chooseExam/time" replace />
        // }
        return <Navigate to="/" replace />
    }
    if (requestState === RequestState.Successful) return <Navigate to="/login" replace />
    return (
        <Background style={window.innerWidth > 1000 ? {height: "130vh"} : {height: "150vh"}}>
            <Logo />
            <Page>
                <Header>
                    <IoIosArrowBack onClick={() => navigator("/")} style={{ fontSize: '24px', color: '#212121', cursor: 'pointer' }} />
                    <Title>Cadastre-se agora!</Title>
                </Header>
                <form className="flex flex-col" onSubmit={handleSubmit}>
                    <input pattern="^\D*$" style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width: "230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                    onChange={handleInput("name")} placeholder="*Nome Completo" required />
                    <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width: "230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                    onChange={handleInput("cpf")} type="text" placeholder="*CPF (somente números)" required />
                    {
                        (requestState === RequestState.CPFAlreadyInUse || requestState === RequestState.CPFInvalid) ?
                            <p className='input-warning'>Este CPF já está cadastrado ou é inválido'.</p> : <></>
                    }
                    <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width: "230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                    onChange={handleInput("phone_number")} type="text" placeholder="*Celular com DDD" required />{

                    }
                    <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width: "230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                    onChange={handleInput("email")} type="text" placeholder="Email" />
                    {
                        requestState === RequestState.EmailAlreadyInUse ?
                            <p className='input-warning'>Este e-mail já está cadastrado.</p> : <></>
                    }
                    <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width: "230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                    onChange={handleInput("birthday")} type={typeInput} onFocus={onFocusChangeTypeDate} placeholder="Data de Nascimento (você deve ter pelo menos 18 anos)" />
                    {
                        getAge(signUpData.birthday) < 18 ?
                            <p className='input-warning'>Você deve ser maior de 18 anos para utilizar o serviço.</p> : <></>
                    }

                    <span className="flex w-full justify-between flex-row items-center">
                        <input style={window.innerWidth > 500 ? { width: "330px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                        :{width: "210px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                        className={requestState === RequestState.PasswordIncorrect ? "line-input line-input-activate" : "line-input"} type={passVisible1 ? "text" : "password"} placeholder="*Senha (Mínimo de 8 caracteres, não todos numéricos)" required onChange={handleInput("password1")} />
                        {
                            passVisible1 ?
                                <FiEyeOff size={20} style={{marginLeft: "-5px"}} onClick={togglePassVisible1} /> :
                                <FiEye size={20} style={{marginLeft: "-5px"}} onClick={togglePassVisible1} />
                        }
                    </span>
                    {
                        requestState === RequestState.WeakPassword ?
                            <p className='input-warning'>Esta senha é muito fraca ou comum. As senhas devem ter no mínimo 8 caracteres, que não sejam todos numéricos.</p> : <></>
                    }

                    <span className="flex w-full justify-between flex-row items-center">
                        <input style={window.innerWidth > 500 ? { width: "330px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                        :{width: "210px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                        onChange={handleInput("password2")} type={passVisible2 ? "text" : "password"} placeholder="*Repetir senha" required />
                        {
                            passVisible2 ?
                                <FiEyeOff size={20} style={{marginLeft: "-5px"}} onClick={togglePassVisible2} /> :
                                <FiEye size={20} style={{marginLeft: "-5px"}} onClick={togglePassVisible2} />
                        }
                    </span>
                    {/* {
                        signUpData.password1 !== signUpData.password2 ?
                            <p className='input-warning'>As duas senhas devem ser iguais!</p> : <></>
                    } */}

                    <TermsAndConditions style={{marginTop: "10px", marginBottom: "10px"}}>
                        <input style={{ width: "18px", boxSizing: "border-box", border: "1px solid #808080", borderRadius: "4px" }} type="checkbox" onChange={() => setTermsAndConditions(!termsAndConditions)} />
                        <p className='mx-4'>Eu aceito os <Link className='underline' to='/termsAndConditions' target="_blank" rel="noopener noreferrer">Termos e Condições</Link>.</p>
                    </TermsAndConditions>
                    {/* {
                        !termsAndConditions ?
                            <p className='input-warning'>Por favor aceite os termos e condições.</p> : <></>
                    } */}
                    {
                        requestState === RequestState.Waiting ?
                            <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> :
                            <input className='button-primary' type="submit" style={window.innerWidth > 500 ? {width: "360px", marginLeft: "0px"} 
                            : {maxWidth: "230px", width:"100%", marginLeft: "0px"}} 
                            value="Cadastrar" />
                    }
                </form>

                <OtherWithLine>
                    <Line />
                    <span>Ou</span>
                    <Line />

                </OtherWithLine>
                <CenterDiv>
                    <p>Já possui uma conta?&nbsp;&nbsp;</p><Link to="/login" className="button-animation" style={{ color: "#37E0B7" }}>Entrar</Link>
                </CenterDiv>
            </Page>
        </Background>
    )
}

const TermsAndConditions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
margin-top:  30px
margin-bottom: 24px;
/* identical to box height */

text-align: center;

/* text / primary */

color: #212121;
`
