import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { BookingSlotLabExam, RequestState, RequestType } from '../../types';
import { Loader } from '../../components';
import useAuthorization from "../../hooks/useAuthorization"
import { AxiosError, AxiosResponse } from 'axios';
import axios from '../../commons/axios'


export default function MyExam(): React.ReactElement {
    const navigate = useNavigate();
    let { id_exam } = useParams();
    if(id_exam === undefined) {
        const idExamLocalStorage = localStorage.getItem("id_exam");
        if (!idExamLocalStorage)
            navigate("/");
        else{
            id_exam = idExamLocalStorage
        }
    }
    const [examToken, setExamToken] = useState("waiting")
    const [booking_slot, SetBookingSlot] = useState<BookingSlotLabExam>()
    const request = useRequest('api/booking-slots/' + id_exam + "/", SetBookingSlot, RequestType.Get);
    const requestState = request.requestState;

    function getTimestamp(date: string): string {
        const pad = (n: any, s = 2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
        const d = new Date(date);
        return `${pad(d.getDate())}/${pad(d.getMonth() + 1)}/${pad(d.getFullYear(), 4)} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
    }

    function getExamToken() {
        let url = `/api/booking-slots/get_book_slot_by_token/`
        let token = localStorage.getItem("token")
        let birthday = localStorage.getItem("birthday")

        if (token !== null && birthday !== null) {
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            axios.get(url, {
                params: {
                    token: token,
                    birthday: birthday,
                }
            })
                .then((response: AxiosResponse) => response.data)
                .then((data: any) => setExamToken("true"))
                .catch((error: AxiosError) => {
                    console.log(error)
                    setExamToken("false")
                })
        }
    }

    useEffect(() => request.call({}), [id_exam]);

    useEffect(() => getExamToken(), []);

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido. Tente novamente mais tarde.");
                break;
            default: break;
        }
    }, [requestState])

    console.log(booking_slot);

    if (!useAuthorization()) {
        switch (examToken) {
            case "waiting":
                return <Loader />
            case "false":
                return <Navigate to="/login" replace />
            default:
                break;
        }
    }
    if (typeof booking_slot === 'undefined') {
        return (<Loader />)
    }
    else return (
        <div style={{ margin: "auto", marginBottom: "30px" }}>
            <h1 style={{ marginTop: '15px', textAlign: 'center', marginBottom: '10px' }}>Meu Exame</h1>
            <h1 className="font-bold text-center pt-3">Detalhes da consulta:</h1>
            <div className="flex flex-row align-middle">
                <section>
                    <section className="mt-3 flex flex-row align-text-top">
                        <p className="ml-0 font-semibold">{"Nome do Exame"}:</p>
                        <p className="ml-4">{booking_slot?.lab_exam?.exam?.name}</p>
                    </section>
                    <section className="mt-3 flex flex-row align-text-top">
                        <p className="ml-0 font-semibold">{"Nome do Laboratório"}:</p>
                        <p className="ml-4">{booking_slot?.lab_exam?.exam?.name}</p>
                    </section>
                    <section className="mt-3 flex flex-row align-text-top">
                        <p className="ml-0 font-semibold">{"Endereço do Laboratório"}:</p>
                        <p className="ml-4">{booking_slot?.lab_exam?.lab?.address}</p>
                    </section>
                    <section className="mt-3 flex flex-row align-text-top">
                        <p className="ml-0 font-semibold">{"Telefone do Laboratório"}:</p>
                        <p className="ml-4">{booking_slot?.lab_exam?.lab?.telefone}</p>
                    </section>
                    <section className="mt-3 flex flex-row align-text-top">
                        <p className="ml-0 font-semibold">{"Horário do Exame"}:</p>
                        <p className="ml-4">{getTimestamp(booking_slot?.date_time_from)}</p>
                    </section>
                    <section className="mt-3 flex flex-row align-text-top">
                        <p className="ml-0 font-semibold">{"Duração do Exame"}:</p>
                        <p className="ml-4">{booking_slot?.duration + " minutos"}</p>
                    </section>
                    <section className="mt-3 flex flex-row align-text-top">
                        <p className="ml-0 font-semibold">{"Token"}:</p>
                        <p className="ml-4">{booking_slot?.token}</p>
                    </section>
                </section>
            </div>
            <section className="mb-6" style={{ margin: 'auto', marginTop: "40px", backgroundColor: 'main', borderRadius: '20px' }}>
                <h2 className="font-bold text-white" style={{ textAlign: "center", paddingTop: "40px" }}>Descrição do Exame</h2>
                <p className="text-justify leading-7 p-8 indent-1 text-white" style={{ textIndent: "2em" }}>
                    {booking_slot?.lab_exam.exam.description}
                </p>
            </section>
            <div className="mt-8 flex justify-center align-middle overflow-hidden" style={{ borderRadius: "20px" }}>
                {/* <img src={auxBookOBject.img_url} style={{flexShrink: "0", minHeight:"100%", minWidth:"100%"}}/> */}
            </div>
        </div>
    )
}