import Consulting from '../assets/icons/consulting.svg';
import ImageExam from '../assets/icons/ImageExam.svg';
import Tube from '../assets/icons/tube.svg';
import UltraSound from '../assets/icons/ultrasound.svg';

const iconProps = {
    className: "pr-2 ",
    alt: ""
}
type examsIconsType = {
    [key: string]: React.ReactElement;
}
const examsIcons: examsIconsType = {
    "Tomografia": <img style={{width: "30px", overflow: "hidden", height: "30px"}} {...iconProps} src={ImageExam}/>,
    "Ressonância": <img style={{width: "30px", overflow: "hidden", height: "30px"}} {...iconProps} src={ImageExam}/>,
    "Ultrassom": <img style={{width: "30px", overflow: "hidden", height: "30px"}} {...iconProps} src={UltraSound}/>,
    "Hemograma": <img style={{width: "30px", overflow: "hidden", height: "30px"}} {...iconProps} src={Tube}/>,
    "Exame de Colesterol": <img style={{width: "30px", overflow: "hidden", height: "30px"}} {...iconProps} src={Tube}/>,
    "Exame de Urina": <img style={{width: "30px", overflow: "hidden", height: "30px"}} {...iconProps} src={Tube}/>,
    "Endoscopia": <img style={{width: "30px", overflow: "hidden", height: "30px"}} {...iconProps} src={Consulting}/>,
    "*": <img style={{width: "30px", overflow: "hidden", height: "30px"}} {...iconProps} src={Consulting} />
};
export default examsIcons;