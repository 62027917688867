import { useState, useEffect } from 'react';
import useRequest from './../hooks/useRequest';
import { RequestState, RequestType, UserData, UserTypeList } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData as setUser } from '../redux/features/user';
import { logOut, setPerm } from 'src/redux/features/auth';

export default function useUserData(): [UserData | undefined, RequestState] {
    const dispatcher = useDispatch();
    const [userData, setUserData] = useState<UserData>(
        useSelector((state: any) => state.user.userData)
    );
    const logged = useSelector((state: any) => state.auth.logged);
    
    // request
    const request = useRequest("api/users/me/", setUserData);
    const requestState = request.requestState;

    useEffect(() => {
        if(userData === null || !userData) request.call({});
    }, [])
    useEffect(() => {
        dispatcher(setUser(userData));
        if(userData) {
            dispatcher(setPerm(userData.permissions));
        }
    }, [userData])

    useEffect(() => {
        switch(requestState){
            case RequestState.NotSuccessful:
                break;
            case RequestState.NotConnected:
                if(logged) {
                    dispatcher(logOut());
                    window.location.reload();
                }
                break;
            default: break;
        }
    }, [requestState])

    return [userData, requestState];
}