import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { Lab, RequestState, RequestType } from '../../types';
import { Loader } from '../../components';
import CardClinicGestor from 'src/components/CardClinicGestor';


export default function MyLabs(): React.ReactElement {
    const navigate = useNavigate()
    const [clinicas, SetClinicas] = useState<Lab[]>()
    const request = useRequest('api/labs/get_labs_by_gestor/', SetClinicas, RequestType.Get);
    const requestState = request.requestState;

    useEffect(() => request.call({}), [])

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            default: break;
        }
    }, [requestState])

    return (
        <div>
            <h1 style={{ marginTop: '15px', textAlign: 'center', marginBottom: '20px' }}>Meus Laboratórios</h1>
            {
                requestState === RequestState.Waiting ?
                    <Loader />
                    : <div>
                        {clinicas?.map((clinica: Lab) => (
                            <CardClinicGestor
                                razao_social={clinica.razao_social}
                                email={clinica.email}
                                address={clinica.address}
                                clinica_id={clinica.id}
                            />
                        ))}
                        <Link className="button-primary" to={"/myLabs/addLab"}>Adicionar Laboratório</Link>
                    </div>
            }
        </div>
    )
}