import { width } from "@mui/system"
import { AiOutlineCheckCircle } from "react-icons/ai"
import { MdKeyboardArrowLeft } from "react-icons/md"
import styled from "styled-components"

type CarrouselNavigatorProps = {
    goBack: () => void,
    numberPages: number,
    numberActive: number
}

const BlueLine = styled.div`

height: 2px;

/* Main */

background: #37E0B7;
`

export default function CarrouselNavigator({ goBack, numberActive, numberPages }: CarrouselNavigatorProps): React.ReactElement {

    const activation: string[] = new Array(numberPages)
        .fill("")
        .map((_: string, index: number) =>
            index < numberActive ? "bg-main" :
                index === numberActive ? "bg-main" :
                    "bg-gray"
        )

    return (
        <div className="w-full flex flex-row justify-center items-center self-center" style={{width: "100%", margin: "0 auto", marginTop: "16px", marginBottom: "16px"}}>
            <div className="w-10 py-1 m-2  text-white bg-main rounded-full button-animation" onClick={goBack}>
                <MdKeyboardArrowLeft className="m-auto" />
            </div>
            <div className="flex flex-row">
                {
                    activation.map((color: string, index: number) => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                                width: "100%",

                            }}>
                            {
                                index < numberActive ?
                                    <AiOutlineCheckCircle size={"30px"} color="#FFFFFF" style={{ backgroundColor: "#37E0B7", borderRadius: "15px" }} />
                                    : <div className={`ball ${color}`} key={index}></div>

                            }
                            {index === numberPages - 1 ? <></> : <BlueLine style={window.innerWidth > 1000 ? {width: "70px"} : {width: "25px"}}/>}
                        </div>
                    ))
                }
            </div>
            {/* dummy component to center balls */}
            <div className="w-10"></div>
        </div>
    )
}