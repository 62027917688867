import { useState, useEffect } from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useRequest from '../hooks/useRequest';
import useAuthorization from '../hooks/useAuthorization';
import { RequestState, RequestType } from '../types';
import textFromInputEvent from '../helpers/textFromInputEvent';
import Logo from '../components/Logo';
import { logIn } from '../redux/features/auth';
import axios from '../commons/axios'
import { isInvalidEmail, isInvalidName, validatePassword } from 'src/helpers/validator';
import { AxiosError } from 'axios';
import { Background, Page, Header, Title, CenterDiv, Line, OtherWithLine } from "../components/NewDesignCommons";
import { IoIosArrowBack } from 'react-icons/io';
import styled from 'styled-components';

type userDataGestor = {
    name: string,
    email: string,
    institution: string,
    password1: string,
    password2: string,
}

export default function SignUpGestor() {
    const [signUpData, setSignUpData] = useState<userDataGestor>({
        name: "",
        email: "",
        institution: "",
        password1: "",
        password2: "",
    })
    const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
    const [requestState, setRequestState] = useState<RequestState>(RequestState.NotSuccessful)

    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const signUpDataSetter = (data: any) => {
        const { name, email, institution, password1, password2 } = data
        const newData = {
            name: name || signUpData.name,
            email: email || signUpData.email,
            institution: institution || signUpData.institution,
            password1: password1 || signUpData.password1,
            password2: password2 || signUpData.password2,
        }
        setSignUpData(newData);
    }

    const handleInput = (key: string | number) => (event: any) => signUpDataSetter({ [key]: (textFromInputEvent(event)) })
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isInvalidName(signUpData.name)) {
            window.alert("Insira um nome válido!"); return
        }
        if (isInvalidName(signUpData.institution)) {
            window.alert("Insira um nome válido para a instituição!"); return
        }
        if (isInvalidEmail(signUpData.email)) {
            window.alert("Insira um e-mail válido!"); return
        }
        if (signUpData.password1 !== signUpData.password2) {
            window.alert("Suas duas senhas devem ser iguais!"); return
        }
        if (validatePassword(signUpData.password1)) {
            window.alert("Sua senha deve ter no mínimo 8 caracteres e não pode ser completamente numérica"); return
        }
        if (!termsAndConditions) {
            window.alert("Por favor aceite os termos e condições."); return
        }
        setRequestState(RequestState.Waiting)
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.post("/auth/registration/", {
            ...signUpData,
            birthday: "1900-01-01",
            created_at: ""
        }).then(response => {
            if (response.status === 200 || response.status === 201)
                axios.post("/api/gestor/create_gestor/", {
                    email: signUpData.email,
                    institution: signUpData.institution
                }).then(response => {
                    if (response.status === 200 || response.status === 201) {
                        window.alert("Enviamos para a análise, aguarde até sua aprovação.")
                        navigate("/")
                    }
                })
        }).catch((err: AxiosError) => {
            let responseData = err.response?.data
            if ("email" in responseData && responseData.email.includes("email_already_in_use")) {
                window.alert("Esse email já está cadastrado. Por favor, tente outro.")
                setRequestState(RequestState.EmailAlreadyInUse)
            } else if (
                ("password1" in responseData && responseData.password1.includes("password_too_common")) ||
                ("password1" in responseData && responseData.password1.includes("password_too_short")) ||
                ("password1" in responseData && responseData.password1.includes("password_only_numbers")) ||
                ("new_password2" in responseData && responseData.new_password2.includes("password_too_common")) ||
                ("new_password2" in responseData && responseData.new_password2.includes("password_too_short")) ||
                ("new_password2" in responseData && responseData.new_password2.includes("password_only_numbers"))
            ) {
                window.alert("Esta senha é muito fraca ou comum. As senhas devem ter no mínimo 8 caracteres, que não sejam todos numéricos.")
                setRequestState(RequestState.WeakPassword)
            } else {
                window.alert("Parece que temos um problema momentaneo. Tente novamente mais tarde!")
            }
        })
    }

    if (useAuthorization()) return <Navigate to="/" replace />
    // if (requestStateGestor === RequestState.Successful) return <Navigate to="/login" replace />
    return (
        <Background style={window.innerWidth > 1000 ? {height: "130vh"} : {height: "150vh"}}>
            <Logo />
            <Page>
                <Header>
                    <IoIosArrowBack onClick={() => navigate("/")} style={{ fontSize: '24px', color: '#212121', cursor: 'pointer' }} />
                    <Title>Seja nosso parceiro</Title>
                </Header>
                <form className="flex flex-col items-center px-3" onSubmit={handleSubmit}>
                    <input pattern="^\D*$" style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width:"230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                    onChange={handleInput("name")} type="text" placeholder="*Nome Completo" required />
                    
                    <input pattern="^\D*$" style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width:"230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                    onChange={handleInput("institution")} type="text" placeholder="*Instituição" required />
                    
                    <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width:"230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                    onChange={handleInput("email")} type="text" placeholder="Email" />
                    {
                        requestState === RequestState.EmailAlreadyInUse ?
                            <p className='input-warning'>Este e-mail já está cadastrado.</p> : <></>
                    }

                    <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width:"230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                    onChange={handleInput("password1")} type="password" placeholder="*Senha (Mínimo de 8 caracteres, não todos numéricos)" required />
                    {
                        requestState === RequestState.WeakPassword ?
                            <p className='input-warning'>Esta senha é muito fraca ou comum. As senhas devem ter no mínimo 8 caracteres, que não sejam todos numéricos.</p> : <></>
                    }
                    
                    <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width:"230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}}
                    onChange={handleInput("password2")} type="password" placeholder="*Repetir senha" required />
                    {
                        signUpData.password1 !== signUpData.password2 ?
                            <p className='input-warning'>As duas senhas devem ser iguais!</p> : <></>
                    }
                    <TermsAndConditions style={{marginTop: "10px", marginBottom: "10px"}}>
                        <input style={{ width: "18px", boxSizing: "border-box", border: "1px solid #808080", borderRadius: "4px" }} type="checkbox" onChange={() => setTermsAndConditions(!termsAndConditions)} />
                        <p className='mx-4'>Eu aceito os <Link className='underline' to='/termsAndConditions' target="_blank" rel="noopener noreferrer">Termos e Condições</Link>.</p>
                    </TermsAndConditions>
                    {
                        !termsAndConditions ?
                            <p className='input-warning'>Por favor aceite os termos e condições.</p> : <></>
                    }
                    {
                        requestState === RequestState.Waiting ?
                            <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> :
                            <input className='button-primary' type="submit" style={window.innerWidth > 500 ? {width: "360px", marginLeft: "0px"} 
                            : {maxWidth: "230px", width:"100%", marginLeft: "0px"}} 
                            value="Cadastrar" />
                    }
                </form>
                <OtherWithLine>
                    <Line />
                    <span>Ou</span>
                    <Line />
                </OtherWithLine>
                <CenterDiv>
                    <p>Já é nosso parceiro?&nbsp;&nbsp;</p><Link to="/logingestor" className="button-animation" style={{ color: "#37E0B7" }}>Entre</Link>
                </CenterDiv>
            </Page>
        </Background>
    )
}

const TermsAndConditions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
margin-top:  30px
margin-bottom: 24px;
/* identical to box height */

text-align: center;

/* text / primary */

color: #212121;
`