import useRequest from '../../hooks/useRequest';
import { RequestState, RequestType } from '../../types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type DeleteButtonProps = {
    entity: string,
    entityId: string,
    name: string
}

export default function DeleteButton({ entity, entityId, name }: DeleteButtonProps): React.ReactElement {

    const editPropertyRequest = useRequest(`api/${entity}/${entityId}/`, () => { }, RequestType.Delete);
    const requestState = editPropertyRequest.requestState;
    const navigate = useNavigate()

    useEffect(() => {
        switch (requestState) {
            case RequestState.Successful:
                window.alert(`Deletado com sucesso!`);
                navigate(-1)
                break
            case RequestState.UnableToChangeProperty:
                window.alert("Não foi possivel atualizar esse dado no momento. Tente novamente em alguns momentos!");
                navigate(-1)
                break;
            case RequestState.NotSuccessful:
                window.alert(`Parece que estamos tendo problemas no servidor. Tente novamente em alguns momentos.`);
                navigate(-1)
                break;
        }
    }, [requestState])

    const deleteButton = (event: any): void => {
        console.log("aqui", entity, entityId)
        event.preventDefault();
        editPropertyRequest.call({});
    }

    return (
        <div className='w-full my-2' >
            <button className="button-primary button-animation" style={{width: "100%", marginLeft: "0"}} onClick={deleteButton}>
                Deletar {name}
            </button>
        </div>

    )
}