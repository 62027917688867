import { useState } from 'react'
import { Navigate } from 'react-router-dom';

type CardButtonProps = {
    icon: any,
    title: string,
    additionalText?: string|null,
    redirect?: string,
    action?: () => void,
    align: string
}

export default function CardButtonBanner({title, additionalText = null, icon, action = () => {}, redirect = "", align = "center"}: CardButtonProps): React.ReactElement{
    const [active, setActive] = useState<Boolean>(false);
    const handleClick = (): void => {
        action();
        setActive(true);
    }

    if(redirect && active) return <Navigate to={redirect} />
    return (
        <div className="py-5 px-10 cursor-pointer bg-white flex flex-col items-center content-center justify-center" onClick={handleClick} style={{maxWidth: "178px", width: "100%", textAlign: "center", borderWidth: "1px", borderColor: "#f5f5f5", backgroundColor: "#fff", borderRadius: "14px"}}>
            <img src={icon} />
            <p style={{marginTop: "10px", fontSize: "16px", fontWeight: "450", lineHeight: "19px", maxWidth: "97px", width: "100%"}}>{title}</p>
            {
                additionalText === null?<></>:
                <p className=" text-gray-dark text-sm">{additionalText}</p>
            }
        </div>
    )
}