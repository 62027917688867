import React from 'react';
import { Day, Time } from '../types';

type TimeTableProps = {
    day: Day
    settingBookingIdAction: (bookingId: number, date: String, hour: String) => () => void
}

export default function TimeTable({ day, settingBookingIdAction }: TimeTableProps): React.ReactElement {
    const handleClickTimeNotAvailable = () => {
        window.alert("Este horário não está disponível.");
    }
    const times: Time = day.times;
    return (
        <div className=" flex justify-start flex-wrap items-center my-6">
            {
                Object.keys(times).map((hour: string, index: number) => (
                    <div key={index}
                        style={times[hour].available ? {
                            width: "123px", border: "1px solid #37E0B7",
                            cursor: "pointer",
                            borderRadius: "14px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            margin: "0 8px 8px 0",
                            height: "64px", backgroundColor: "#FFFFFF", color: "#37E0B7"
                        } : {
                            width: "123px", border: "1px solid #808080",
                            cursor: "pointer",
                            borderRadius: "14px",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            textDecorationLine: "line-through",
                            flexDirection: "column",
                            margin: "0 8px 8px 0",
                            height: "64px", backgroundColor: "#FFFFFF", color: "#808080"
                        }}
                        onClick={
                            times[hour].available ?
                                settingBookingIdAction(times[hour].id, day.date.getBR(), hour) :
                                handleClickTimeNotAvailable
                        }>
                        <p>{hour}</p>
                        <p>R$ {parseFloat(times[hour].price).toFixed(2)}</p>
                    </div>
                ))
            }
        </div>
    )
}