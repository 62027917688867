import React from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { BookingSlot, BookingSlotStatus, Day, Lab, LabExam, RequestState, RequestType } from '../../types';
import { DateTimeTable, Loader } from '../../components';
import useAuthorization from "../../hooks/useAuthorization"
import MyDate from 'src/helpers/date';
import { emptyLab } from 'src/constants/emptyTypes';

export default function MyLabPage(): React.ReactElement {
    let {labId} = useParams()
    const navigate = useNavigate()

    if(labId === undefined) {
        const labIdLocalStorage = localStorage.getItem("labId");
        if (!labIdLocalStorage)
            navigate("/");
        else{
            labId = labIdLocalStorage
        }
    }

    const [lab, setLab] = useState<Lab>(emptyLab)
    const request = useRequest(`api/labs/${labId}/`, setLab, RequestType.Get)
    const requestStatus = request.requestState

    useEffect(() => request.call({}), [])

    useEffect(() => {
        switch(requestStatus){
            case RequestState.NotSuccessful:
                window.alert("Não foi possível completar a requisição no momento, tente novamente mais tarde.")
                break;
            default:
                break;
        }
    }, [requestStatus])

    if (!useAuthorization()) return <Navigate to="/" replace />

    return (
        <div>
            {
                requestStatus === RequestState.Waiting || lab === undefined ?
                    <Loader />
                    : <div style={{ margin: "auto", marginBottom: "30px" }}>
                        <h1 className="font-bold text-center pt-3">Detalhes do Laboratório:</h1>
                        <h1 style={{ marginTop: '15px', textAlign: 'center', marginBottom: '10px' }}>{lab.razao_social}</h1>
                        <div className="flex flex-row align-middle">
                            <section>
                                <section className="mt-3 flex flex-row align-text-top">
                                    <p className="ml-0 font-semibold">{"Descrição"}:</p>
                                    <p className="ml-4">{lab.description}</p>
                                </section>
                                <section className="mt-3 flex flex-row align-text-top">
                                    <p className="ml-0 font-semibold">{"E-mail"}:</p>
                                    <p className="ml-4">{lab.email}</p>
                                </section>
                                <section className="mt-3 flex flex-row align-text-top">
                                    <p className="ml-0 font-semibold">{"CNPJ"}:</p>
                                    <p className="ml-4">{lab.cnpj}</p>
                                </section>
                                <section className="mt-3 flex flex-row align-text-top">
                                    <p className="ml-0 font-semibold">{"Endereço"}:</p>
                                    <p className="ml-4">{lab.address}</p>
                                </section>
                                <section className="mt-3 flex flex-row align-text-top">
                                    <p className="ml-0 font-semibold">{"CEP"}:</p>
                                    <p className="ml-4">{lab.cep}</p>
                                </section>
                            </section>
                        </div>
                        <Link className="button-primary" onClick={() => {
                            localStorage.setItem("labId", labId?.toString() || "");
                        }} to={"/myLabs/labId/edit"}>Editar Lab</Link>
                    </div>
            }
        </div>
    )
}
