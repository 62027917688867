import { useState } from 'react';
import { Link } from "react-router-dom";
import { Hamburger } from "../components";
import useAuthorization, { useFuncionario, useGerente, useGerenteInvalid } from "../hooks/useAuthorization";
import useUserData from "../hooks/useUserData";

import { BiUser, BiHome } from 'react-icons/bi';
import logo from '../assets/images/logo.svg';

export default function NavigationBar(): React.ReactElement {

    //initial data
    const auth = useAuthorization();
    const isInvalidGerente = useGerenteInvalid();
    const isValidGerente = useGerente();
    const isFuncionario = useFuncionario();
    console.log(isValidGerente)
    const [active, setActive] = useState<boolean>(false);
    const toggleActive = (): void => setActive(!active);
    const [userData, userDataRequestState] = useUserData();
    console.log(userData)
    if(window.innerWidth <= 1000){
        return (
            <div className='w-full transition-all sticky top-0 overflow-visible left-0 z-90 bg-white shadow-md' >
                <div className="flex justify-between items-center p-2  lg:px-10">
                    <Hamburger active={active} toggleActive={toggleActive} />
                    <Link to="/" className='button-animation'>
                        <img src={logo} className="h-10" alt="Sanus logo" />
                    </Link>
                    {
                        !auth ?
                            <Link to="/">
                                <BiHome size={28} className="my-2 mx-2 button-animation" />
                            </Link> :
                            isInvalidGerente || isValidGerente || isFuncionario || !userData ?
                                <Link to="/user">
                                    <BiUser size={28} className="my-2 mx-2 button-animation" />
                                </Link> :
                                <Link to="/user">
                                    <img src={userData.photo} alt="foto do usuário" className="h-10 w-10 rounded-full button-animation" />
                                </Link>
                    }
                </div>
                {
                    active ?
                        <nav onClick={toggleActive} className='z-30 h-screen w-full flex flex-col align-middle py-5 relative bg-gray-light'>
                            {
                                (isValidGerente) ?
                                    <>
                                        <Link to="/myLabs" className="nav-button">Gerenciar Meus Laboratórios, Exames e Funcionários</Link>
                                    </> : (isFuncionario) ?
                                        <>
                                            <Link to="/myLabExamsFuncionario" className="nav-button">Cadastrar Horários de Exame</Link>
                                        </>
    
                                        : auth ?
                                            <>
                                                <Link to={"/"} className="nav-button">Início</Link>
                                                <Link to={"/myExams"} className="nav-button">Meus Exames</Link>
                                                <Link to="/chooseExam" className="nav-button">Marcar um exame</Link>
                                            </> : <></>
                            }
                            {
                                (isValidGerente) ?
                                    <>
                                        <Link to="/chooseLabConfirmExam" className="nav-button">Confirmar um Exame Agendado</Link>
                                    </> : (isFuncionario) ?
                                        <>
                                            <Link to="/confirmExam" className="nav-button">Confirmar um Exame Agendado</Link>
                                        </> :
                                        <></>
                            }
                            {
                                (isValidGerente) ?
                                    <>
                                        <Link to="/chooseLabAppointment" className="nav-button">Marcação de Horários de Exame</Link>
                                    </> : (isFuncionario) ?
                                        <>
                                            <Link to="/makeAppointment" className="nav-button">Marcação de Horários de Exame</Link>
                                        </> :
                                        <></>
                            }
                            {
                                (isValidGerente) ?
                                    <Link to="/statsHomeScreen" className="nav-button">Estatísticas</Link>
                                    : <></>
                            }
    
                            {
                                // List of protected and not-protected links for the navbar
                                auth ?
                                    <>
                                        <Link to="/user" className="nav-button">Página do {isValidGerente ? "Gerente" : isFuncionario ? "Funcionário(a)" : "Usuário"}</Link>
                                    </> :
                                    <>
                                        <Link to="/login" className="nav-button">Entrar</Link>
                                        <Link to="/signup" className="nav-button">Cadastre-se</Link>
                                    </>
                            }
                        </nav> : <></>
                }
    
    
            </div >
        )
    }

    return (
        <div className='w-full transition-all sticky top-0 overflow-visible left-0 z-90 bg-white shadow-md' >
            <div className="flex justify-between items-center p-2  lg:px-10">
                <Link to="/" className='button-animation'>
                    <img src={logo} className="h-10" alt="Sanus logo" />
                </Link>
                {
                    (isValidGerente) ?
                        <>
                            <Link to="/myLabs" className="nav-button">Gerenciar Meus Laboratórios, Exames e Funcionários</Link>
                        </> : (isFuncionario) ?
                            <>
                                <Link to="/myLabExamsFuncionario" className="nav-button">Cadastrar Horários de Exame</Link>
                            </>

                            : auth ?
                                <section className="flex flex-row items-center justify-center gap-10">
                                    <Link to={"/"} className="nav-button">Início</Link>
                                    <Link to={"/myExams"} className="nav-button">Meus Exames</Link>
                                    <Link to="/chooseExam" className="nav-button">Marcar um exame</Link>
                                </section> : <></>
                }
                {
                    (isValidGerente) ?
                        <>
                            <Link to="/chooseLabConfirmExam" className="nav-button">Confirmar um Exame Agendado</Link>
                        </> : (isFuncionario) ?
                            <>
                                <Link to="/confirmExam" className="nav-button">Confirmar um Exame Agendado</Link>
                            </> :
                            <></>
                }
                {
                    (isValidGerente) ?
                        <>
                            <Link to="/chooseLabAppointment" className="nav-button">Marcação de Horários de Exame</Link>
                        </> : (isFuncionario) ?
                            <>
                                <Link to="/makeAppointment" className="nav-button">Marcação de Horários de Exame</Link>
                            </> :
                            <></>
                }
                {
                    (isValidGerente) ?
                        <Link to="/statsHomeScreen" className="nav-button">Estatísticas</Link>
                        : <></>
                }
                {
                    !auth ?
                        <section className="flex flex-row justify-around gap-20">
                            <section className="flex flex-row justify-around gap-5">
                                <Link to="/" className="nav-button">Início</Link>
                                <Link to="/signupgestor" className="nav-button">Cadastrar clínica</Link>
                            </section>
                            <section className="flex flex-row justify-center items-center gap-2">
                                <Link to="/login" className="nav-button">Entrar</Link>
                                <div style={{width:"3px", height: "20px", backgroundColor: "#37e0b7"}}></div>
                                <Link to="/signup" className="nav-button">Cadastre-se</Link>
                            </section>
                        </section> :
                        isInvalidGerente || isValidGerente || isFuncionario || !userData ?
                            <section>
                                <Link to="/user">
                                    <BiUser size={28} className="my-2 mx-2 button-animation" />
                                </Link>
                            </section>
                             :
                            <Link to="/user">
                                <img src={userData.photo} alt="foto do usuário" className="h-10 w-10 rounded-full button-animation" />
                            </Link>
                }
            </div>

        </div >
    )

}