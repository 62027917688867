import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth';
import examReducer from './features/exam';
import userReducer from './features/user';

export default configureStore({
  reducer: {
      auth: authReducer,
      exam: examReducer,
      user: userReducer
  },
})