import React from 'react';

import { FiSearch, FiX } from 'react-icons/fi';

import setFromEvent from './../helpers/setFromEvent';

type SearchBarProps = {
    search: string|null,
    setSearch: (value: string|null ) => void
}

export default function SearchBar({search, setSearch}: SearchBarProps): React.ReactElement {
    const buttonProps = {
        size: 24,
        className: "ml-2 button-animation"
    }
    return (
        <div className='w-full flex flex-row items-center'>
            <input autoFocus placeholder='Pesquisar' value={search||""} onChange={setFromEvent(setSearch)} type="text" className='line-input' />
            {
                search === null?
                <FiSearch {...buttonProps} onClick={() => setSearch("")} />:
                <FiX {...buttonProps} onClick={() => setSearch(null)} />
            }

        </div>
    )
}