export const isInvalidName = (name: string) => {
    return (String(name)
        .toLowerCase()
        .match(
            /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u
        )) === null;;
};

export const isInvalidEmail = (email: string) => {
    return (String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )) === null;
};

export const getAge = (date: string) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const validatePassword = (password: string) => {
    return (password.length < 8) || (password.match(/^[0-9]+$/) != null)
};

export const validateCPF = (cpf: string) => {
    return !(cpf.match(/^[0-9]+$/) != null) || (cpf.length !== 11)
};

export const validatePhoneNumber = (phone_number: string) => {
    return !(phone_number.match(/^[0-9]+$/) != null) || (phone_number.length !== 11)
};