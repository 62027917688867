import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        logged: localStorage.getItem("logged") !== null ? true : false,
        perm: JSON.parse(localStorage.getItem("perm") || "{}"),
        user_type: localStorage.getItem("user_type"),
        gestor_is_valid: localStorage.getItem("gestor_is_valid"),
    },
    reducers: {
        logIn: (state) => {
            state.logged = true;
            window.localStorage.setItem("logged", "1")
        },
        logOut: (state) => {
            state.logged = false;
            window.localStorage.removeItem("logged")
        },
        setPerm: (state, action) => {
            state.perm = action.payload;
            localStorage.setItem("perm", JSON.stringify(action.payload))
        },
        setUserType: (state, action) => {
            state.user_type = action.payload;
            localStorage.setItem("user_type", action.payload)
        },
        setGestorIsValid: (state, action) => {
            state.gestor_is_valid = action.payload;
            localStorage.setItem("gestor_is_valid", action.payload)
        }
    }
})

export const { logIn, logOut, setPerm, setUserType, setGestorIsValid } = authSlice.actions;
export default authSlice.reducer;