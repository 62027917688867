import { useState } from 'react'
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

const RedirectBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.01);
    }
`;

const RedirectLink = styled.div`
    width: 90%;
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 5px;
    border-radius: 10px;
    height: 50%;
    font-size: 10 px;
    font-weight: bold;
    margin: 10px;
`


type CardClinicProps = {
    razao_social: string,
    email: string,
    address: string,
    clinica_id: number,
    action?: () => void,
}

export default function CardClinicGestor({ razao_social, email, address, clinica_id, action = () => { } }: CardClinicProps): React.ReactElement {
    const [active, setActive] = useState<Boolean>(false);
    const [redirect, setRedirect] = useState<string>();
    // "/myLabs/labId=" + clinica.id + "/funcionarios"
    const handleClick = (): void => {
        action();
        setActive(true);
    }

    if (redirect && active) return <Navigate to={redirect} />
    return (
        <div className="card" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: 'auto', marginBottom: '30px', width: '90%' }}>
            {
                window.innerWidth > 400 ?
                    <div className="my-auto p-2" >
                        { }
                    </div> : <></>
            }
            <div className="flex flex-col justify-center w-full ml-8">
                <p className="font-semibold">{razao_social}</p>
                <p className="text-gray-dark text-sm">{email}</p>
                <p className="text-gray-dark text-sm">{address}</p>
            </div>
            <RedirectBox>
                <RedirectLink onClick={() => {
                    localStorage.setItem("labId", clinica_id.toString());
                    setRedirect("/myLabs/labId");
                    handleClick()
                }}>Laboratório</RedirectLink>
                <RedirectLink onClick={() => {
                    localStorage.setItem("labId", clinica_id.toString());
                    setRedirect("/myLabs/labId/funcionarios");
                    handleClick()
                }}>Funcionários</RedirectLink>
                <RedirectLink onClick={() => {
                    localStorage.setItem("labId", clinica_id.toString());
                    setRedirect("/myLabs/labId/labExams");
                    handleClick()
                }}>Exames</RedirectLink>
                <RedirectLink onClick={() => {
                    localStorage.setItem("labId", clinica_id.toString());
                    setRedirect("/interactiveCalendar/labId");
                    handleClick();
                }}>Calendário</RedirectLink>
            </RedirectBox>
        </div>
    )
}