import { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { CarrouselNavigator } from '../../components';

import ChooseClinic from './ChooseClinic';
import ChooseExam from './ChooseExam';
import ChooseFilter from './ChooseFilter';
import ChooseTime from './ChooseTime';
import BookExam from './BookExam';
import CepPopWindow from './CepPopWindow';
import Error404Screen from '../Error404Screen';

export default function ChooseExamsScreen() {
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState<number>(0);
    const numberPages: number = 5;
    const setPage = (pageNumber: number) => () => setPageNumber(pageNumber);
    const goBack = (): void => navigate(-1);

    return (
        <div style={{ width: "100%" }}>
            <CepPopWindow />
            {
                pageNumber !== 0 ?
                    <CarrouselNavigator numberActive={pageNumber} numberPages={numberPages} goBack={goBack} />
                    : <></>
            }
            <Routes>
                <Route index element={<ChooseExam setPage={setPage(0)} />} />
                <Route path="filter" element={<ChooseFilter setPage={setPage(1)} />} />
                <Route path="clinic" element={<ChooseClinic setPage={setPage(2)} />} />
                <Route path="time" element={<ChooseTime setPage={setPage(3)} />} />
                <Route path="book" element={<BookExam setPage={setPage(4)} />} />
                <Route path="*" element={<Error404Screen />} />
            </Routes>
        </div>
    )
}