import React, { useState, useEffect } from 'react';
import checkCep from '../../helpers/checkCep';
import cleanCep from '../../helpers/cleanCep';

import useRequest from '../../hooks/useRequest';
import { LatLong, RequestState, RequestType } from '../../types';
import { Loader } from '../../components';
import setFromEvent from '../../helpers/setFromEvent';
import { useDispatch, useSelector } from 'react-redux';
import { setLocation } from '../../redux/features/user';

const dummyLatLong: LatLong = {
    lat: 1,
    lng: 1
}

type CepPopWindowProps = {
}

export default function CepPopWindow({ }: CepPopWindowProps): React.ReactElement {

    // general logic
    const dispatcher = useDispatch();

    // general user location logic
    const { lat, long, cep, locationAuth } = useSelector((state: any) => state.user);
    const [start, setStart] = useState<boolean>(true)
    const [latitude, setLatitude] = useState<number>(lat);
    const [longitude, setLongitude] = useState<number>(long);
    const [cepState, setCepState] = useState<string>(cep);
    const [locationAuthorization, setLocationAuthorization] = useState<null | boolean>(locationAuth);
    useEffect(() => {
        if (locationAuthorization === true) setShowCepWindow(false);
    }, []);
    useEffect(() => {
        dispatcher(setLocation({ location: [latitude, longitude, cepState], locationAuth: locationAuthorization }));
    }, [locationAuthorization, longitude, latitude, cepState]);

    // cep text logic
    const [newcep, setCep] = useState<string>("");
    const [finalCep, setFinalCep] = useState<string>(newcep);
    const [cepOk, setCepOk] = useState<boolean>(false);
    useEffect(() => {
        if(newcep !== "")
            setStart(false)
        setFinalCep(cleanCep(newcep));
        setCepOk(checkCep(newcep))
    }, [newcep])

    // request for cep to latlong
    // not storing in database
    const [latLong, setLatLong] = useState<LatLong>(dummyLatLong);
    const request = useRequest(`api/get-geolocation`, setLatLong, RequestType.Get);
    const requestState = request.requestState
    useEffect(() => {
        switch (requestState) {
            case RequestState.Successful:
                setLatitude(latLong.lat);
                setLongitude(latLong.lng);
                setCepState(finalCep)
                setLocationAuthorization(true);
                window.alert("CEP adicionado com sucesso!");
                closeWindow();
                window.location.reload()
                break;
            case RequestState.NotSuccessful:
                window.alert("Não foi possível adicionar o seu CEP no momento, tente novamente mais tarde.");
                break;
            default: break;
        }
    }, [requestState])

    //windows logic
    const [showCepWindow, setShowCepWindow] = useState<boolean>(true);
    const closeWindow = () => setShowCepWindow(false);
    const [blockedLocation, setBlockedLocation] = useState<boolean>(false);
    const handleLocation = () => getLocation(setLatitude, setLongitude, setCepState, setLocationAuthorization, setShowCepWindow, setBlockedLocation, finalCep);
    const handleSubmit = (event: any) => {
        event.preventDefault();
        request.call({
            cep: finalCep
        });
    }

    return !showCepWindow ? <></> : (
        <div className='z-10 h-screen w-screen absolute left-0 top-0 flex items-center justify-center bg-black bg-opacity-30'>
            <div className='p-4 max-w-xl rounded-md shadow-2xl bg-white m-4'>
                <h1>Precisamos da sua localização!</h1>
                <p className='my-2 text-justify'>Para podermos te oferecer as melhores clínicas e laboratórios precisamos saber em qual região você está. Para isso utilizamos a localização do seu aparelho ou o seu CEP!</p>
                <p className='my-2 text-gray-dark'>Insira o CEP:</p>
                <form action="#" onSubmit={handleSubmit}>
                    <input onChange={setFromEvent(setCep)} className='line-input' type="text" placeholder='CEP' autoFocus/>
                    {
                        !cepOk && !start ?
                            <p className='input-warning'>O CEP indicado não é válido.</p> : <></>
                    }
                    {
                        requestState === RequestState.Waiting ?
                            <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> :
                            <input className='button-primary w-full button-animation' type="submit" value="Confirmar CEP" />
                    }
                </form>
                {
                    !blockedLocation && requestState !== RequestState.Waiting ?
                        <button onClick={handleLocation} className='button-secondary w-full button-animation'>Ativar Localização</button> : <></>
                }
            </div>
        </div>
    )
}


function getLocation(
    setLat: (value: number) => void,
    setLong: (value: number) => void,
    setCepState: (value:string) => void,
    setAvailable: (value: boolean) => void,
    getCep: (value: boolean) => void,
    setBlockedLocation: (value: boolean) => void,
    finalCep: string,
): void {
    if (!navigator.geolocation) {
        setBlockedLocation(true);
        setAvailable(false);
        getCep(true);
        window.alert('O seu navegador não suporta localização.');
    } else {
        navigator.geolocation.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLong(position.coords.longitude);
            setCepState(finalCep)
            setAvailable(true);
            getCep(false);
            window.alert('Localização registrada com sucesso!');
        }, () => {
            setBlockedLocation(true);
            setAvailable(false);
            getCep(true);
        });
    }
}
