import { useState } from 'react'
import { Navigate } from 'react-router-dom';

type CardButtonProps = {
    icon: any,
    title: string,
    subtitle: string,
    redirect?: string,
    action?: () => void,
}

export default function CardExam({title, subtitle, icon, action = () => {}, redirect = ""}: CardButtonProps): React.ReactElement{
    const [active, setActive] = useState<Boolean>(false);
    const handleClick = (): void => {
        action();
        setActive(true);
    }

    if(redirect && active) return <Navigate to={redirect} />
    return (
        <div className="py-6 px-16 cursor-pointer bg-white justify-cente items-center" onClick={handleClick} style={window.innerWidth > 1000 ? {alignContent: "start", height: "230px", border: "1px solid #f5f5f5", backgroundColor: "#fff", borderRadius: "14px", width: "280px"} : {alignContent: "center", height: "230px", border: "1px solid #f5f5f5", backgroundColor: "#fff", borderRadius: "14px", width: "300px"}}>
            <div className="flex flex-col items-center justify-center pl-2 pr-1 py-2" style={{width:"48px", borderRadius: "24px", backgroundColor:"#b6ede0"}}>
                {icon}
            </div>
            <p style={{marginTop: "30px", fontSize: "16px", fontWeight: "400", lineHeight: "24px"}}>{title}</p>
            <p style={{fontSize: "12px", fontWeight: "400", lineHeight: "24px"}}>{subtitle}</p>
        </div>
    )
}