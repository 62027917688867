import { Link, Navigate, useNavigate } from "react-router-dom"
import useAuthorization, { useFuncionario, useGerente, useGerenteInvalid } from '../hooks/useAuthorization';
import styled from "styled-components";
import QuemSomosNos from '../assets/images/home/quem_somos_nos.svg'
import Banner from '../assets/images/home/banner.svg'
import MenuBook from '../assets/images/home/MenuBook.svg'
import Biotech from '../assets/images/home/Biotech.svg'
import Localizacao from '../assets/images/home/localizacao.svg'
import Tempo from '../assets/images/home/tempo.svg'
import Dinheiro from '../assets/images/home/dinheiro.svg'
import CardButton from "src/components/CardButton";
import CardButtonBanner from "src/components/CardButtonBanner"
import LogoFooter from '../assets/images/logo.svg'

export default function HomeScreen(): React.ReactElement {
    const isValidGerente = useGerente();
    const isFuncionario = useFuncionario()
    const is_authorized = useAuthorization();

    const navigate = useNavigate()


    if (isValidGerente) {
        return (
            <StyledHomeScreen>
                <h1>Quem é a eSanus?</h1>
                <p className=" text-justify py-4">eSanus é a escolha certa na hora de marcar um exame! Nós analisamos os melhores preços, horários e localidades para te ligar ao centro perfeito para as suas necessidades. Chega de telefonar para todos os laboratórios da cidade ou sempre achar que está pagando mais caro que deveria, cadastre-se agora mesmo!</p>
                <p className=" text-justify py-4">Este aplicativo foi construído pela Equipe eSanus, essa start-up brasileira que quer enfrentar e resolver os problemas de saúde do nosso povo.</p>
                <h1>Seja bem-vindo gestor</h1>

                <p className=" text-justify py-4">No canto superior esquerdo, você pode acessar seus laboratórios, inserir novos laboratórios e confirmar exames.</p>

            </StyledHomeScreen>

        )
    }
    else if (isFuncionario) {
        return (
            <StyledHomeScreen>
                <h1>Quem é a eSanus?</h1>
                <p className=" text-justify py-4">eSanus é a escolha certa na hora de marcar um exame! Nós analisamos os melhores preços, horários e localidades para te ligar ao centro perfeito para as suas necessidades. Chega de telefonar para todos os laboratórios da cidade ou sempre achar que está pagando mais caro que deveria, cadastre-se agora mesmo!</p>
                <p className=" text-justify py-4">Este aplicativo foi construído pela Equipe eSanus, essa start-up brasileira que quer enfrentar e resolver os problemas de saúde do nosso povo.</p>
                <h1>Seja bem-vindo</h1>

                <p className=" text-justify py-4">No canto superior esquerdo, você pode acessar confirmar exames.</p>

            </StyledHomeScreen>

        )
    }
    return (
        <StyledHomeScreen>
            <div className={window.innerWidth > 1000 ? "flex flex-row content-center justify-center items-center pt-12 pb-12 px-16" : "flex flex-row content-center justify-center items-center pt-10 pb-10 px-5"} style={{backgroundColor: "#37e0b7"}}>
                <section className="flex flex-col justify-center items-start gap-6">
                    <h1 style={{fontSize: "36px", fontWeight: "700", lineHeight: "54px", maxWidth: "617px", width: "100%"}}>Exames de qualidade realizados da melhor forma para você!</h1>
                    <p style={{fontSize: "20px", fontWeight: "400", lineHeight: "24px"}}>eSanus é a escolha certa na hora de cuidar da sua saúde.</p>
                    <section className={window.innerWidth > 1000 ? "flex flex-row content-center justify-center items-center gap-6" : "flex flex-col content-center justify-center items-center gap-6"}>
                        <CardButtonBanner
                            icon={MenuBook}
                            title={"Ver meus exames"}
                            action={() => navigate("/exam_token")}
                            align={"center"}
                        />
                        <CardButtonBanner
                            icon={Biotech}
                            title={"Marcar um exame"}
                            action={() => navigate("/chooseExam")}
                            align={"center"}
                        />
                    </section>
                </section>
                {window.innerWidth > 1000 ? 
                    <img src={Banner}/>
                : <></>}
            </div>

            <div className="flex flex-row justify-center content-center items-center pt-20" style={{margin: "0 auto", width:"90%", marginTop: "50px"}}>
                {window.innerWidth > 1000 ? <img src={QuemSomosNos} style={{overflow: "hidden", height: "100%", borderRadius: "20px", marginRight: "50px"}}/> : <></>}
                <section style={ window.innerWidth > 1000 ? {textAlign: "start", marginLeft: "30px", maxWidth: "700px"} : {textAlign: "start", width: "100%"}}>
                    <h1 style={{fontSize: "32px", fontWeight: "700px", lineHeight: "48px"}}>Quem é a eSanus?</h1>
                    <p style={{marginTop: "40px", lineHeight: "21px", fontWeight: "400px", fontSize: "14px", maxWidth:"577px", width: "100%"}}>
                        Somos uma plataforma que visa a democratização do acesso a saúde, através da oferta de exames reduzindo filas de espera e distância. Tudo isso cabendo no seu bolso!
                    </p>
                    {/* <p className=" text-justify py-4" style={{marginTop: "40px", lineHeight: "21px", fontWeight: "400px", fontSize: "14px", maxWidth:"577px", width: "100%"}}>Este aplicativo foi construído pela Equipe eSanus, essa start-up brasileira que quer enfrentar e resolver os problemas de saúde do nosso povo.</p> */}

                </section>
            </div>

            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "70px", marginBottom: "100px"}}>
                <h1 style={{fontWeight: "700px", fontSize: "32px", lineHeight: "48px", marginBottom: "32px"}}>Evite filas!</h1>
                <div
                    onClick={() => navigate("/chooseExam")}
                    className="cursor-pointer"
                    style={window.innerWidth > 1000 ?
                        {display: "flex", borderWidth: "2px", borderColor: "#f5f5f5", backgroundColor: "#fff", borderRadius: "14px", flexDirection: "row", alignItems: "flex-start", gap: "48px", justifyContent: "center", maxWidth: "900px", width: "100%"} :
                        {display: "flex", borderWidth: "2px", borderColor: "#f5f5f5", backgroundColor: "#fff", borderRadius: "14px", flexDirection: "column", alignItems:"center", alignContent: "center", justifyContent: "center", gap:"20px"}
                    }
                >
                    <CardButton
                        icon={Localizacao}
                        title={"Mais próximo"}
                        subtitle={"Encontre a clínica mais próxima"}
                        action={() => navigate("/")}
                    />
                    <CardButton
                        icon={Tempo}
                        title={"Mais rápido"}
                        subtitle={"Encontre as datas mais próximas"}
                        action={() => navigate("/")}
                    />
                    <CardButton
                        icon={Dinheiro}
                        title={"Mais barato"}
                        subtitle={"Encontre os melhores preços"}
                        action={() => navigate("/")}
                    />
                </div>
            </div>
            <div className="flex w-full pl-10 py-10" style={window.innerWidth > 1000 ? {flexDirection: "row", alignContent: "center", justifyContent: "space-around", backgroundColor: "#3a3939"} : {flexDirection: "column", backgroundColor: "#3a3939"}}>
                <div className="flex w-full px-0" style={window.innerWidth > 1000 ? {flexDirection: "row", alignContent: "center", alignItems: "center", justifyContent: "start", gap: "50px"} : {flexDirection: "column", alignContent: "center", justifyContent: "center"}}>
                    <section className="flex flex-col gap-5 justify-center items-start">
                        <img src={LogoFooter} className="h-10"/>
                        <p className="text-white text-sm leading-4">Contato: esanus@gmail.com</p>
                    </section>
                    <section className="flex flex-col gap-5 justify-center items-start">
                        <a className="text-white text-sm leading-4" href="/">Início</a>
                        <a className="text-white text-sm leading-4" href="/signupgestor">Seja nosso parceiro</a>
                    </section>
                    <section className="flex flex-col gap-5 justify-center items-start">
                        <a className="text-white text-sm leading-4" href="/chooseExam">Marcar exames</a>
                        <a className="text-white text-sm leading-4" href="/exam_token">Ver exames</a>
                    </section>
                </div>
                {window.innerWidth > 1000 ? 
                    <section className="flex flex-row pr-5">
                        <button className="button-primary" style={{width: "139px", height: "34px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "5px", color:"#000", fontSize: "13px", fontWeight: "500px"}} onClick={() => navigate("/login")} >Entrar</button>
                        <button className="button-primary" style={{width: "139px", height: "34px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "5px", color:"#000", fontSize: "13px", fontWeight: "500px"}} onClick={() => navigate("/signup")}>Cadastre-se</button>
                    </section>
                : <></>}
            </div>
        </StyledHomeScreen>
    )
}

const StyledHomeScreen = styled.div`
    .page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: #f9fbfc;
    }`

const TwoLinks = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    `
