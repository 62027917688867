import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '../../components';
import useRequest from '../../hooks/useRequest';
import { RequestState, RequestType } from '../../types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cleanExamState } from '../../redux/features/exam';
import styled from 'styled-components';
import { AiOutlineCheckCircle } from 'react-icons/ai';
type BookExamProps = {
    setPage: () => void,
}

const Title = styled.div`

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 24px;
/* identical to box height, or 100% */

/* text / primary */

color: #212121;
    `
const SubTitle = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
text-align: center;
margin-top: 8px;
margin-bottom: 27px;
/* text / primary */

color: #212121;
`

export default function BookExam({ setPage }: BookExamProps): React.ReactElement {

    // initial data
    const navigator = useNavigate();
    const dispatcher = useDispatch();
    const { demandDate, demandTime, labPhone, examName, labName, bookingId, examId, labAddress } = useSelector((state: any) => state.exam);
    const { name } = useSelector((state: any) => state.user.userData);
    const redirectToWhatsApp = () => {
        if (labPhone === null) {
            window.alert("Infelizmente não foi possível achar o número de telefone deste laboratório. Tente contactá-los para terminar de marcar o seu exame.");
            return;
        };
        const text = `Olá! Eu me chamo ${name} e eu gostaria de marcar um exame de ${examName} na seu laboratório, ${labName}, no dia ${demandDate} às ${demandTime}h.\n\nEu fui enviado pela eSanus, uma ferramenta que recomenda somente os melhores centros de exames para seus clientes! Saiba mais em esanus.com.br !\n\nIdentificador do exame: ${bookingId}`;
        window.open(`https://wa.me/+55${labPhone}?text=${encodeURI(text)}`, "_blank");
    }
    const goFirst = () => navigator("../");

    // checking if needed data
    useEffect(() => {
        if (bookingId === null) navigator("../");
        setPage();
    }, [bookingId, navigator, setPage]);

    // request
    const request = useRequest(`api/booking-slots/${bookingId}/book/`, () => { }, RequestType.Post);
    const requestState = request.requestState;
    useEffect(() => {
        request.call({
            lab_exam: examId,
        });
        dispatcher(cleanExamState())
    }, [dispatcher]);

    useEffect(() => {
        switch (requestState) {
            case RequestState.Successful:
                redirectToWhatsApp();
                break;
            case RequestState.NotSuccessful:
                redirectToWhatsApp();
                break;
            default: break;
        }
    }, [requestState])

    return (
        <div className="flex flex-col items-center px-1">
            <div className='my-4'>
                {
                    requestState === RequestState.Waiting ?
                        <>
                            <h1>Confirmando seu pedido.</h1>
                            <Loader />
                        </> :
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Title>Seu pedido foi encaminhado!</Title>
                            <SubTitle>Em alguns instantes você será redirecionado para conversar com o laboratório {labName} relativo ao exame {examName}:</SubTitle>
                            <AiOutlineCheckCircle size={"200px"} color="#37E0B7" />
                            {/* <div className='ml-6 my-4'>
                                <p>Nome do Laboratório : {labName}</p>
                                <p>Endereço: {labAddress}</p>
                                <p>Telefone: {labPhone}</p>
                            </div>
                            <p>Sobre o seu exame de {examName} (Identificador: {bookingId})</p> */}
                        </div>
                }
            </div>
            <button style={{ width: "415px", height: "48px", borderRadius: "5px" }} className='button-primary' onClick={goFirst}>Marcar um novo exame!</button>
        </div>
    )
}