import React, { useEffect } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import useAuthorization from "../../hooks/useAuthorization"
import useRequest from 'src/hooks/useRequest';
import { RequestState, RequestType, Lab } from 'src/types';
import { emptyLab } from 'src/constants/emptyTypes';
import EditDataLab from 'src/components/EditData/EditDataLab';

export default function EditLab(): React.ReactElement {
    let {labId} = useParams()
    const navigate = useNavigate()

    if(labId === undefined) {
        const labIdLocalStorage = localStorage.getItem("labId");
        if (!labIdLocalStorage)
            navigate("/");
        else{
            labId = labIdLocalStorage
        }
    }

    const [objectParam, setObjectParam] = useState<Lab>(emptyLab)
    const request = useRequest(`api/labs/${labId}/`, setObjectParam, RequestType.Get)
    const requestStatus = request.requestState

    useEffect(() => request.call({}), [])

    useEffect(() => {
        switch(requestStatus){
            case RequestState.NotSuccessful:
                window.alert("Não foi possível completar a requisição no momento, tente novamente mais tarde.")
                break;
            default:
                break;
        }
    }, [requestStatus])

    if (!useAuthorization()) return <Navigate to="/" replace />

    return (
        <div className="page" style={{ marginBottom: "40px" }}>
            {
                requestStatus == RequestState.Waiting || labId === undefined || objectParam.id === 0 ? <></> :
                <EditDataLab
                    title={"Editar dados da Clínica"}
                    listParams={{
                        razao_social: objectParam.razao_social,
                        description: objectParam.description,
                        email: objectParam.email,
                        address: objectParam.address,
                        telefone: objectParam.telefone,
                        cep: objectParam.cep
                    }}
                    entity={"labs"}
                    entityId={labId}
                />
            }
        </div>
    )
}