import React from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { Funcionario, RequestState, RequestType } from '../../types';
import { Loader } from '../../components';
import useAuthorization from "../../hooks/useAuthorization"
import CardFuncionario from 'src/components/CardFuncionario';
import DeleteButton from 'src/components/EditData/DeleteButtons';

export default function MyFuncionarios(): React.ReactElement {
    const navigate = useNavigate()
    let { labId } = useParams()
    const [funcionarios, SetFuncionarios] = useState<Funcionario[]>()
    const request = useRequest('api/funcionario/get_funcionario_by_lab/', SetFuncionarios, RequestType.Get);
    const requestState = request.requestState;

    useEffect(() => {
        if(labId !== undefined){
            request.call({
                lab_id: labId
            })
        } else{
            const labIdLocalStorage = localStorage.getItem("labId");
            if (!labIdLocalStorage)
                navigate("/");
            else{
                labId = labIdLocalStorage
                request.call({
                    lab_id: labIdLocalStorage
                })
            }
        }
        
    }, [])

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            default: break;
        }
    }, [requestState])

    if (!useAuthorization()) return <Navigate to="/login" replace />

    return (
        <div>
            <h1 style={{ marginTop: '15px', textAlign: 'center', marginBottom: '20px' }}>Meus Funcionários</h1>
            {
                requestState === RequestState.Waiting ?
                    <Loader />
                    : <div>
                        {funcionarios?.map((funcionario: Funcionario) => (
                            <>
                                <CardFuncionario
                                    name={funcionario.user.name}
                                    photo=""
                                    lab={funcionario.lab.razao_social}
                                    email={funcionario.user.email}
                                    redirect={"/myLabs/labId/funcionarioId"}
                                    action={() => {
                                        localStorage.setItem("labId", labId?.toString() || "");
                                        localStorage.setItem("funcionarioId", (funcionario.id).toString());
                                    }}
                                />
                            </>
                        ))}
                        <Link className="button-primary" onClick={() => localStorage.setItem("labId", labId?.toString() || "")} to={"/myLabs/labId/funcionarios/addFuncionario"}>Adicionar Funcionário</Link>
                    </div>
            }
        </div>
    )
}