import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BookingSlotsMultipleLineChart from "src/components/BookingSlotsMultipleLineChart";
import { emptyListBookingSlotLabExamUser } from "src/constants/emptyTypes";
import useRequest from "src/hooks/useRequest";
import { BookingSlotLabExam, BookingSlotStatus, RequestState, RequestType } from "src/types";
import styled from "styled-components";

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const VerticalContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    min-width: 1280px;
`

const Row = styled.div`
    width: 400px;
    display: flex;
    margin-top: 5px;
    flex-direction: row;
    justify-content: space-between;
`
const ColumnLeft = styled.div`
    border: 1px solid #333;
    background-color: #f2f2f2;
    text-align: left;
    border-radius: 5px;
    padding-left: 5px;
    display: flex;
    width: 300px;
    font-size: 13px;
    flex-direction: column;
`
const ColumnCenter = styled.div`
    border: 1px solid #333;
    background-color: #f2f2f2;
    margin-left: 5px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    width:100px;
`

const TextHeader = styled.div`
    font-size: 25px;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
`
type pointData = {
    data: string,
    Realizados: number,
    "Não Utilizados": number,
    "Com Não Comparecimento": number
}

export default function InstitutionStats(): React.ReactElement {
    const { labIdList } = useParams();
    const navigate = useNavigate();
    let lab_id_array:any;
    if(labIdList === undefined) {
        const labIdListLocalStorage = localStorage.getItem("labIdList");
        if (!labIdListLocalStorage)
            navigate("/");
        else{
            lab_id_array = labIdListLocalStorage
        }
    } else {
        console.log(labIdList?.split(','));
        lab_id_array = labIdList?.split(',').map(e => parseInt(e));
    }
    const [listBookSlot, setListBookSlot] = useState<BookingSlotLabExam[]>(emptyListBookingSlotLabExamUser)
    const getListGestor = useRequest(`api/booking-slots/get_book_slot_by_gestor/`, setListBookSlot, RequestType.Get);
    const today = new Date().toISOString().substr(0, 10)
    const date90DaysAgo = new Date()
    date90DaysAgo.setDate(date90DaysAgo.getDate() - 90)
    const dateString90daysAgo = date90DaysAgo.toISOString().substr(0, 10)
    const [inicioDate, setInicioDate] = useState(dateString90daysAgo)
    const [fimDate, setFimDate] = useState(today)
    const [data, setData] = useState<pointData[]>([])
    const [confirmedBookingSlots, setConfirmedBookingSlots] = useState(0)
    const [unusedBookingSlots, setUnusedBookingSlots] = useState(0)
    const [noShowBookingSlots, setNoShowBookingSlots] = useState(0)
    const [totalBookingSlots, setTotalBookingSlots] = useState(0)
    const [fillRate, setFillRate] = useState(0)
    const [noShowRate, setNoShowRate] = useState(0)
    const [revenue, setRevenue] = useState(0)
    const [confirmedRevenue, setConfirmedRevenue] = useState(0)
    const [unusedRevenue, setUnusedRevenue] = useState(0)
    const [noShowRevenue, setNoShowRevenue] = useState(0)
    const [fillRevenueRate, setFillRevenueRate] = useState(0)


    function getTimestampMonth(date: string): string {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        d.setDate(1);
        return d.toDateString();
    }

    const processData = () => {
        const ungroupedData = listBookSlot.filter((bookingSlot: BookingSlotLabExam) => {
            return bookingSlot.id > 0 &&
                bookingSlot.date_time_from !== "" &&
                bookingSlot.date_time_from > inicioDate + "T00:00:00-03:00" &&
                bookingSlot.date_time_from < fimDate + "T23:59:59-03:00"
        }).map((bookingSlot: BookingSlotLabExam) => {
            const date = getTimestampMonth(bookingSlot.date_time_from);
            return {
                data: `S${date}`,
                status: bookingSlot.status,
                price: bookingSlot.price,
            }
        })
        const data = ungroupedData.reduce((result: any, booking_slot) => {
            (result[booking_slot.data] = result[booking_slot.data] || []).push(
                booking_slot
            );
            return result;
        }, {});
        const groupedData = Object.keys(data).map((key) => {
            return {
                data: key,
                Realizados: data[key].filter((booking_slot: any) => booking_slot.status === BookingSlotStatus.booked).length,
                "Não Utilizados": data[key].filter((booking_slot: any) => booking_slot.status === BookingSlotStatus.free).length,
                "Com Não Comparecimento": data[key].filter((booking_slot: any) => booking_slot.status === BookingSlotStatus.inProgress).length,
            }
        })
        setData(groupedData);
        setTotalBookingSlots(listBookSlot.filter((bookingSlot: BookingSlotLabExam) => {
            return bookingSlot.id > 0 &&
                bookingSlot.date_time_from !== "" &&
                bookingSlot.date_time_from > inicioDate + "T00:00:00-03:00" &&
                bookingSlot.date_time_from < fimDate + "T23:59:59-03:00"
        }).length)
        setRevenue(listBookSlot.filter((bookingSlot: BookingSlotLabExam) => {
            return bookingSlot.id > 0 &&
                bookingSlot.date_time_from !== "" &&
                bookingSlot.date_time_from > inicioDate + "T00:00:00-03:00" &&
                bookingSlot.date_time_from < fimDate + "T23:59:59-03:00"
        }).reduce((total: number, booking_slot: BookingSlotLabExam) => Math.round(100 * total + parseFloat(booking_slot.price)) / 100, 0))
        setConfirmedBookingSlots(listBookSlot.filter((bookingSlot: BookingSlotLabExam) => {
            return bookingSlot.id > 0 &&
                bookingSlot.date_time_from !== "" &&
                bookingSlot.date_time_from > inicioDate + "T00:00:00-03:00" &&
                bookingSlot.date_time_from < fimDate + "T23:59:59-03:00" &&
                bookingSlot.status === BookingSlotStatus.booked
        }).length)
        setConfirmedRevenue(listBookSlot.filter((bookingSlot: BookingSlotLabExam) => {
            return bookingSlot.id > 0 &&
                bookingSlot.date_time_from !== "" &&
                bookingSlot.date_time_from > inicioDate + "T00:00:00-03:00" &&
                bookingSlot.date_time_from < fimDate + "T23:59:59-03:00" &&
                bookingSlot.status === BookingSlotStatus.booked
        }).reduce((total: number, booking_slot: BookingSlotLabExam) => Math.round(100 * total + parseFloat(booking_slot.price)) / 100, 0))
        setUnusedBookingSlots(listBookSlot.filter((bookingSlot: BookingSlotLabExam) => {
            return bookingSlot.id > 0 &&
                bookingSlot.date_time_from !== "" &&
                bookingSlot.date_time_from > inicioDate + "T00:00:00-03:00" &&
                bookingSlot.date_time_from < fimDate + "T23:59:59-03:00" &&
                bookingSlot.status === BookingSlotStatus.free
        }).length)
        setUnusedRevenue(listBookSlot.filter((bookingSlot: BookingSlotLabExam) => {
            return bookingSlot.id > 0 &&
                bookingSlot.date_time_from !== "" &&
                bookingSlot.date_time_from > inicioDate + "T00:00:00-03:00" &&
                bookingSlot.date_time_from < fimDate + "T23:59:59-03:00" &&
                bookingSlot.status === BookingSlotStatus.free
        }).reduce((total: number, booking_slot: BookingSlotLabExam) => Math.round(100 * total + parseFloat(booking_slot.price)) / 100, 0))
        setNoShowBookingSlots(listBookSlot.filter((bookingSlot: BookingSlotLabExam) => {
            return bookingSlot.id > 0 &&
                bookingSlot.date_time_from !== "" &&
                bookingSlot.date_time_from > inicioDate + "T00:00:00-03:00" &&
                bookingSlot.date_time_from < fimDate + "T23:59:59-03:00" &&
                bookingSlot.status === BookingSlotStatus.inProgress
        }).length)
        setNoShowRevenue(listBookSlot.filter((bookingSlot: BookingSlotLabExam) => {
            return bookingSlot.id > 0 &&
                bookingSlot.date_time_from !== "" &&
                bookingSlot.date_time_from > inicioDate + "T00:00:00-03:00" &&
                bookingSlot.date_time_from < fimDate + "T23:59:59-03:00" &&
                bookingSlot.status === BookingSlotStatus.inProgress
        }).reduce((total: number, booking_slot: BookingSlotLabExam) => Math.round(100 * total + parseFloat(booking_slot.price)) / 100, 0))
        setFillRate(totalBookingSlots === 0 ? 0 : Math.round(100.0 * confirmedBookingSlots / totalBookingSlots))
        setNoShowRate(totalBookingSlots === 0 ? 0 : Math.round(100.0 * noShowBookingSlots / totalBookingSlots))
        setFillRevenueRate(confirmedRevenue === 0 ? 0 : Math.round(100.0 * confirmedRevenue / revenue))
    }

    useEffect(() => {
        if (labIdList !== undefined && inicioDate !== "" && fimDate !== "" && listBookSlot.length <= 1) {
            getListGestor.call({})
        }
    }, [labIdList, inicioDate, fimDate])

    useEffect(() => {
        switch (getListGestor.requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possível carregar as informações. Tente novamente mais tarde");
                navigate("/")
                break;
            default:
                console.log(listBookSlot)
                setListBookSlot(listBookSlot.filter((bookingSlot: BookingSlotLabExam) => { return lab_id_array?.includes(bookingSlot.lab_exam.lab.id) }))
                processData()
                break;
        }
    }, [getListGestor.requestState, inicioDate, fimDate])


    return <div className="page" style={{ marginBottom: "40px" }}>
        <MainContainer>
            <VerticalContainer>
                <MainContainer>
                    <TextHeader>Quantidade de Exames Mês a Mês</TextHeader>
                    <h1>Realizados, Não Utilizados e Com Não Comparecimento</h1>

                    {inicioDate !== "" && fimDate !== "" ? <BookingSlotsMultipleLineChart data={data} /> : <></>}
                    <br></br>
                </MainContainer>
                <MainContainer>
                    <TextHeader>Resumo do Período</TextHeader>
                    <h1>Período Padrão = Últimos 90 Dias</h1>

                    <Row>
                        <ColumnLeft>Exames Realizados</ColumnLeft>
                        <ColumnCenter> {confirmedBookingSlots}</ColumnCenter>
                    </Row>
                    <Row>
                        <ColumnLeft>Exames Não Utilizados</ColumnLeft>
                        <ColumnCenter> {unusedBookingSlots}</ColumnCenter>
                    </Row>
                    <Row>
                        <ColumnLeft>Exames Com Não Comparecimento</ColumnLeft>
                        <ColumnCenter> {noShowBookingSlots}</ColumnCenter>
                    </Row>
                    <Row>
                        <ColumnLeft>Total de Exames</ColumnLeft>
                        <ColumnCenter> {totalBookingSlots}</ColumnCenter>
                    </Row>
                    <Row>
                        <ColumnLeft>Taxa de Aproveitamento</ColumnLeft>
                        <ColumnCenter>{fillRate}%</ColumnCenter>
                    </Row>
                    <Row>
                        <ColumnLeft>Taxa de Não Comparecimento</ColumnLeft>
                        <ColumnCenter>{noShowRate}%</ColumnCenter>
                    </Row>
                    <Row>
                        <ColumnLeft>Faturamento Realizado</ColumnLeft>
                        <ColumnCenter> R$ {confirmedRevenue}</ColumnCenter>
                    </Row>
                    <Row>
                        <ColumnLeft>Faturamento Perdido - Não Agendamento</ColumnLeft>
                        <ColumnCenter> R$ {unusedRevenue}</ColumnCenter>
                    </Row>
                    <Row>
                        <ColumnLeft>Faturamento Perdido - Não Comparecimento</ColumnLeft>
                        <ColumnCenter> R$ {noShowRevenue}</ColumnCenter>
                    </Row>
                    <Row>
                        <ColumnLeft>Máximo Faturamento Possível</ColumnLeft>
                        <ColumnCenter> R$ {revenue}</ColumnCenter>
                    </Row>
                    <Row>
                        <ColumnLeft>Taxa de Faturamento Aproveitada</ColumnLeft>
                        <ColumnCenter>{fillRevenueRate}%</ColumnCenter>
                    </Row>
                </MainContainer>
            </VerticalContainer>
            <VerticalContainer>
                <h1>Escolha o Período a Ser Análisado: </h1>
                <div className={window.innerWidth > 400 ? "flex flex-row" : "flex flex-col"} style={{ alignSelf: "center" }}>
                    <section className={window.innerWidth > 400 ? "mr-5" : ""} >
                        <label>Início </label>
                        <input className='line-input' onChange={e => setInicioDate(e.target.value)} type="date" placeholder="Início" />
                        {
                            inicioDate === "" ?
                                <p className="input-warning">Selecione a data de início do gráfico</p> : <></>
                        }
                    </section>
                    <section className={window.innerWidth > 400 ? "ml-5" : ""}>
                        <label>Fim </label>
                        <input className='line-input' onChange={e => setFimDate(e.target.value)} type="date" placeholder="Fim" />
                        {
                            fimDate === "" ?
                                <p className="input-warning">Selecione a data de fim do gráfico</p> : <></>
                        }
                    </section>
                </div>
            </VerticalContainer>


        </MainContainer>
    </div>
}