import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { RequestState } from "../types";
import useExamToken from "../hooks/useExamToken";
import { Loader } from "../components";

import { Background, Page, Header, Title, CenterDiv, Line, OtherWithLine, LeftDiv } from "../components/NewDesignCommons";
import { IoIosArrowBack } from "react-icons/io";
import Logo from '../components/Logo';

export default function ExamTokenScreen(): React.ReactElement {
    const examToken = useExamToken();
    const requestState = examToken.requestState;
    const navigator = useNavigate()
    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possível acessar o exame. Tente novamente mais tarde.");
                break;
            default: break;
        }
    }, [requestState])

    if (requestState === RequestState.Successful){
        localStorage.setItem("id_exam", examToken.idExam.toString());
        return <Navigate to={"/myExams/id"} replace />
    }

    return (
        <Background>
            <Logo />
            <Page>
                <Header>
                    <IoIosArrowBack onClick={() => navigator("/")} style={{ fontSize: '24px', color: '#212121', cursor: 'pointer' }} />
                    <Title>Acessar Exames</Title>
                </Header>
                <form className="flex flex-col px-3" onSubmit={examToken.connect}>
                    <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width:"230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}} 
                    type="text" placeholder="Token de 6 dígitos" maxLength={6} required onChange={examToken.setToken} />
                    {
                        requestState === RequestState.InvalidToken ?
                            <p className="text-sm text-red-600">Token Inválido.</p> : <></>
                    }
                    <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                    :{width: "230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px"}} 
                    type="date" placeholder="Data de nascimento" required onChange={examToken.setBirthday} />
                    {
                        requestState === RequestState.MissingData ?
                            <p className="text-sm text-red-600">Os campos acima devem ser preenchidos</p>
                            : requestState === RequestState.BirthdayIncorrect ?
                                <p className="text-sm text-red-600">Data de nascimento incorreto</p>
                                : <></>
                    }
                    {
                        requestState === RequestState.Waiting ?
                            <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> :
                            <input className="button-primary" style={window.innerWidth > 500 ? {width: "360px", marginLeft: "0px"} 
                            : {maxWidth: "230px", width:"100%", marginLeft: "0px"}} 
                            type="submit" value="Acessar meu exame" />
                    }
                </form>
                <CenterDiv>
                    <p>Já possui uma conta?&nbsp;&nbsp;</p><Link to="/login" className="button-animation" style={{ color: "#37E0B7" }}>Entre</Link>
                </CenterDiv>
                <OtherWithLine>
                    <Line />
                    <span>Ou</span>
                    <Line />

                </OtherWithLine>
                <CenterDiv>
                    <p>Ainda não é inscrito?&nbsp;&nbsp;</p><Link to="/signupgestor" className="button-animation" style={{ color: "#37E0B7" }}>Inscreva-se</Link>
                </CenterDiv>
            </Page>
        </Background>
    )
}