import { useState, useEffect } from 'react';
import useRequest from '../hooks/useRequest';
import {BiUser} from 'react-icons/bi'
import { Card, CarrouselNavigator, SearchBar } from '../components';
import distanceBetweenPoints from '../helpers/distanceBetweenPoints';
import ReactPaginate from 'react-paginate';
import MyExam from './MyExams/MyExam'

const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

function Items({ currentItems }:any) {
    return (
        <>
            {currentItems &&
                currentItems.map((item:any) => (
                <div>
                    <h3>Item #{item}</h3>
                </div>
            ))}
        </>
  );
}

export default function TestScreen (){

    const [count, setCount] = useState<number>(1);
    const handleClick = () => setCount(count + 1);

    useEffect(() => {
        console.log("o counter mudou")
    }, [count])
    const wppNumber = "+5531998524668";
    const wppText = "Olá! Eu fui enviado da sanus para o seu laboratório"

    // const [catFact, setCatFact] = useState<{fact: string}>({fact: ""});
    // const catRequest = useRequest('fact', setCatFact);
    const [predict, setPredict] = useState<{country: [{country_id: string, probability: number}]}>({country: [{country_id: "", probability: 0}]})
    const predictRequest = useRequest("", setPredict );

    // if(!authorized) return <Navigate to="/accessDenied" replace/>
    const [n, setN] = useState<number>(1);

    const [lat, setLat] = useState<number>();
    const [long, setLong] = useState<number>();
    const [available, setAvailable] = useState<boolean>();

    // window.open(`https://wa.me/${wppNumber}?text=${encodeURI(wppText)}`, "_blank");


    // PAGINATION START
    const [currentItems, setCurrentItems] = useState<any>(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 5;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event:any) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };
    // PAGINATION END

    console.log(process.env);
    return (
        <div>
            <h1>
                Im a test screen.
            </h1>
            <p>
            </p>

            <SearchBar setSearch={() => {}} search=""/>
            <button className='button-secondary'>
                button
            </button>
            <button className='button-primary' onClick={() => predictRequest.call({name: "guilherme"})} >
                call it
            </button>
            {predict.country.map((country: any, index: number) => <p key={index}>{country.country_id} with prob {country.probability}</p>)}
            <Card title='Test title' subtitle='test subtitle goes here' icon={<BiUser
            size={32}/>} redirect="/" />


            <CarrouselNavigator goBack={() => setN(n-1)} numberActive={n} numberPages={5}/>

            <p>lat {lat}</p>
            <p>long {long}</p>
            <p>av {available ? "sim" : "nao"}</p>

            <p>{
                distanceBetweenPoints([ 48.858059, 2.31152 ], [48.711284876403695, 2.209252301077994])
            }</p>
            <p>
            </p>
            {/* <MyExam /> */}
            <h1 style={{textAlign: "center"}}>Paginate</h1>
                <Items currentItems={currentItems} />
                <ReactPaginate
                    nextLabel={'>'}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    previousLabel={'<'}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    renderOnZeroPageCount={() => {}}
                />
        </div>
    )
}