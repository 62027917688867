import { useState } from 'react';

import { BiPencil } from 'react-icons/bi';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import setFromEvent from './../../helpers/setFromEvent';
import useRequest from '../../hooks/useRequest';
import { RequestState, RequestType } from '../../types';
import { useEffect } from 'react';
import { getAge, validateCPF, isInvalidName, validatePhoneNumber, isInvalidEmail } from 'src/helpers/validator';

type EditableLineInputProps = {
    title: string,
    defaultValue: any,
    propertyName: string,
    entity: string,
    entityId: string,
    type?: string,
    editable?: boolean
}

export default function EditableLineInput({ title, defaultValue, propertyName, entity, entityId, type = "text", editable = true }: EditableLineInputProps): React.ReactElement {
    const [content, setContent] = useState<typeof defaultValue>(defaultValue);
    const [edit, setEdit] = useState<boolean>(false)

    const editPropertyRequest = useRequest(`api/${entity}/${entityId}/`, () => { }, RequestType.Patch);
    const requestState = editPropertyRequest.requestState;

    useEffect(() => {
        switch (requestState) {
            case RequestState.Successful:
                window.alert(`${title} atualizado com sucesso!`);
                break
            case RequestState.UnableToChangeProperty:
                window.alert("Não foi possivel atualizar esse dado no momento. Tente novamente em alguns momentos!");
                break;
            case RequestState.NotSuccessful:
                window.alert(`Parece que estamos tendo problemas no servidor. Tente novamente em alguns momentos.`);
                break;
        }
    }, [requestState])

    const enableEdit = (): void => setEdit(true);
    const disableEdit = (): void => setEdit(false);
    const sendEdit = (event: any): void => {
        switch (title) {
            case "Nome":
                if (isInvalidName(content)) {
                    window.alert("Por favor, insira um nome válido!");
                    return;
                }
                break;
            case "Email":
                if (isInvalidEmail(content)) {
                    window.alert("Por favor, insira um email válido!");
                    return;
                }
                break;
            case "CPF":
                if (validateCPF(content)) {
                    window.alert("Por favor, insira um CPF válido!");
                    return;
                }
                break;
            case "Telefone":
                if (validatePhoneNumber(content)) {
                    window.alert("Por favor, insira um telefone válido!");
                    return;
                }
                break;
            case "Data de Nascimento":
                if (getAge(content) < 18) {
                    window.alert("Você deve ser maior de 18 anos para utilizar o serviço.");
                    return;
                }
                break;
        }
        event.preventDefault();
        disableEdit();
        if (content === defaultValue) {
            window.alert("Por favor insira um valor diferente.");
            return;
        }
        editPropertyRequest.call({
            [propertyName]: content
        });
    }

    return (
        <div className='w-full my-2'>
            <p className='mx-2'>{title}:</p>
            <form action="#" onSubmit={sendEdit} className='w-full flex flex-row justify-around items-center'>
                <input className={
                    edit ? 'line-input line-input-activate w-max' : 'line-input w-max text-gray-dark'
                } onChange={setFromEvent(setContent)} type={type} disabled={!edit} placeholder={defaultValue} value={type === "date" ? content : undefined} />
                {
                    !editable ? <></> :
                        edit ?
                            <span className='flex flex-row ml-2'>
                                <AiOutlineCheck size={20} onClick={sendEdit} className="mx-1 button-animation" />
                                <AiOutlineClose size={20} onClick={disableEdit} className="mx-1 button-animation" />
                            </span> :
                            <BiPencil size={20} onClick={enableEdit} className="mx-1 button-animation" />
                }
            </form>
        </div>

    )
}