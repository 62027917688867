import React, { useEffect } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import useAuthorization from "../../hooks/useAuthorization"
import EditData from 'src/components/EditData/EditData';
import useRequest from 'src/hooks/useRequest';
import { RequestState, RequestType, Lab, BookingSlotLabExam, LabExam } from 'src/types';
import { emptyLab, emptyLabExam } from 'src/constants/emptyTypes';

export default function EditExam(): React.ReactElement {
    let { labExamId } = useParams()
    const navigate = useNavigate()
    if(labExamId === undefined) {
        const labExamIdLocalStorage = localStorage.getItem("labExamId");
        if (!labExamIdLocalStorage)
            navigate("/");
        else{
            labExamId = labExamIdLocalStorage
        }
    }

    const [objectParam, setObjectParam] = useState<LabExam>(emptyLabExam)
    const request = useRequest(`api/lab-exams/${labExamId}/`, setObjectParam, RequestType.Get)
    const requestStatus = request.requestState

    useEffect(() => request.call({}), [])

    useEffect(() => {
        switch(requestStatus){
            case RequestState.NotSuccessful:
                window.alert("Não foi possível carregar alguns dados no momento, tente novamente mais tarde.")
                break;
            default:
                break;
        }
    }, [requestStatus])

    if (!useAuthorization()) return <Navigate to="/" replace />

    return (
        <div className="page" style={{ marginBottom: "40px" }}>
            {
                labExamId === undefined ? <></> :
                <EditData
                    nameDelete={"exame"}
                    title={"Editar os dados do exame"}
                    listParams={{
                        "name": objectParam.exam.name,
                        "description": objectParam.exam.description,
                    }}
                    entity={"exams"}
                    entityId={(objectParam.exam.id).toString()}
                />
            }
        </div>
    )
}