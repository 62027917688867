import { useSelector } from "react-redux";
import { PermissionTypes, UserTypeList } from "src/types";

export default function useAuthorization(permission?: PermissionTypes): boolean {
    const logged = useSelector((state: any) => state.auth.logged);
    const permissions = useSelector((state: any) => state.auth.perm) || { permissions: null };

    if (!permission) return logged;
    if (!permissions) return false;

    const perm: boolean = Boolean(permissions[permission.valueOf()]);
    return perm && logged;
}

export function useGerenteInvalid(): boolean {
    const user_type = useSelector((state: any) => state.auth.user_type);
    const gestor_is_valid = useSelector((state: any) => state.auth.gestor_is_valid);
    if (user_type === UserTypeList.GERENTE && gestor_is_valid === "false") return true
    return false
}


export function useGerente(): boolean {
    const user_type = useSelector((state: any) => state.auth.user_type);
    const gestor_is_valid = useSelector((state: any) => state.auth.gestor_is_valid);
    if (user_type === UserTypeList.GERENTE && gestor_is_valid === "true") return true

    return false
}

export function useFuncionario(): boolean {
    const user_type = useSelector((state: any) => state.auth.user_type);

    if (user_type === UserTypeList.FUNCIONARIO) return true

    return false
}
