import { useState } from 'react';
import { Day } from '../types'
import { FiChevronRight, FiChevronDown } from 'react-icons/fi'
import { TimeTable } from './index';

type DateTimeTableProps = {
    day: Day,
    index: number,
    collapsible: boolean,
    settingBookingIdAction: (bookingId: number, date: String, hour: String) => () => void;
}

export default function DateTimeTable({ day, index, collapsible, settingBookingIdAction }: DateTimeTableProps) {
    const [show, setShow] = useState<boolean>(false);
    return (
        <div className="my-4 px-1" style={{maxWidth: "400px", width: "100%"}} key={index} >
            <div className='flex items-center justify-between cursor-pointer' onClick={() => setShow(!show)}>
                <p className="px-4 text-sm border-b-2 border-gray w-full ">{day.date.getUserFormated()}</p>
                {
                    show ?
                        <FiChevronDown /> :
                        <FiChevronRight />
                }
            </div>
            {
                show ?
                    <TimeTable day={day} settingBookingIdAction={settingBookingIdAction} /> : <></>
            }
        </div>
    )
}