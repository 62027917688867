import styled from "styled-components"
import logo from '../assets/images/logo.svg';

const LogoBox = styled.div`
display:flex

/* text / secondary */
justify-content:center;
align-items: center;
background: #FFFFFF;
border-radius: 17.6px;
object-fit: contains
`
export default function Logo(): React.ReactElement {
    return (
       <LogoBox>
            <img style ={{width: "130px", height: "65px", marginLeft:"10px", marginRight:"10px"}}src={logo} alt="Sanus logo" />
       </LogoBox>
    )
}