import { useState } from 'react';
import { RequestState, UserTypeList } from '../types';
import { useDispatch } from 'react-redux';
import { logIn, setGestorIsValid, setUserType } from '../redux/features/auth';

import setFromEvent from '../helpers/setFromEvent';
import { AxiosError, AxiosResponse } from 'axios';
import axios from "../commons/axios";

export type useLoginReturn = {
    type_login: string,
    setTypeLogin: (event: any) => void,
    setPassword: (event: any) => void,
    setEmail: (event: any) => void,
    setCpf: (event: any) => void,
    setPhoneNumber: (event: any) => void,
    connect: (event: any) => void,
    requestState: RequestState
}

export default function useLogin(): useLoginReturn {
    const [type_login, setTypeLogin] = useState<string>("email");
    const [email, setEmail] = useState<string>("");
    const [cpf, setCpf] = useState<string>("");
    const [phone_number, setPhoneNumber] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [requestState, setRequestState] = useState<RequestState>(RequestState.NotStarted);

    const dispatch = useDispatch();

    async function checkGerente() {
        try {
            let response = await axios.get("/api/gestor/get_gestor/", {})
            if (response.data.isValid) {
                dispatch(setGestorIsValid("true"))
                return true
            }
            else {
                dispatch(setGestorIsValid("false"))
                return false
            }
        } catch (e) {
            let response_post = await axios.post("/api/gestor/create_gestor/", {institution: ""})
            if (response_post.status !== 201)
                window.alert("Erro na criação do gestor, tente novamente mais tarde")
            else {
                if (response_post.data.isValid){
                    dispatch(setGestorIsValid("true"))
                    return true
                }
                else dispatch(setGestorIsValid("false"))
            }
            return false
        }
    }

    const url = `/auth/login/`
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    const connectUser = (event: any): void => {
        event.preventDefault();
        setRequestState(RequestState.Waiting);
        let payload: any = {
            password: password
        }

        if (type_login === "email")
            payload["email"] = email
        else if (type_login === "cpf")
            payload["cpf"] = cpf
        else if (type_login === "phone_number")
            payload["phone_number"] = phone_number

        axios.post(url, payload)
            .then((response: AxiosResponse) => response.data)
            .then(async (data: any) => {
                try {
                    let response = await axios.get("/api/users/me/", {})
                    if (response.status === 200) {
                        dispatch(setUserType(response.data.user_type));
                        if (response.data.user_type === UserTypeList.GERENTE) {
                            const isValidGerente = await checkGerente()
                            if (!isValidGerente) {
                                setRequestState(RequestState.GestorInvalid);
                            }
                            else {
                                dispatch(logIn());
                                setRequestState(RequestState.Successful);
                            }
                        }
                        else {
                            dispatch(logIn());
                        }
                    }
                } catch (e) {
                    console.log("error")
                }
            })
            .catch((error: AxiosError) => {
                switch (error.response!.status) {
                    case 400:
                        setRequestState(RequestState.UnableToLogIn)
                        break;
                    default:
                        setRequestState(RequestState.NotSuccessful);
                        console.error(error);
                        break;
                }
            })
    }

    return {
        type_login,
        setTypeLogin: setFromEvent(setTypeLogin),
        setPassword: setFromEvent(setPassword),
        setEmail: setFromEvent(setEmail),
        setCpf: setFromEvent(setCpf),
        setPhoneNumber: setFromEvent(setPhoneNumber),
        connect: connectUser,
        requestState: requestState,
    }
}
