import {useState} from 'react';
import {RequestState} from '../types';
import {useDispatch} from 'react-redux';
import {setExamToken} from '../redux/features/exam';

import setFromEvent from '../helpers/setFromEvent';
import {AxiosError, AxiosResponse} from 'axios';
import axios from "../commons/axios";

import requestExceptions, {RequestException} from '../constants/requestExceptions';

export type useExamTokenReturn = {
    idExam: number,
    setToken: (event: any) => void,
    setBirthday: (event: any) => void,
    connect: (event: any) => void,
    requestState: RequestState
}

export default function useExamToken(): useExamTokenReturn {
    const [idExam, setIdExam] = useState(0)
    const [token, setToken] = useState<string>("");
    const [birthday, setBirthday] = useState<string>("");
    const [requestState, setRequestState] = useState<RequestState>(RequestState.NotStarted);

    const dispatch = useDispatch();

    const url = `/api/booking-slots/get_book_slot_by_token/`
    axios.defaults.headers.post['Content-Type'] = 'application/json';

    const connectUser = (event: any): void => {
        event.preventDefault();
        setRequestState(RequestState.Waiting);
        let payload:any = {
            token: token,
            birthday: birthday
        }
        
        axios.get(url, {params: payload})
            .then((response: AxiosResponse) => response.data)
            .then((data: any) => {
                let action_payload = {
                    token: data.token,
                    birthday: payload.birthday
                }
                setIdExam(data.id)
                dispatch(setExamToken(action_payload))
                setRequestState(RequestState.Successful)
            })
            .catch((error: AxiosError) => {
                try{
                    const status = error.response!.status;
                    const responseData = error.response!.data;
                    requestExceptions[status].forEach(
                        (exception: RequestException) => {
                            exception.check(responseData, setRequestState);
                        }
                    );
                }
                catch {
                    setRequestState(RequestState.NotSuccessful);
                }
            })
    }

    return {
        idExam: idExam,
        setToken: setFromEvent(setToken),
        setBirthday: setFromEvent(setBirthday),
        connect: connectUser,
        requestState: requestState,
    }
}
