import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { RequestState } from "../types";
import useLogin from "../hooks/useLogin";
import useAuthorization from "../hooks/useAuthorization";
import { Loader } from "../components";

import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";

import { Radio } from "antd";
import { Background, Page, Header, Title, CenterDiv, Line, OtherWithLine, LeftDiv } from "../components/NewDesignCommons";
import Logo from '../components/Logo';

export default function LoginScreen(): React.ReactElement {
    const login = useLogin();
    const requestState = login.requestState;
    const navigator = useNavigate();
    const location = useLocation();
    const [passVisible, setPassVisible] = useState<boolean>(false);
    const togglePassVisible = () => setPassVisible(!passVisible);

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel efetuar seu login no momento.");
                break;
            case RequestState.UnableToLogIn:
                window.alert("Seu nome de usuário ou senha estão incorretos. Verifique e tente novamente.")
                break;
            case RequestState.GestorInvalid:
                window.alert("Seu cadastro como gestor ainda não foi validado. Entre em contato com a Sãnus para mais informações.")
                break;
            default: break;
        }
    }, [requestState])


    // checks user logged in
    const authorized = useAuthorization();
    const state: any = location.state || { redirect: undefined };
    const redirect = state.redirect;
    useEffect(() => {
        if (!authorized) return
        let redirectTime = localStorage.getItem("redirectTime")
        if(redirectTime === "true") {
            localStorage.removeItem("redirectTime")
            navigator("/chooseExam/time", {replace: true})
            return
        }
        if (!redirect) navigator("/", { replace: true });
        else navigator(redirect);
    }, [authorized])

    return (
        <Background>
            <Logo/>
            <Page style={window.innerWidth > 1000 ? {} : {}}>
                <Header>
                    <IoIosArrowBack onClick={() => navigator("/")} style={{ fontSize: '24px', color: '#212121', cursor: 'pointer' }} />
                    <Title>Entrar</Title>
                </Header>
                {/* <h1>Escolha a opção de Login:</h1> */}
                <Radio.Group onChange={login.setTypeLogin} defaultValue="email" style={{ textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <Radio.Button value="email" style={{ marginLeft: '5px', marginRight: '5px', borderRadius: '5px', borderColor: "#37E0B7" }}>Email</Radio.Button>
                    <Radio.Button value="cpf" style={{ marginLeft: '5px', marginRight: '5px' }}>CPF</Radio.Button>
                    <Radio.Button value="phone_number" style={{ marginLeft: '5px', marginRight: '5px' }}>Telefone</Radio.Button>
                </Radio.Group>

                <form className="flex flex-col" onSubmit={login.connect}>
                    {
                        login.type_login === "email" ?
                            <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                            :{ maxWidth: "280px", width: "100%", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                            } className={requestState === RequestState.UserNotFound ? "line-input line-input-activate" : "line-input"} type="email" placeholder="Email" required onChange={login.setEmail} />
                        : login.type_login === "cpf" ?
                            <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                            :{ maxWidth: "280px", width: "100%", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                            } className={requestState === RequestState.UserNotFound ? "line-input line-input-activate" : "line-input"} type="number" placeholder="CPF" pattern="[0-9]*" maxLength={11} required onChange={login.setCpf} />
                        : login.type_login === "phone_number" ?
                            <input style={window.innerWidth > 500 ? { width: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                            :{ maxWidth: "280px", width: "100%", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                            } className={requestState === RequestState.UserNotFound ? "line-input line-input-activate" : "line-input"} type="number" placeholder="Telefone" pattern="[0-9]*" maxLength={11} required onChange={login.setPhoneNumber} />
                        : <></>
                    }
                    {
                        requestState === RequestState.UserNotFound ?
                            <p className="text-sm text-red-600">Usuário não existe.</p> : <></>
                    }
                    <span className="flex w-full justify-between flex-row items-center">
                        <input style={window.innerWidth > 500 ? 
                        { maxWidth: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }
                        :{ maxWidth: "250px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px" }}
                        className={requestState === RequestState.PasswordIncorrect ? "line-input line-input-activate" : "line-input"} type={passVisible ? "text" : "password"} placeholder="Senha" required onChange={login.setPassword} />
                        {
                            passVisible ?
                                <FiEyeOff size={20} className="ml-4" onClick={togglePassVisible} /> :
                                <FiEye size={20} className="ml-4" onClick={togglePassVisible} />
                        }
                    </span>
                    {
                        requestState === RequestState.PasswordIncorrect ?
                            <p className="text-sm text-red-600">Senha incorreta.</p> : <></>
                    }
                    <LeftDiv style={window.innerWidth > 500 ? {width: "360px", marginLeft: "0px"} 
                            : {maxWidth: "280px", width:"100%", marginLeft: "0px"}} >
                        <Link to="/password" className="button-animation">Esqueceu sua senha?</Link>
                    </LeftDiv>
                    {
                        requestState === RequestState.Waiting ?
                            <Loader /> :
                            <input className="button-primary" style={window.innerWidth > 500 ? {width: "360px", marginLeft: "0px"} 
                            : {maxWidth: "280px", width:"100%", marginLeft: "0px"}} 
                            type="submit" value="Entrar" />
                    }
                </form>
                <OtherWithLine>
                    <Line />
                    <span>Ou</span>
                    <Line />

                </OtherWithLine>
                <CenterDiv>

                    <p>Ainda não é inscrito?&nbsp;&nbsp;</p><Link to="/signup" className="button-animation" style={{ color: "#37E0B7" }}>Inscreva-se</Link>
                </CenterDiv>
            </Page>
        </Background>
    )
}
