import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom"
import useAuthorization, { useGerenteInvalid } from "../../hooks/useAuthorization"
import { useDispatch } from "react-redux"
import { logOut } from "../../redux/features/auth";
import useRequest from '../../hooks/useRequest';
import { RequestState, RequestType } from "../../types";
import Loader from './../../components/Loader';
import EditableLine from "./EditableLine";
import useUserData from "src/hooks/useUserData";
import { resetLocation } from "src/redux/features/user";
import { useGerente } from "../../hooks/useAuthorization";
import setFromEvent from "src/helpers/setFromEvent";
import { FiEye, FiEyeOff } from "react-icons/fi";

export default function UserScreen(): React.ReactElement {
    const dispatcher = useDispatch();
    const navigator = useNavigate();
    const [userData, getUserDataRequestState] = useUserData();
    const isValidGerente = useGerente();
    const isInvalidGerente = useGerenteInvalid();

    const [old_password, setOldPassword] = useState<string>("");
    const [password1, setPassword1] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");

    const [passVisible_old, setPassVisibleOld] = useState<boolean>(false)
    const [passVisible_new_1, setPassVisibleNew1] = useState<boolean>(false)
    const [passVisible_new_2, setPassVisibleNew2] = useState<boolean>(false)

    const request = useRequest('auth/password/change/', () => { }, RequestType.Post);
    const requestState = request.requestState;

    const logOutRequest = useRequest("auth/logout/", () => { }, RequestType.Post);
    const logOutRequestState = logOutRequest.requestState;

    const handleLogout = (event: any) => {
        event.preventDefault();
        localStorage.clear();
        logOutRequest.call({});
        dispatcher(logOut());
        window.location.reload();
    }

    const handleLocation = () => {
        dispatcher(resetLocation());
        navigator("/chooseExam");
        window.location.reload();
    }

    const handleChangePassword = () => {
        dispatcher(resetLocation());
        navigator("/changepassword");
    }

    const handleSubmit = (event: any): void => {
        event.preventDefault();
        if (password1 !== password2) {
            window.alert("Suas duas senhas devem ser iguais!"); return
        }
        else if ((password1.length < 8) || (password1.match(/^[0-9]+$/) != null)) {
            window.alert("Sua senha deve ter no mínimo 8 caracteres e não pode ser completamente numérica"); return
        }
        request.call({
            old_password: old_password,
            new_password1: password1,
            new_password2: password2
        })
    }

    useEffect(() => {
        switch (logOutRequestState) {
            case RequestState.NotSuccessful:
                window.alert("Estamos com problemas no servidor, tente novamente mais tarde...");
                break;
            case RequestState.Successful:
                window.alert("Desconectado com sucesso!");
                break;
            default: break;
        }
    }, [logOutRequestState])

    useEffect(() => {
        switch (requestState) {
            case RequestState.Successful:
                window.alert("Senha alterada com sucesso!");
                localStorage.clear();
                dispatcher(resetLocation());
                window.location.reload();
                break;
            case RequestState.OldPasswordWrong:
                window.alert("Senha atual errada. Por favor digite a senha correta.");
                break;
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            default: break;
        }
    }, [requestState])

    if (!useAuthorization()) return <Navigate to="/login" replace />
    if (isInvalidGerente) return <></>
    if (isValidGerente) return (
        <div className="page">
            <h1>Página do Usuário</h1>
            <div className="flex flex-col">
                {
                    !userData ? <></> :
                        <>
                            {/* <EditableLine userId={userData.id} title="Nome de Usuário" propertyName="username" defaultValue={userData.username} editable={false} /> */}
                            <EditableLine userId={userData.id} title="Nome" propertyName="name" defaultValue={userData.name} />
                            <EditableLine userId={userData.id} title="Email" propertyName="email" defaultValue={userData.email} />
                        </>
                }
            </div>
            {
                getUserDataRequestState === RequestState.Successful ?
                    <div className="">
                    </div> : <></>
            }
            <button className="button-secondary button-animation" style={{ marginTop: '10px' }} onClick={handleChangePassword}>
                Alterar senha
            </button>
            <button className="button-primary button-animation" onClick={handleLogout}>
                Sair
            </button>
        </div>
    )

    if (getUserDataRequestState === RequestState.Waiting) return <Loader />
    return (
        <div className="page flex flex-col items-center" style={window.innerWidth > 1000 ? {marginTop: "-10px"} : {}}>
            {window.innerWidth > 1000 ? 
                <div style={{width: "100%", backgroundColor: "#37E0B733", height: "150px"}}></div>
            : <></>}
            <div className="flex flex-col" style={{maxWidth: "800px", width: "90%"}}>
                <div style={window.innerWidth > 1000 ? {marginTop: "-120px", alignSelf: "flex-start"} : {}}>
                    <div className="w-40 h-40 mx-auto shadow-xl my-10 flex flex-col rounded-full overflow-hidden" 
                        style={{justifyContent: "center", alignItems: "center", backgroundColor: "#37E0B7"}}
                    >
                        <img src={userData?.photo} alt="Foto do usuário" className="object-cover min-h-full min-w-full" />
                    </div>
                </div>
                {
                    !userData ? <></> :
                        <div style={window.innerWidth > 1000 ? {display: "grid", gridTemplateColumns: "auto auto", columnGap: "20px", rowGap: "20px"} : {}}>
                            {/* <EditableLine userId={userData.id} title="Nome de Usuário" propertyName="username" defaultValue={userData.username} editable={false} /> */}
                            <EditableLine userId={userData.id} title="Nome" propertyName="name" defaultValue={userData.name} />
                            <EditableLine userId={userData.id} title="Email" propertyName="email" defaultValue={userData.email} />
                            <EditableLine userId={userData.id} title="CPF" propertyName="cpf" defaultValue={userData.cpf} />
                            <EditableLine userId={userData.id} title="Telefone" propertyName="phone_number" defaultValue={userData.phone_number} />
                            <EditableLine userId={userData.id} title="Data de Nascimento" propertyName="birthday" type="date" defaultValue={userData.birthday} />
                        </div>
                }
                <div style={{alignSelf: "flex-start", marginTop: "40px"}}>
                    <h1 style={{ textAlign: "center" }}>Alterar senha</h1>
                </div>
                <form onSubmit={handleSubmit} className="flex flex-col" action="#">
                    <div style={window.innerWidth > 1000 ? {display: "grid", gridTemplateColumns: "auto auto", columnGap: "20px", rowGap: "20px"} : {}}>
                        <section className="flex flex-row items-center">
                            <input className='line-input' onChange={setFromEvent(setOldPassword)} type={passVisible_old ? "text" : "password"} placeholder='Senha atual' />
                            {
                                passVisible_old ?
                                    <FiEyeOff size={20} className="ml-4" onClick={() => setPassVisibleOld(!passVisible_old)} /> :
                                    <FiEye size={20} className="ml-4" onClick={() => setPassVisibleOld(!passVisible_old)} />
                            }
                        </section>
                        <section className="flex flex-col">
                            <section className="flex flex-row items-center">
                                <input className='line-input' onChange={setFromEvent(setPassword1)} type={passVisible_new_1 ? "text" : "password"} placeholder='Nova senha' />
                                {
                                    passVisible_new_1 ?
                                        <FiEyeOff size={20} className="ml-4" onClick={() => setPassVisibleNew1(!passVisible_new_1)} /> :
                                        <FiEye size={20} className="ml-4" onClick={() => setPassVisibleNew1(!passVisible_new_1)} />
                                }
                            </section>
                            {
                                requestState === RequestState.WeakPassword ?
                                    <p className='input-warning'>Esta senha é muito fraca ou comum. As senhas devem ter no mínimo 8 caracteres.</p> : <></>
                            }
                        </section>
                        <section className="flex flex-col">
                            <section className="flex flex-row items-center">
                                <input style={{ marginBottom: '40px' }} className='line-input' onChange={setFromEvent(setPassword2)} type={passVisible_new_2 ? "text" : "password"} placeholder='Repita sua nova senha' />
                                {
                                    passVisible_new_2 ?
                                        <FiEyeOff size={20} className="ml-4" onClick={() => setPassVisibleNew2(!passVisible_new_2)} /> :
                                        <FiEye size={20} className="ml-4" onClick={() => setPassVisibleNew2(!passVisible_new_2)} />
                                }
                            </section>
                            {
                                password1 !== password2 ?
                                    <p style={{ marginBottom: '10px' }} className='input-warning'>As duas senhas devem ser iguais!</p> : <></>
                            }
                        </section>
                    </div>
                    {
                        requestState === RequestState.Waiting ?
                            <h4 text-center><b>Clique no OK</b> quando a mensagem aparecer</h4> :
                            <div className="flex flex-col justify-center items-center"
                                style={window.innerWidth > 1000 ? { marginTop: '30px', width: "400px", alignSelf: "center"} : { marginTop: '30px', width: "260px", alignSelf: "center" }}>
                                <input type="submit" value="Alterar senha" className='button-primary button-animation w-full' style={window.innerWidth > 1000 ? { alignSelf: "center" } : { alignSelf: "center" }} />
                            </div>
                    }
                </form>
            </div>

            <button className="button-primary button-animation" style={window.innerWidth > 1000 ? { marginTop: '30px', width: "400px" } : { marginTop: '30px', width: "260px" }} onClick={handleLogout}>
                Sair
            </button>
        </div>
    )
}
