import monthNames from '../constants/monthNames';

export default class MyDate {
    private date: Date;
    private ISO!: string;
    private BR!: string;
    private hour!: string;
    private timezone: string;

    constructor(dateString?: string){
        this.timezone = "America/Sao_Paulo"
        if(!dateString)
            this.date = new Date();
        else
            this.date = new Date(dateString);
    }

    getValue (): number{
        return this.date.valueOf();
    }

    getUserFormated (): string{
        const relativeDay: string = 
            this.isToday() ? "Hoje ":
            this.isTomorrow() ? "Amanhã ": ""

        const dateString: string = this.date.toLocaleDateString('pt-BR', {timeZone: this.timezone})
        const [givenDayInt, givenMonthInt, _ ] = dateString.split('/').map(d => parseInt(d));
        const givenMonthString: string = monthNames[givenMonthInt - 1];
        const givenWeekDayString: string = this.date.toLocaleDateString('pt-BR', {timeZone: this.timezone, weekday: 'long'}).toUpperCase();

        return `${relativeDay}(${givenWeekDayString}, ${givenDayInt} DE ${givenMonthString})`
    }

    getBR(): string {
        return this.date.toLocaleDateString('pt-BR', {timeZone: this.timezone});
    }

    getHour(): string{
        return this.date.toLocaleString('pt-BR', {timeStyle: "short",  timeZone: this.timezone});
    }

    static compareDates(a: MyDate, b: MyDate): number {
        return (a.getValue() >= b.getValue()) ? 1 : -1;
    }
    static compareBRDates(a: string, b: string): number {
        return this.compareDates(new MyDate(a), new MyDate(b));
    }

    private isToday (): boolean {
        const today = new MyDate();
        return today.getBR() === this.getBR();
    }
    
    private isTomorrow (): boolean {
        const today = new MyDate();
        const todayString: string[] = today.getBR().split('/');
        return this.getBR() === `${parseInt(todayString[0]) + 1}/${todayString[1]}/${todayString[2]}`
    }
}