export default function Error404Screen(): React.ReactElement {
    return (
        <div>
            <h1>
                Não conseguimos achar o que você estava procurando...
            </h1>
            <p>
                Certifique-se que o endereço que você digitou está correto!
            </p>
        </div>
    )

}