import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../commons/axios'
import AlertDialog from 'src/components/Popup';
import DatePicker, { DateObject, } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';

const OverrideBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
`


export default function AddBookingSlot(): React.ReactElement {

    let { labExamId } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if(labExamId === undefined) {
            const labExamIdLocalStorage = localStorage.getItem("labExamId");
            if (!labExamIdLocalStorage)
                navigate("/");
            else{
                labExamId = labExamIdLocalStorage
            }
        }
    }, [])

    const [openPopup, setOpenPopup] = useState(false)
    const [duration, setDuration] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const [hour, setHour] = useState<DateObject>();
    const [override, setOverride] = useState(false);
    const [dates, setDates] = useState<DateObject[]>([])

    const handleSubmit = async () => {
        await handleBook()
    }

    const handleBook = async () => {
        if (labExamId !== undefined && price !== 0 && dates.length > 0 && duration !== undefined) {
            let dateFormat = "YYYY-MM-DDTHH:mm:ss.000-03:00"
            console.log(dates.map(date => date.format(dateFormat)))
            if (override && hour) {
                dateFormat = `YYYY-MM-DDT${hour.format("HH:mm:ss")}.000-03:00`
            }
            let response = await axios.post("/api/booking-slots/create_multiples_book_slots/", {
                dates_list: dates.map(date => date.format(dateFormat)),
                lab_exam: labExamId,
                price: price,
                duration: duration,
            })
            if (response.status === 200 || response.status === 201) {
                window.alert("Disponibilizado com sucesso.")
                navigate(-1)
            }
        } else {
            window.alert("Erro no sistema, tente novamente mais tarde")
            navigate(-1)
        }
    }


    return (
        <div className="page">
            {
                openPopup ?
                    <AlertDialog
                        open={openPopup}
                        setOpen={setOpenPopup}
                        handleAgree={async () => handleSubmit()}
                        handleDisagree={() => { }}
                        title={"Deseja disponibilizar esses horários?"}
                        message={"A reserva para o dia e horário" + dates.map(date => date.format("DD-MMYYYYTHH:mm")) + " será disponibilizada."}
                    /> : <></>
            }
            <div className="flex flex-col align-center justify-center">
                <div className={window.innerWidth > 400 ? "flex flex-row" : "flex flex-col"} style={{ alignSelf: "center", justifyContent: "center" }}>
                    <section className={window.innerWidth > 400 ? "mr-5" : ""} >
                        <DatePicker
                            placeholder='Escolha as datas e o horários desejados                            '
                            multiple
                            sort
                            value={dates}
                            onChange={setDates}
                            format="DD/MM/YYYY HH:mm"
                            plugins={[
                                <DatePanel />,
                                <TimePicker position="bottom" value={dates[0]} hideSeconds={true} />
                            ]}
                            style={{
                                border: "1px #0c8af8 solid",
                                display: "flex", width: "720px", height: "40px", marginBottom: "20px", margin: "auto", alignContent: "center", textAlign: "center", justifyContent: "center", alignItems: "center"
                            }}
                        />
                        {
                            dates.length === 0 ?
                                <p className="input-warning">Selecione as datas e o horário desejado</p> : <></>
                        }
                        <OverrideBox>
                            <FormControlLabel control={<Checkbox value={override} onChange={(e) => setOverride(e)} />} label='Marque essa opção e preencha um horário ao lado se você quiser um horário fixo para todas as datas selecionadas acima'
                            />

                            <DatePicker
                                disableDayPicker
                                format="HH:mm"
                                value={hour}
                                onChange={setHour}
                                plugins={[
                                    <TimePicker hideSeconds={true} />
                                ]}

                                style={{
                                    border: "1px #0c8af8 solid",
                                    display: "flex", width: "100px", height: "40px", margin: "auto", alignContent: "center", textAlign: "center"
                                }}
                            />
                        </OverrideBox>


                        <input className='line-input' placeholder="Duração (em minutos)" min={0} onChange={e => setDuration(parseInt(e.target.value))} type="number" />
                        <input className='line-input' placeholder="Preço (28.90, por exemplo)" min={0} onChange={e => setPrice(parseFloat(e.target.value))} type="number" />

                    </section>

                </div>
                <button className="button-primary" style={window.innerWidth > 400 ? { width: "70%", alignSelf: "center" } : { alignSelf: "center" }} onClick={handleBook}>Disponibilizar Horário</button>

            </div>
        </div>
    )
}