import { createSlice } from '@reduxjs/toolkit';

export const examSlice = createSlice({
    name: "exam",
    initialState: {
        examId: localStorage.getItem("examId"),
        filterId: localStorage.getItem("filterId"),
        labExamId: localStorage.getItem("labExamId"),
        demandDate: localStorage.getItem("demandDate"),
        demandTime: localStorage.getItem("demandTime"),
        labName: localStorage.getItem("labName"),
        examName: localStorage.getItem("examName"),
        labPhone: localStorage.getItem("labPhone"),
        bookingId: localStorage.getItem("bookingId"),
        labAddress: localStorage.getItem("labAddress"),
        token: localStorage.getItem("token"),
        birthday: localStorage.getItem("birthday")
    },
    reducers: {
        setExamId: (state, action) => {
            state.examId = action.payload
            localStorage.setItem("examId", action.payload);
        },
        setFilterId: (state, action) => {
            state.filterId = action.payload
            localStorage.setItem("filterId", action.payload);
        },
        setLabExamId: (state, action) => {
            state.labExamId= action.payload
            localStorage.setItem("labExamId", action.payload);
        },
        setBookingId: (state, action) => {
            state.bookingId = action.payload
            localStorage.setItem("bookingId", action.payload);
        },
        setDemandDate: (state, action) => {
            state.demandDate = action.payload
            localStorage.setItem("demandDate", action.payload);
        },
        setDemandTime: (state, action) => {
            state.demandTime= action.payload
            localStorage.setItem("demandTime", action.payload);
        },
        setLabName: (state, action) => {
            state.labName = action.payload
            localStorage.setItem("labName", action.payload);
        },
        setExamName: (state, action) => {
            state.examName = action.payload
            localStorage.setItem("examName", action.payload);
        },
        setLabPhone: (state, action) => {
            state.labPhone = action.payload
            localStorage.setItem("labPhone", action.payload);
        },
        setLabAddress: (state, action) => {
            state.labAddress = action.payload;
            localStorage.setItem("labAddress", action.payload);
        },
        cleanExamState : (state) => {
            localStorage.removeItem("examId");
            localStorage.removeItem("filterId");
            localStorage.removeItem("labExamId");
            localStorage.removeItem("demandDate");
            localStorage.removeItem("demandTime");
            localStorage.removeItem("labName");
            localStorage.removeItem("examName");
            localStorage.removeItem("labPhone");
            localStorage.removeItem("bookingId");
            localStorage.removeItem("labAddress");
        },
        setExamToken: (state, action) => {
            state.token = action.payload.token
            state.birthday = action.payload.birthday
            localStorage.setItem("token", action.payload.token)
            localStorage.setItem("birthday", action.payload.birthday)
        }

    }
})

export const {
    setExamId,
    setFilterId,
    setLabExamId,
    setBookingId,
    setDemandDate,
    setDemandTime,
    setLabName,
    setExamName,
    setLabPhone,
    cleanExamState,
    setLabAddress,
    setExamToken
} = examSlice.actions;

export default examSlice.reducer
