import styled from "styled-components";


export const Background = styled.div`
    background-color: #37E0B7;
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
    padding-left: 7px;
    padding-right: 7px;
    padding-bottom: 20px;
    
`;

export const Page = styled.div`

    /* Auto layout */

display: flex;
flex-direction: column;
align-items: center;
margin-top: 20px;
max-width: 579px;
width: 100%;
max-height: 720px;
height: 100%
padding-left: 15px;
padding-right: 15px;
padding-bottom: 50px;

/* text / secondary */

background: #FFFFFF;
/* shadow - */

box-shadow: 0px 4px 4px rgba(4, 16, 45, 0.15), 0px 24px 32px -3px rgba(3, 9, 50, 0.04);
border-radius: 12px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
`

export const Header = styled.div`
    /* Auto layout */
    display: flex;
    max-width: 415px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
/* identical to box height */


/* text / primary */

color: #212121;
    `
export const Title = styled.h1`
width: 100%;
margin-left: 10px
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
`


export const CenterDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
font-size: 14px;
line-height: 17px;
/* identical to box height */
text-align: center;
width: 100%;


/* text / primary */

color: #212121;`

export const Line = styled.div`
width: 45%;
height: 0px;

/* Border */

border: 1px solid #F5F5F5;
`

export const OtherWithLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 12px;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
/* identical to box height */


/* text/tertiary */

color: #808080;

    `
export const LeftDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
margin-top: 10px;
margin-bottom: 10px;
/* identical to box height */

text-align: right;
text-decoration-line: underline;

/* text / primary */

color: #212121;
    `