import { BookingSlot, BookingSlotLabExam, BookingSlotLabExamUser, BookingSlotStatus, Exam, Gestor, Lab, LabExam, Permissions, UserData } from "src/types"

export const emptyPermissions: Permissions = {
    is_staff: false,
    is_lab_admin: false,
    moderation_lab: 0
}

export const emptyUserData: UserData = {
    id: 0,
    name: "",
    photo: "",
    email: "",
    username: "",
    birthday: "",
    cpf: "",
    user_type: "",
    phone_number: "",
    permissions: emptyPermissions
}

export const emptyGestor: Gestor = {
    id: 0,
    user: emptyUserData,
    isValid: false
}

export const emptyLab: Lab = {
    id: 0,
    gestor: emptyGestor,
    razao_social: "",
    cnpj: "",
    email: "",
    description: "",
    address: "",
    latitude: 0,
    longitude: 0,
    telefone: "",
    cep: ""
}

export const emptyListLab: Lab[] = [emptyLab]

export const emptyExam: Exam = {
    id: 0,
    name: "",
    description: ""
}

export const emptyListExam: Exam[] = [emptyExam]

export const emptyBookingSlot: BookingSlot = {
    id: 0,
    status: BookingSlotStatus.free,
    duration: 0,
    price: "0.00",
    date_time_from: "",
    date_time_to: "",
    lab_exam: 1 ,
    token: ""
}

export const emptyBookingSlotList:BookingSlot[] = [emptyBookingSlot]

export const emptyLabExam: LabExam = {
    id: 0,
    lab: emptyLab,
    exam: emptyExam,
    booking_slots: emptyBookingSlotList,
    min_price: 0,
    max_price: 0
}

export const emptyListLabExam: LabExam[] = [emptyLabExam]

export const emptyBookingSlotLabExam: BookingSlotLabExam = {
    id: 0,
    status: BookingSlotStatus.free,
    duration: 0,
    price: "",
    date_time_from: "",
    date_time_to: "",
    lab_exam: emptyLabExam,
    token: "",
}

export const emptyScheduleBookingSlotLabExam: BookingSlotLabExam[]  = [emptyBookingSlotLabExam]

export const emptyBookingSlotLabExamUser: BookingSlotLabExamUser = {
    id: 0,
    user: emptyUserData,
    status: BookingSlotStatus.free,
    duration: 0,
    price: "",
    date_time_from: "",
    date_time_to: "",
    lab_exam: emptyLabExam,
    token: "",
}

export const emptyListBookingSlotLabExamUser: BookingSlotLabExamUser[] = [emptyBookingSlotLabExamUser]