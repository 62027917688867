import React from 'react';
import { Navigation, NavigationBar } from "./navigation";

function App() {
  // Function mainly to specify the directives of the site
  return (
    <div className="h-screen w-screen bg-white flex flex-col overflow-x-hidden overflow-y-visible" style={{backgroundColor: "#f9fbfc"}}>
      <NavigationBar/>
      <div className="mx-auto" style={{maxWidth: "2000px", width: "100%"}}>
        <Navigation/>
      </div>
    </div>
  )
}

export default App;
