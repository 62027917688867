import { useEffect, useState } from 'react'
import { MdCancel, MdKeyboardArrowRight } from "react-icons/md"
import { Navigate, useNavigate } from 'react-router-dom';
import { getTimestamp } from 'src/helpers/getTimestamp';
import useRequest from 'src/hooks/useRequest';
import { BookingSlotLabExam, BookingSlotStatus, RequestState, RequestType } from '../types'
import iconMyExam from '../assets/icons/iconMyExam.svg'

type CardExamProps = {
    booking_slot: BookingSlotLabExam,
    redirect: string,
    action?: () => void,
}

export default function CardExam({ booking_slot, redirect, action = () => { } }: CardExamProps): React.ReactElement {
    const navigate = useNavigate()
    const [active, setActive] = useState<Boolean>(false);
    const [hasCanceled, setHasCanceled] = useState<Boolean>(false);
    const cancelRequest = useRequest(`api/booking-slots/${booking_slot.id}/book/cancel/`, () => { }, RequestType.Post);

    const handleClick = (): void => {
        action();
        setActive(true);
    }
    const handleCancel = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (window.confirm('Você tem certeza que quer cancelar esse exame?')) {
            if (!hasCanceled) {
                setHasCanceled(true);
                cancelRequest.call({});
            }
        } else {
            console.log('O exame não foi cancelado.');
        }

    }

    useEffect(() => {
        switch (cancelRequest.requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi cancelar esse exame no momento. Tente novamente mais tarde.");
                break;
            case RequestState.NotFound:
                window.alert("O exame desejado não existe.");
                break;
            case RequestState.Successful:
                window.alert("Exame cancelado com sucesso!");
                break;
            case RequestState.BookingNotAvailable:
                window.alert("Este exame não está aguardando confirmação ou cancelamento.");
                break;
            case RequestState.NotAuthorized:
                window.alert("Somente administradores de laboratórios podem alterar estados de exames.");
                navigate('/accessDenied')
                break;
            default: break;
        }
    }, [cancelRequest.requestState])

    async function redirectToWPP(e:any){
        e.preventDefault()
        if (booking_slot.lab_exam.lab.telefone === null) {
            window.alert("Infelizmente não foi possível achar o número de telefone deste laboratório. Tente contactá-los pelo e-mail.");
            return;
        };
        const text = `Olá!`;
        window.open(`https://wa.me/+55${booking_slot.lab_exam.lab.telefone}?text=${encodeURI(text)}`, "_blank");
    }

    if (redirect && active) return <Navigate to={redirect} />
    return (
        <div className={window.innerWidth > 500 ? "px-8 py-6 flex flex-row items-center justify-between" : "px-8 py-6 flex flex-col items-center justify-between"} style={{ margin: 'auto', marginBottom: '30px', maxWidth: '610px', width: "100%", backgroundColor: "#fff", border: "1px solid #f5f5f5", borderRadius: "14px" }}>
            <section className={"flex flex-col items-start justify-between"} style={{height: "180px"}}>
                <section className="flex flex-row justify-start items-center gap-3">
                    <img src={iconMyExam}/>
                    <section>
                        <p style={{fontSize: "18px", fontWeight: "600"}}>{booking_slot.lab_exam.exam.name}</p>
                        <p style={{fontSize: "12px", fontWeight: "400"}}>Clínica {booking_slot.lab_exam.lab.razao_social}</p>
                    </section>
                </section>
                <section style={{display: "grid", gridTemplateColumns: "auto auto", columnGap: "20px"}}>
                    <p style={{fontSize: "12px", fontWeight: "500"}}>Data: {booking_slot.date_time_from.slice(0, 10)}</p>
                    <p style={{fontSize: "12px", fontWeight: "500"}}>Duração: {booking_slot.duration}</p>
                    <p style={{fontSize: "12px", fontWeight: "500"}}>Hora: {booking_slot.date_time_from.slice(11, 16)}</p>
                    <p style={{fontSize: "12px", fontWeight: "500"}}>Status: {booking_slot.status === BookingSlotStatus.inProgress ? "Em progresso" : booking_slot.status === BookingSlotStatus.booked ? "Confirmado" : "Livre"}</p>
                    <p style={{fontSize: "12px", fontWeight: "500"}}>Token: {booking_slot.token}</p>
                </section>
                <section>
                    <p style={{fontSize: "14px", fontWeight: "600"}}>Laboratório</p>
                    <p style={{fontSize: "12px", fontWeight: "500"}}>Endereço: {booking_slot.lab_exam.lab.address}</p>
                    <p style={{fontSize: "12px", fontWeight: "500"}}>Telefone: {booking_slot.lab_exam.lab.telefone}</p>
                </section>
            </section>
            <section className={window.innerWidth > 500 ? "flex flex-col items-center justify-between" : "flex flex-col items-center gap-4 mt-5"} style={window.innerWidth > 500 ? {height: "200px"} : {}}>
                <p className="justify-self-start" style={{color: "#37e0b7", fontSize: "18px", fontWeight: "700"}}>R$ {booking_slot.price}</p>
                <button onClick={redirectToWPP} className="px-4 py-2" style={{backgroundColor: "#37e0b7", borderRadius: "5px"}}>Entrar em contato</button>
            </section>
        </div>
    )
}