import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { LatLong, RequestState, RequestType } from '../../types';
import { Loader } from '../../components';
import useAuthorization from "../../hooks/useAuthorization"
import textFromInputEvent from 'src/helpers/textFromInputEvent';
import cleanCep from '../../helpers/cleanCep';
import axios from '../../commons/axios'
import { isInvalidEmail } from 'src/helpers/validator';

type newLab = {
    razao_social: string,
    cnpj: string,
    email: string,
    description: string,
    address: string,
    telefone: string,
    cep: string
}

const dummyLatLong: LatLong = {
    lat: 1,
    lng: 1
}

export default function AddLab(): React.ReactElement {
    const navigate = useNavigate()

    const [labData, setLabData] = useState<newLab>({
        razao_social: "",
        cnpj: "",
        email: "",
        description: "",
        address: "",
        telefone: "",
        cep: ""
    })

    const request = useRequest('api/labs/create_lab/', () => { }, RequestType.Post);
    const requestState = request.requestState;

    const signUpDataSetter = (data: any) => {
        const { razao_social, cnpj, email, description, address, telefone, cep } = data
        const newData = {
            razao_social: razao_social || labData.razao_social,
            cnpj: cnpj || labData.cnpj,
            email: email || labData.email,
            description: description || labData.description,
            address: address || labData.address,
            telefone: telefone || labData.telefone,
            cep: cep || labData.cep,
        }
        setLabData(newData);
    }

    const handleInput = (key: string | number) => (event: any) => signUpDataSetter({ [key]: (textFromInputEvent(event)) })
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (!labData.razao_social) {
            window.alert("Nome não pode ser nulo"); return
        }
        if (labData.cnpj.length !== 14) {
            window.alert("CNPJ não pode ser nulo e deve conter apenas números"); return
        }
        if (isInvalidEmail(labData.email)) {
            window.alert("Insira um e-mail válido!"); return
        }
        if (!labData.description) {
            window.alert("Campo descrição não pode ser nulo"); return
        }
        if (!labData.address) {
            window.alert("Campo endereço não pode ser nulo"); return
        }
        if (!labData.telefone) {
            window.alert("Campo telefone não pode ser nulo"); return
        }
        if (!labData.description) {
            window.alert("Campo descrição não pode ser nulo"); return
        }
        if (labData.cep.length !== 8) {
            window.alert("Campo CEP deve ser válido"); return
        }
        if (!labData.cep) {
            window.alert("Campo CEP Não pode ser nulo"); return
        } else {
            let cepCleaned = cleanCep(labData.cep)
            let response = await axios.get("/api/get-geolocation", { params: { cep: cepCleaned } })
            console.log("\n\naqui no cep temos:", response)
            if (response.status === 200 || response.status === 201) {
                request.call({
                    razao_social: labData.razao_social,
                    cnpj: labData.cnpj,
                    email: labData.email,
                    description: labData.description,
                    address: labData.address,
                    image: "",
                    telefone: labData.telefone,
                    cep: labData.cep,
                    latitude: response.data.lat,
                    longitude: response.data.lng,
                });
            } else
                window.alert("Não foi possível processar o cep")
        }
    }

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            case RequestState.Successful:
                window.alert("Laboratório criado com sucesso")
                navigate("/myLabs")
                break;
            case RequestState.NotAuthorized:
                window.alert("Operação não autorizada")
                navigate("/chooseExam")
                break;
            case RequestState.MissingData:
                window.alert("Os campos obrigatórios devem ser preenchidos")
                break;
            case RequestState.UserAlready:
                window.alert("Usuário já possui essa atribuição")
                break;
            case RequestState.GestorInvalid:
                window.alert("Usuário não possui permissão de gestor")
                break;
            case RequestState.GestorNotIdentified:
                window.alert("Usuário não identificado como gestor")
                break;
            default: break;
        }
    }, [requestState])

    if (!useAuthorization()) return <Navigate to="/" replace />

    return (
        <div className="page" style={{ marginBottom: "40px" }}>
            <h1>Adicione um novo laboratório</h1>
            <form className="flex flex-col" onSubmit={handleSubmit}>
                <input className='line-input' onChange={handleInput("razao_social")} type="text" placeholder="Razão Social" />

                <input className='line-input' onChange={handleInput("cnpj")} type="number" placeholder="CNPJ (somente números)" maxLength={14} minLength={14} />

                <input className='line-input' onChange={handleInput("email")} type="text" placeholder="Email" />
                {
                    requestState === RequestState.EmailAlreadyInUse ?
                        <p className='input-warning'>Este e-mail já está cadastrado.</p> : <></>
                }

                <input className='line-input' onChange={handleInput("description")} type="text" placeholder="Descrição" />

                <input className='line-input' onChange={handleInput("address")} type="text" placeholder="Endereço" />

                <input className='line-input' onChange={handleInput("telefone")} type="text" placeholder="Telefone com DDD" />
                {
                    (labData.telefone.length !== 10 && labData.telefone.length !== 11) ?
                        <p className='input-warning'>Telefone inválido</p> : <></>
                }
                <input className='line-input' onChange={handleInput("cep")} type="text" placeholder="CEP" />
                {
                    labData.cep.length !== 8 ?
                        <p className='input-warning'>CEP inválido.</p> : <></>
                }
                {
                    requestState === RequestState.Waiting ?
                        <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> :
                        <input className='button-primary' type="submit" value="Adicionar" />
                }
            </form>
        </div>
    )
}