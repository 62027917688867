import RequestException, {
    EmailAlreadyInUse,
    CPFAlreadyInUse,
    CPFInvalid,
    EmailInvalid,
    UnableToChangeProperty,
    WeakPassword,
    BookingNotAvailable,
    NoBookingsFound,
    NotAuthorized,
    DefaultException,
    NotConnected,
    BookingAlreadyBooked,
    NotFound,
    OldPasswordWrong,
    MissingData,
    InvalidToken,
    BirthdayIncorrect,
    GestorInvalid,
    GestorNotIdentified,
    UserAlreadyFuncionario,
    UserAlreadyGerente,
} from "./RequestException";

const requestExceptions: { [HTTPCode: number]: RequestException[] } = {
    400: [
        new EmailAlreadyInUse(),
        new CPFAlreadyInUse(),
        new WeakPassword(),
        new UnableToChangeProperty(),
        new EmailInvalid(),
        new CPFInvalid(),
        new NoBookingsFound(),
        new BookingNotAvailable(),
        new BookingAlreadyBooked(),
        new OldPasswordWrong(),
        new MissingData(),
        new InvalidToken(),
        new BirthdayIncorrect(),
        new GestorNotIdentified(),
        new UserAlreadyFuncionario(),
        new UserAlreadyGerente(),
    ],
    401: [
        new NotAuthorized(),
        new GestorInvalid(),
    ],
    403: [
        new NotConnected(),
    ],
    404: [
        new NotFound()
    ],
}

export default requestExceptions;
export { RequestException };