import { useEffect, useState } from "react";
import { BookingSlotLabExamUser, BookingSlotStatus, LabExam, RequestState, RequestType } from "../../types";
import useRequest from '../../hooks/useRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { useFuncionario, useGerente } from '../../hooks/useAuthorization';
import { Loader } from "src/components";
import { emptyLabExam, emptyListBookingSlotLabExamUser, emptyListLabExam } from "src/constants/emptyTypes";
import CardBookSlot from "src/components/CardBookSlot";
import { Radio } from "antd";
import CardLabExam from "src/components/CardLabExam";

export default function ListLabExam(): React.ReactElement {
    const params = useParams()

    const navigate = useNavigate();
    const isGerente = useGerente();
    const isFuncionario = useFuncionario();

    const [listLabExam, setListLabExam] = useState<LabExam[]>(emptyListLabExam)

    useEffect(() => {
        if(!isGerente)
            if(!isFuncionario)
                navigate("../accessDenied");
    }, []);

    const getListGestor = useRequest(`api/lab-exams/get_lab_exams_by_gestor/`, setListLabExam, RequestType.Get);
    const getListFuncionario = useRequest(`api/lab-exams/get_lab_exams_by_funcionario/`, setListLabExam, RequestType.Get);

    useEffect(() => {
        if(params.labId !== undefined){
            let labId = params.labId
            getListGestor.call({
                lab_id: labId
            })
        }else{
            const labIdLocalStorage = localStorage.getItem("labId");
            if (!labIdLocalStorage)
                navigate("/");
            else{
                getListGestor.call({
                    lab_id: labIdLocalStorage
                })
            }
            getListFuncionario.call({})
        }
    }, [])

    useEffect(() => {
        switch(getListGestor.requestState){
            case RequestState.NotSuccessful:
                window.alert("Não foi possível carregar as reservas. Tente novamente mais tarde");
                navigate("/")
                break;
            default: break;
        }
    }, [getListGestor.requestState])

    useEffect(() => {
        switch(getListFuncionario.requestState){
            case RequestState.NotSuccessful:
                window.alert("Não foi possível carregar as reservas. Tente novamente mais tarde");
                navigate("/")
                break;
            default: break;
        }
    }, [getListFuncionario.requestState])

    return (
        <div className="page">
            <h1>Lista de Exames:</h1>
            {
                listLabExam.length === 0 ?
                <p className='mx-4 my-2'>Não há nenhum exame na clinica.</p> :
                <div>
                    {
                        getListGestor.requestState === RequestState.Waiting || getListFuncionario.requestState === RequestState.Waiting ?
                        <Loader /> :
                        <div className="mt-5">
                            {
                                listLabExam.map((labExam:LabExam) => (
                                    <CardLabExam
                                        lab_exam={labExam}
                                        action={() => localStorage.setItem("labExamId", labExam.id.toString())}
                                        redirect={`/makeAppointment/labExamId`}
                                    />
                                ))
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
}