import { useState } from 'react'
import { Navigate } from 'react-router-dom';

type CardButtonProps = {
    icon: any,
    title: string,
    subtitle: string,
    additionalText?: string|null,
    redirect?: string,
    action?: () => void,
}

export default function CardButton({title, subtitle, additionalText = null, icon, action = () => {}, redirect = ""}: CardButtonProps): React.ReactElement{
    const [active, setActive] = useState<Boolean>(false);
    const handleClick = (): void => {
        action();
        setActive(true);
    }

    if(redirect && active) return <Navigate to={redirect} />
    return (
        <div className="py-6 px-16" onClick={handleClick} style={window.innerWidth > 1000 ? {alignContent: "start", height: "200px", maxWidth: "285px", width:"100%"} : {alignContent: "center", height: "200px", maxWidth: "300px", width:"100%"}}>
            <img src={icon} />
            <p style={{marginTop: "30px", fontSize: "16px", fontWeight: "400", lineHeight: "24px"}}>{title}</p>
            <p style={{fontSize: "12px", fontWeight: "400", lineHeight: "24px"}}>{subtitle}</p>
            {
                additionalText === null?<></>:
                <p className=" text-gray-dark text-sm">{additionalText}</p>
            }
        </div>
    )
}