import { useEffect, useState } from "react";
import cleanCep from "src/helpers/cleanCep";
import DeleteButton from "./DeleteButtons";
import EditableLineInput from "./EditableLineInput";
import axios from '../../commons/axios'
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import useRequest from "src/hooks/useRequest";
import { RequestState, RequestType } from "src/types";
import { useNavigate } from "react-router";

type EditDataProps = {
    title: string,
    listParams: any,
    entity: string,
    entityId: string
}

export default function EditDataLab({ title, listParams, entity, entityId }: EditDataProps): React.ReactElement {

    const [cep, setCep] = useState("")
    const [edit, setEdit] = useState<boolean>(false)

    const navigate = useNavigate()

    const editCepRequest = useRequest(`api/${entity}/${entityId}/`, () => { }, RequestType.Patch);
    const requestState = editCepRequest.requestState;

    const handleEditCep = async(event:any) => {
        if (cep.length !== 8) {
            window.alert("Campo CEP deve conter 8 dígitos"); return
        }
        if (!cep) {
            window.alert("Campo CEP Não pode ser nulo"); return
        } else {
            let cepCleaned = cleanCep(cep)
            let response = await axios.get("/api/get-geolocation", { params: { cep: cepCleaned } })
            console.log("\n\naqui no cep temos:", response)
            if (response.status === 200 || response.status === 201) {
                console.log("editando lat e long")
                editCepRequest.call({
                    cep: cepCleaned,
                    latitude: response.data.lat,
                    longitude: response.data.lng
                })
            } else
                window.alert("Não foi possível processar o cep")
        }
    }

    useEffect(() => {
        switch(requestState){
            case RequestState.Successful:
                alert("Editado com sucesso!")
                navigate(-1)
                break;
            case RequestState.NotSuccessful:
                alert("Não foi possível alterar o cep no momento, tente novamente mais tarde")
                break;
            default:
                break;
        }
    }, [requestState])
    console.log("listParams:", listParams)
    return (
        <div className="page">
            <h1>{title}</h1>

            <div className="flex flex-col">
                {
                    Object.keys(listParams).length == 0 ? <></> :
                        <>
                            {
                                Object.keys(listParams).map((item) => {
                                    if(item !== "cep")
                                        return(
                                            <EditableLineInput
                                                title={item}
                                                defaultValue={listParams[item]}
                                                propertyName={item}
                                                entity={entity}
                                                entityId={entityId}
                                            />
                                        )
                                    return <></>
                                })
                            }
                        </>
                }
                <div className='w-full my-2'>
                    <p className='mx-2'>{"CEP"}:</p>
                    <form action="#" onSubmit={handleEditCep} className='w-full flex flex-row justify-around items-center'>
                        <input className={
                            edit ? 'line-input line-input-activate w-max' : 'line-input w-max text-gray-dark'
                        } onChange={e => setCep(e.target.value)} type="number" disabled={!edit} placeholder={listParams["cep"]} value={cep} />
                        {
                            edit ?
                            <span className='flex flex-row ml-2'>
                                <AiOutlineCheck size={20} onClick={handleEditCep} className="mx-1 button-animation" />
                                <AiOutlineClose size={20} onClick={e => setEdit(false)} className="mx-1 button-animation" />
                            </span> :
                                <BiPencil size={20} onClick={e => setEdit(true)} className="mx-1 button-animation" />
                        }
                    </form>
                </div>
            </div>
            <DeleteButton entity={entity} entityId={entityId} name={"clínica"}/>
        </div>
    )
}
