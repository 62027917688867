import { useState } from 'react'
import { MdKeyboardArrowRight } from "react-icons/md"
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';


type CardClinicProps = {
    razao_social: string,
    email: string,
    address: string,
    redirect: string,
    action?: () => void,
}

export default function CardClinic({ razao_social, email, address, redirect, action = () => { } }: CardClinicProps): React.ReactElement {
    const [active, setActive] = useState<Boolean>(false);
    const handleClick = (): void => {
        action();
        setActive(true);
    }

    if (redirect && active) return <Navigate to={redirect} />
    return (
        <div onClick={handleClick} className="card" style={{ margin: 'auto', marginBottom: '30px', width: '90%' }}>
            {
                window.innerWidth > 400 ?
                    <div className="my-auto p-2" >
                        { }
                    </div> : <></>
            }
            <div className="flex flex-col justify-center w-full ml-8">
                <p className="font-semibold">{razao_social}</p>
                <p className="text-gray-dark text-sm">{email}</p>
                <p className="text-gray-dark text-sm">{address}</p>
            </div>
            {
                <div className="my-auto py-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <MdKeyboardArrowRight size={24} style={{ marginBottom: '10px' }} />
                </div>
            }
        </div>
    )
}