import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type PopupProps = {
    open: boolean,
    setOpen: (status:boolean) => void,
    handleClickOpen?: () => void,
    handleClose?: () => void,
    handleAgree: () => void,
    handleDisagree: () => void,
    title: string,
    message: string,
}

export default function AlertDialog({open, setOpen = () => {}, handleClickOpen = () => {}, handleClose = () => {}, handleAgree = () => {}, handleDisagree = () => {}, title, message}: PopupProps) {

    return (
        <div>
            <Dialog
                open={open}
                onClose={e => {
                    setOpen(false)
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={e => {
                    setOpen(false)
                    handleDisagree()
                }}>Disagree</Button>
                <Button onClick={e => {
                    setOpen(false)
                    handleAgree()
                }} autoFocus>
                    Agree
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}