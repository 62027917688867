import { useState, useEffect } from 'react';
import { Day, RequestState, RequestType, BookingSlot, BookingSlotStatus, LabExam, BookingSlotLabExam, Lab, Exam } from '../../types';
import useRequest from '../../hooks/useRequest';
import { Loader, DateTimeTable } from '../../components';
import { useSelector, useDispatch } from 'react-redux';

//helper functions
import MyDate from '../../helpers/date';
import { setBookingId, setDemandDate, setDemandTime } from '../../redux/features/exam';
import { useNavigate } from 'react-router-dom';
import { resetLocation } from 'src/redux/features/user';
import useAuthorization from 'src/hooks/useAuthorization';
import ReactPaginate from 'react-paginate';

type ChooseTimeProps = {
    setPage: () => void,
}

export default function ChooseTime({ setPage }: ChooseTimeProps): React.ReactElement {

    // initial data
    const auth = useAuthorization();

    const navigator = useNavigate();
    const dispatcher = useDispatch();
    const [schedule, setSchedule] = useState<BookingSlot[]>([]);
    const [dayTable, setDayTable] = useState<Day[]>([]);
    const labExamId = useSelector((state: any) => state.exam.labExamId);
    const [isPageReady, setIsDisplayReady] = useState(false);
    const createHandleChoose = (bookingId: number, date: String, hour: String) => (): void => {
        if (!auth) {
            window.alert("Você precisa estar cadastrado(a) e logado(a) para reservar um exame");
            localStorage.setItem("redirectTime", "true")
            return navigator("/login");
        }
        else {
            dispatcher(setDemandDate(date));
            dispatcher(setDemandTime(hour));
            dispatcher(setBookingId(bookingId));
            navigator("../book");
        }
    }
    const handleResetLocation = () => {
        dispatcher(resetLocation());
        navigator("../");
        window.location.reload();
    }

    // paginate
    const [currentItems, setCurrentItems] = useState<Day[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    let itemsPerPage = 0;
    if(window.innerWidth <= 700)
        itemsPerPage = 1;
    else if(window.innerWidth <= 1200)
        itemsPerPage = 3;
    else
        itemsPerPage = 5;

    // checking if needed data
    useEffect(() => {
        if (labExamId === null) navigator("../");
        setPage()
    }, []);


    // get data request
    const request = useRequest('api/lab-exams/get_available_book_slots_by_lab_exam_id/', setSchedule, RequestType.Get);
    const requestState = request.requestState
    useEffect(() => {
        setIsDisplayReady(false);
        request.call({ lab_exam_id: labExamId });

    }, [])
    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel recuperar os exames no momento. Tente novamente mais tarde.")
                break;
            default:
                break;
        }
    }, [requestState])

    // translate timetable into usable format

    // paginate

    useEffect(() => {
        if (schedule !== undefined) {
            setDayTable(createTimeSchedule(schedule))
            updatePaginateItems(createTimeSchedule(schedule))
        }
    }, [schedule])

    useEffect(() => {
        updatePaginateItems(dayTable)
        setIsDisplayReady(true);

    }, [itemOffset, dayTable])

    function updatePaginateItems(dayTableParam: Day[]) {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(dayTable.slice(itemOffset, endOffset))
        setPageCount(Math.ceil(dayTableParam.length / itemsPerPage));
    }

    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % dayTable.length;
        setItemOffset(newOffset);
    };


    return (
        <div className="flex flex-col items-center px-2">
            <h1>Agende um Horário</h1>
            {
                isPageReady === false || requestState === RequestState.Waiting ?
                    <Loader /> :

                    dayTable.length === 0 ?
                        <div className='w-full flex items-center flex-col'>
                            <p className='my-4 mx-2 '> Não temos horários disponíveis para esse exame na sua região no momento.</p>
                            <button onClick={handleResetLocation} className='button-secondary button-animation w-full'>Trocar localização</button>
                        </div > :
                        <div
                            style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}
                        >
                            <div
                                style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}
                            >
                                {currentItems.map((day: Day, index: number) => (
                                    <DateTimeTable
                                        day={day}
                                        index={index}
                                        collapsible={index > 1}
                                        settingBookingIdAction={createHandleChoose}
                                        key={index}
                                    />
                                ))}

                            </div>
                            <ReactPaginate
                                nextLabel={'>'}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                previousLabel={'<'}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                                renderOnZeroPageCount={() => { }}
                            />
                        </div>
            }
        </div>
    )
}

export const createTimeSchedule = (bookingSlots: BookingSlot[]): Day[] => {
    let dayList: Day[] = [];
    bookingSlots.forEach((bookingSlot: BookingSlot) => {
        const date: MyDate = new MyDate(bookingSlot.date_time_from);
        const today: MyDate = new MyDate();
        if (MyDate.compareDates(date, today) < 0) return;

        const registeredDates = dayList.map(day => day.date.getBR());

        // case where the date is already in the list
        if (registeredDates.includes(date.getBR())) {
            const desiredDay = dayList.find(day => day.date.getBR() === date.getBR())
            if (!desiredDay) return;
            desiredDay.times[date.getHour()] = {
                available: bookingSlot.status === BookingSlotStatus.free,
                id: bookingSlot.id,
                price: bookingSlot.price
            }
        }
        else {
            dayList.push({
                date: date,
                times: {
                    [date.getHour()]: {
                        available: bookingSlot.status === BookingSlotStatus.free,
                        id: bookingSlot.id,
                        price: bookingSlot.price
                    }
                }
            })
        }
    })
    return dayList.sort((a: Day, b: Day) => MyDate.compareDates(a.date, b.date));;
}