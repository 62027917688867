import React from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRequest from '../../hooks/useRequest';
import { RequestState, RequestType } from '../../types';
import { Loader } from '../../components';
import useAuthorization from "../../hooks/useAuthorization"
import textFromInputEvent from 'src/helpers/textFromInputEvent';
import { isInvalidEmail, validatePassword } from 'src/helpers/validator';

type newFuncionario = {
    email: string,
    name: string,
    password: string
}

export default function AddFuncionario(): React.ReactElement {
    const navigate = useNavigate()
    let { labId } = useParams()
    if(labId === undefined) {
        const labIdLocalStorage = localStorage.getItem("labId");
        if (!labIdLocalStorage)
            navigate("/");
        else{
            labId = labIdLocalStorage
        }
    }
    const [funcionarioData, setFuncionarioData] = useState<newFuncionario>({
        email: "",
        name: "",
        password: ""
    })

    const request = useRequest('api/funcionario/create_funcionario/', () => { }, RequestType.Post);
    const requestState = request.requestState;

    const signUpDataSetter = (data: any) => {
        const { email, name, password } = data
        const newData = {
            email: email || funcionarioData.email,
            name: name || funcionarioData.name,
            password: password || funcionarioData.password,
            lab_id: labId
        }
        setFuncionarioData(newData);
    }

    const handleInput = (key: string | number) => (event: any) => signUpDataSetter({ [key]: (textFromInputEvent(event)) })
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (isInvalidEmail(funcionarioData.email)) {
            window.alert("Insira um e-mail válido!"); return
        }
        if (validatePassword(funcionarioData.password)){
            window.alert("Sua senha deve ter no mínimo 8 caracteres e não pode ser completamente numérica"); return
        }
        request.call(funcionarioData);
    }

    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            case RequestState.Successful:
                window.alert("Funcionário adicionado")
                localStorage.setItem("labId", labId?.toString() || "");
                navigate("/myLabs/labId/funcionarios")
                break;
            case RequestState.NotAuthorized:
                window.alert("Operação não autorizada")
                navigate("/chooseExam")
                break;
            case RequestState.MissingData:
                window.alert("Os campos obrigatórios devem ser preenchidos")
                break;
            case RequestState.EmailNotUser:
                window.alert("Não foi encontrado usuário com esse email")
                break;
            case RequestState.UserAlready:
                window.alert("Usuário já possui essa atribuição")
                break;
            case RequestState.UserAlreadyFuncionario:
                window.alert("Usuário já é funcionário")
                break;
            case RequestState.UserAlreadyGerente:
                window.alert("Usuário já é gerente")
                break;
            case RequestState.GestorNotIdentified:
                window.alert("Usuário não possui permissão de gestor")
                break;
            default:
                break;
        }
    }, [requestState])

    if (!useAuthorization()) return <Navigate to="/" replace />

    return (
        <div className="page" style={{ marginBottom: "40px" }}>
            <h1>Adicione um novo funcionário</h1>
            <form className="flex flex-col" onSubmit={handleSubmit}>
                <input className='line-input' onChange={handleInput("name")} type="text" placeholder="*Nome Completo" required />
                <input className='line-input' onChange={handleInput("email")} type="text" placeholder="Email" />
                {
                    requestState === RequestState.EmailAlreadyInUse ?
                        <p className='input-warning'>Este e-mail já está cadastrado.</p> : <></>
                }

                <input className='line-input' onChange={handleInput("password")} type="password" placeholder="*Senha (Mínimo de 8 caracteres, não todos numéricos)" required />
                <p className='input-warning'>Recomenda-se uma senha simples para que o funcionário troque depois</p>
                {
                    requestState === RequestState.WeakPassword ?
                        <p className='input-warning'>Esta senha é muito fraca ou comum. As senhas devem ter no mínimo 8 caracteres, que não sejam todos numéricos.</p> : <></>
                }

                {
                    requestState === RequestState.Waiting ?
                        <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> :
                        <input className='button-primary' type="submit" value="Adicionar" />
                }
            </form>
        </div>
    )
}