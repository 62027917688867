import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from "recharts";

type pointData = {
    data: string,
    Realizados: number,
    "Não Utilizados": number,
    "Com Não Comparecimento": number
}

type BookingSlotsMultipleLineChartProps = {
    data: pointData[]
}

function formatXAxis(date: string): string {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(1);
    const pad = (n: any, s = 2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
    return `${pad(d.getDate())}/${pad(d.getMonth() + 1)}/${pad(d.getFullYear().toString().substr(-2), 2)}`;
}


export default function BookingSlotsMultipleLineChart({ data }: BookingSlotsMultipleLineChartProps): React.ReactElement {
    const sortedData = data.sort((a, b) => new Date(a.data) - new Date(b.data))
    return (
        <LineChart
            width={700}
            height={360}
            data={sortedData}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="data" tickFormatter={formatXAxis} />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            <Line
                type="linear"
                dataKey="Realizados"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
            />
            <Line type="linear" dataKey="Não Utilizados" stroke="#82ca9d" />
            <Line type="linear" dataKey="Com Não Comparecimento" stroke="#83249a" />
        </LineChart>
    );
}