import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate, useParams } from "react-router-dom";
import { BookingSlotLabExam, BookingSlotStatus, RequestState, RequestType } from "src/types";
import { useEffect, useState } from "react";
import { emptyListBookingSlotLabExamUser } from "src/constants/emptyTypes";
import useRequest from "src/hooks/useRequest";

type Event = {
    start: Date;
    end: Date;
    title: string;
}
const localizer = momentLocalizer(moment);
export default function LabInteractiveCalendar(): React.ReactElement {
    const { labId } = useParams();
    const [listBookSlot, setListBookSlot] = useState<BookingSlotLabExam[]>(emptyListBookingSlotLabExamUser)
    const getListGestor = useRequest(`api/booking-slots/get_book_slot_by_gestor_and_lab_id/`, setListBookSlot, RequestType.Get);
    const [state, setState] = useState<Event[]>([]);
    const navigate = useNavigate()
    useEffect(() => {
        if (labId !== undefined && listBookSlot.length <= 1) {
            getListGestor.call({
                lab_id: labId,
            })
        } else {
            const labIdLocalStorage = localStorage.getItem("labId");
            if (!labIdLocalStorage)
                navigate("/");
            else if (listBookSlot.length <= 1){
                getListGestor.call({
                    lab_id: labIdLocalStorage,
                })
            }
        }
    }, [labId])

    useEffect(() => {
        switch (getListGestor.requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possível carregar as informações. Tente novamente mais tarde");
                navigate(-1)
                break;
            default:
                setState(
                    listBookSlot.map((bookSlot: BookingSlotLabExam) => {
                            const end_date = new Date(bookSlot.date_time_from);
                            end_date.setMinutes(end_date.getMinutes() + bookSlot.duration);
                            let title_event = bookSlot.lab_exam.exam.name
                            if(bookSlot.status === BookingSlotStatus.inProgress)
                                title_event += " - em progesso"
                            else if(bookSlot.status === BookingSlotStatus.booked)
                                title_event += " - confirmado"
                            return {
                                title: title_event,
                                start: new Date(bookSlot.date_time_from),
                                end: end_date,
                            }
                        })
                )
                break;
        }
    }, [getListGestor.requestState])

    return (
        <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            events={state}
            style={{ height: "100vh" }}
        />)
}