import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import setFromEvent from '../../helpers/setFromEvent';
import useRequest from '../../hooks/useRequest';
import { RequestState, RequestType } from '../../types';
import { useEffect } from 'react';
import { Loader } from '../../components';
import { Background, Page, Header, Title, CenterDiv, Line, OtherWithLine, LeftDiv } from "../../components/NewDesignCommons";
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import Logo from '../../components/Logo';

export default function Reset(): React.ReactElement {
    const { uidb64, token } = useParams();
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const navigator = useNavigate();
    const request = useRequest('auth/password/reset/confirm/', () => { }, RequestType.Post);
    const requestState = request.requestState;

    const [passVisible, setPassVisible] = useState<boolean>(false);
    const togglePassVisible = () => setPassVisible(!passVisible);

    const handleSubmit = (event: any): void => {
        event.preventDefault();
        if (password !== password2) {
            window.alert("Suas duas senhas devem ser iguais!"); return
        }
        else if ((password.length < 8) || (password.match(/^[0-9]+$/) != null)) {
            window.alert("Sua senha deve ter no mínimo 8 caracteres e não pode ser completamente numérica"); return
        }
        request.call({
            uid: uidb64,
            token: token,
            new_password1: password,
            new_password2: password2
        })
    }

    useEffect(() => {
        switch (requestState) {
            case RequestState.Successful:
                window.alert("Senha alterada com sucesso!");
                break;
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar o seu pedido no momento. Tente novamente mais tarde.");
                break;
            default: break;
        }
    }, [requestState])


    if (requestState === RequestState.Successful) return <Navigate to='/login' replace />
    return (
        <Background>
            <Logo/>
            <Page style={{ height: "304px" }}>
                <Header>
                    <IoIosArrowBack onClick={() => navigator("/login")} style={{ fontSize: '24px', color: '#212121', cursor: 'pointer' }} />
                    <Title>Insira Sua Nova Senha</Title>
                </Header>

                <form className="flex flex-col" onSubmit={handleSubmit} action="#">
                    <span className="flex w-full justify-between flex-row items-center">
                        <input style={window.innerWidth > 500 ? { maxWidth: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                        :{ maxWidth: "230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }}
                        onChange={setFromEvent(setPassword)} type={passVisible ? "text" : "password"} placeholder='Nova senha' />
                        {
                            requestState === RequestState.WeakPassword ?
                                <p className='input-warning'>Esta senha é muito fraca ou comum. As senhas devem ter no mínimo 8 caracteres.</p> : <></>
                        }
                        {
                            passVisible ?
                                <FiEyeOff size={20} className="ml-4" onClick={togglePassVisible} /> :
                                <FiEye size={20} className="ml-4" onClick={togglePassVisible} />
                        }
                    </span>
                    <input style={window.innerWidth > 500 ? { maxWidth: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                        :{ maxWidth: "230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }}
                    onChange={setFromEvent(setPassword2)} className={requestState === RequestState.PasswordIncorrect ? "line-input line-input-activate" : "line-input"} type="password" placeholder='Repita sua nova senha' />
                    {
                        password !== password2 ?
                            <p className='input-warning'>As duas senhas devem ser iguais!</p> : <></>
                    }
                    {
                        requestState === RequestState.Waiting ?
                            <Loader /> :
                            <input type="submit" style={window.innerWidth > 500 ? {width: "360px", marginLeft: "0px"} 
                            : {maxWidth: "230px", width:"100%", marginLeft: "0px"}} 
                            value="Alterar senha" className='button-primary button-animation' />
                    }
                </form>
            </Page>
        </Background>
    )
}