import { SearchFilter } from '../types';
import { FiDollarSign, FiClock } from 'react-icons/fi';
import { IoLocationOutline } from 'react-icons/io5';
import distanceBetweenPoints from '../helpers/distanceBetweenPoints'
import availabilityFromBookingSlots from '../helpers/availabilityFromBookingSlots'

const iconProps = {
    size: 32,
    className: "m-4",
    background: "#B6EDE0",
    color: "#212121"
}

const searchFilters: SearchFilter[] = [
    {
        tag: "closest",
        title: "Mais Próximo",
        description: "Encontre a clínica mais próxima",
        icon: <IoLocationOutline {...iconProps} />,
        compareFunctionGenerator: (props: any) => (obj1: any, obj2: any) => distanceBetweenPoints(props.userLocation, [obj1.lab.latitude, obj1.lab.longitude]) - distanceBetweenPoints(props.userLocation, [obj2.lab.latitude, obj2.lab.longitude])
    },
    {
        tag: "fastest",
        title: "Mais Rápido",
        description: "Encontre as datas mais próximas",
        icon: <FiClock {...iconProps} />,
        compareFunctionGenerator: (props: any) => (obj1: any, obj2: any) => availabilityFromBookingSlots(obj2.bookingSlots) - availabilityFromBookingSlots(obj1.bookingSlots)
    },
    {
        tag: "cheapest",
        title: "Mais Barato",
        description: "Encontre os melhores preços",
        icon: <FiDollarSign {...iconProps} />,
        compareFunctionGenerator: (props: any) => (obj1: any, obj2: any) => {
            let avg1: number = 0;
            let avg2: number = 0;
            obj1.booking_slots.map((book: any) => {
                avg1 += parseFloat(book.price)
            })
            obj2.booking_slots.map((book: any) => {
                avg2 += parseFloat(book.price)
            })
            { obj1.booking_slots.length === 0 ? avg1 = Number.MAX_SAFE_INTEGER : avg1 = avg1 / obj1.booking_slots.length }
            { obj2.booking_slots.length === 0 ? avg2 = Number.MAX_SAFE_INTEGER : avg2 = avg2 / obj2.booking_slots.length }
            return avg1 - avg2
        }
    },
]

export default searchFilters;