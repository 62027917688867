import { FiHome } from 'react-icons/fi'
import { Loader } from '../../components'
import CardExamNew from "../../components/CardExamNew"
import { BookingSlot, BookingSlotStatus, Exam, Gestor, Lab, LabExam, Permissions, RequestState, RequestType, SearchFilter, UserData } from '../../types'
import { useDispatch } from 'react-redux';
import { setLabAddress, setLabExamId, setLabName, setLabPhone } from '../../redux/features/exam';
import { useSelector } from 'react-redux';
import searchFilters from '../../constants/searchFilters';
import distanceBetweenPoints from '../../helpers/distanceBetweenPoints';
import timeBetweenPoints from '../../helpers/timeBetweenPoints';
import useRequest from '../../hooks/useRequest';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import maxDistance from '../../constants/maxDistance';
import { resetLocation } from 'src/redux/features/user';
import { emptyBookingSlot, emptyExam, emptyLab } from 'src/constants/emptyTypes';
import Biotech from '../assets/images/home/Biotech.svg'
import { BiClinic } from 'react-icons/bi';

type ChooseClinicProps = {
    setPage: () => void,
}

const emptyLabExams: LabExam[] = [{
    lab: emptyLab,
    exam: emptyExam,
    booking_slots: [emptyBookingSlot],
    id: 0,
    min_price: 0,
    max_price: 0
}]

export default function ChooseClinic({ setPage }: ChooseClinicProps): React.ReactElement {

    // initial data
    const navigator = useNavigate();
    const dispatcher = useDispatch();

    const { lat, long, userLocationAuth } = useSelector((state: any) => ({ lat: state.user.lat, long: state.user.long, userLocationAuth: state.user.locationAuth }));
    const filter: SearchFilter = useSelector((state: any) => searchFilters[state.exam.filterId])
    const { examId } = useSelector((state: any) => state.exam);

    const [labExams, setLabExams] = useState<LabExam[]>(emptyLabExams)
    const [labExamsFiltered, setLabExamsFiltered] = useState<LabExam[]>(emptyLabExams)

    const createHandleChoose = (labExamId: number) => () => {
        const labExam = labExams.filter((value: LabExam) => value.id === labExamId)[0];
        if (!labExam) {
            window.alert("Tivemos um problema para processar o seu exame. Tente novamente em alguns instantes.");
            navigator("../");
            return;
        }
        dispatcher(setLabExamId(labExamId));
        dispatcher(setLabName(labExam.lab.razao_social));
        dispatcher(setLabPhone(labExam.lab.telefone));
        dispatcher(setLabAddress(labExam.lab.address));
        navigator("../time");
    }
    const handleResetLocation = () => {
        dispatcher(resetLocation());
        navigator("../");
        window.location.reload();
    }

    // checking if needed data
    useEffect(() => {
        if (!filter || !examId) navigator("../");
        setPage()
    }, []);

    // get data request
    const request = useRequest('api/lab-exams/lab_examid/', setLabExams, RequestType.Get);
    const requestState = request.requestState
    useEffect(() => request.call({
        "exam_id": examId,
        "status": "free"
    }), [])
    useEffect(() => {
        switch (requestState) {
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel recuperar os exames no momento. Tente novamente mais tarde.")
                break;
            default: break;
        }
    }, [requestState])

    // filtering exams to show
    useEffect(() => {
        setLabExamsFiltered(labExams.filter(
            (labExam: LabExam) => (
                labExam.exam.id === examId &&
                distanceBetweenPoints([labExam.lab.latitude, labExam.lab.longitude], [lat, long]) < maxDistance
            )
        ))
    }, [labExams])


    if (!filter) return <></>;
    return (
        <div className='flex flex-col items-center'>
            <h1>{filter.title}</h1>
            {
                requestState === RequestState.Waiting ?
                    <Loader /> :
                    <>
                        {
                            labExamsFiltered.length === 0 ?
                                <div className='w-full flex items-center flex-col'>
                                    <p className='my-4 mx-2'>Não temos opções disponíveis para esse exame no momento.</p>
                                    <button onClick={handleResetLocation} className='button-secondary button-animation w-full'>Trocar localização</button>
                                </div > :
                                <div className="justify-center mt-3 px-5" style={window.innerWidth > 1000 ? {display: "grid", gridTemplateColumns: "auto auto", columnGap: "20px", rowGap: "30px", width: "100%"} : {width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "10px"}}>
                                    {labExamsFiltered
                                        .sort(filter.compareFunctionGenerator({
                                            userLocation: userLocationAuth ? [lat, long] : [0, 0]
                                        }))
                                        .map((labExam: LabExam, index: number) => {
                                            return <CardExamNew
                                                icon={<BiClinic size={32} />}
                                                title={labExam.lab.razao_social}
                                                subtitle={
                                                    userLocationAuth && (!!labExam.lab.latitude && !!labExam.lab.longitude)
                                                        ? `${distanceBetweenPoints([lat, long], [labExam.lab.latitude, labExam.lab.longitude]).toFixed(1)
                                                        }km - ${timeBetweenPoints([lat, long], [labExam.lab.latitude, labExam.lab.longitude]).toFixed(0)
                                                        } minutos` : ""}
                                                additionalText={labExam.lab.address}
                                                subtitleHighlight={`R$${labExam.min_price.toFixed(2)} - R$${labExam.max_price.toFixed(2)}`}
                                                action={createHandleChoose(labExam.id)}
                                                key={index}
                                            />
                                    })}
                                </div>
                        }
                    </>
            }
        </div>
    )
}