import React from 'react';
import { useState } from 'react';
import setFromEvent from '../../helpers/setFromEvent';
import useRequest from '../../hooks/useRequest';
import { RequestState, RequestType } from '../../types';
import { useEffect } from 'react';
import Loader from './../../components/Loader';
import { isInvalidEmail } from 'src/helpers/validator';
import { Background, Page, Header, Title, CenterDiv, Line, OtherWithLine, LeftDiv } from "../../components/NewDesignCommons";
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo';

export default function GetEmail(): React.ReactElement {
    const [email, setEmail] = useState<string>("");
    const handleSubmit = (event: any): void => {
        if (isInvalidEmail(email)) {
            window.alert("Insira um e-mail válido!"); return
        }
        event.preventDefault()
        request.call({
            email: email
        })
        setEmail("");
    }
    const navigator = useNavigate();

    const request = useRequest('auth/password/reset/', () => { }, RequestType.Post);
    const requestState = request.requestState;

    useEffect(() => {
        switch (requestState) {
            case RequestState.Successful:
                window.alert("Se você inseriu um e-mail válido, receberá em instantes um email para resetar a sua senha.");
                break;
            case RequestState.NotSuccessful:
                window.alert("Não foi possivel completar a sua requisição no momento. Tente novamente em alguns instantes.");
                break;
            default: break;
        }
    })

    return (
        <>
            <Background>
                <Logo/>
                <Page style={{ height: "304px" }}>
                    <Header>
                        <IoIosArrowBack onClick={() => navigator("/login")} style={{ fontSize: '24px', color: '#212121', cursor: 'pointer' }} />
                        <Title>Esqueceu sua senha?</Title>
                    </Header>
                    <form className="flex flex-col" onSubmit={handleSubmit} action="#">
                        <input style={window.innerWidth > 500 ? { maxWidth: "360px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }
                        :{ maxWidth: "230px", boxSizing: "border-box", border: "1px solid #E1E1E1", borderRadius: "4px", height: "48px", paddingLeft: "10px", marginTop: "6px", marginBottom: "6px" }}
                        placeholder='Email' onChange={setFromEvent(setEmail)} type="email" required />
                        {
                            requestState === RequestState.EmailInvalid ?
                                <p className='input-warning'>Entre um email válido.</p> : <></>
                        }
                        {
                            requestState === RequestState.Waiting ?
                                <h4 text-center><b>Clique no OK</b> quando a mensagem de sucesso aparecer</h4> :
                                <input type="submit" style={window.innerWidth > 500 ? {width: "360px", marginLeft: "0px"} 
                                : {maxWidth: "230px", width:"100%", marginLeft: "0px"}} 
                                 value="Solicitar Nova Senha" className='button-primary' />
                        }
                    </form>
                </Page>
            </Background>
        </>
    )
}