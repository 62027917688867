import {useEffect, useState} from "react";
import {Navigate, Link} from "react-router-dom";
import {Loader} from "../components";
import axios from "../commons/axios";
import {RequestState} from "../types";
import {logIn} from "../redux/features/auth";

export default function ApiTest(): React.ReactElement {
    const [user, setUser] = useState<String | null>();

    useEffect(
        () => {
            fetchUser({});
        }, []
    )

    const fetchUser = (event: any): void => {
        // event.preventDefault();

        axios.get('/api/users/me/')
            .then((response: { data: object }) => {
                return response.data
            })
            .then((data: any) => {
                setUser(data.username)
                console.log(data);
            })
            .catch((error: any) => {
                console.log(error);
                setUser("Nenhum usuário");
            })
    }
    const login = (event: any): void => {
        event.preventDefault();

        axios.post('/auth/login/', {
            email: 'guilhermevmanhaes@gmail.com',
            password: 'Aquario0'
        })
            .then((response: { data: object }) => {
                return response.data
            })
            .then((data: any) => {
                console.log(data);
                if (data.key) {
                    console.log("Login realizado com sucesso!")
                    fetchUser({});
                }
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    const logout = (event: any): void => {
        event.preventDefault();


        axios.post('/auth/logout/'
        )
            .then((response: { data: object }) => {
                return response.data
            })
            .then((data: any) => {
                console.log(data);
                console.log("Logout realizado com sucesso!")
                fetchUser({});

            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    return (
        <div className="page">
            <h1>usuario:</h1>
            <h3>{user || "nenhum usuário"}</h3>
            <button className="button-secondary" onClick={fetchUser}>Fetch user</button>
            <button className="button-secondary" onClick={login}>Login</button>
            <button className="button-secondary" onClick={logout}>Logout</button>
        </div>
    )
}
