import axios, { Axios } from "axios";
import baseURL from '../constants/baseURL';

let url = baseURL;

if (window.location.origin !== "http://localhost:3000") {
    url = window.location.origin;
}

const configuredAxios: Axios = axios.create({
    baseURL: url,
    withCredentials: true,
    xsrfHeaderName: "X-CSRFToken",
    xsrfCookieName: "csrftoken"
});

export default configuredAxios;